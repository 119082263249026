<template>
    <article class="tool-container" itemscope itemtype="https://schema.org/SoftwareApplication">
        <ToolHeader :toolData="toolData" />

        <section class="svg-generator-container">
            <!-- Секция настроек -->
            <n-card class="settings-panel">
                <div class="settings-grid">
                    <!-- Ширина SVG -->
                    <div class="setting-item">
                        <label for="svg-width">{{ $t('svg_placeholder_width_label') }} (px)</label>
                        <n-input-number id="svg-width" v-model:value="width" min="50" max="1000" placeholder="300" />
                    </div>

                    <!-- Цвет фона -->
                    <div class="setting-item">
                        <label for="background-color">{{ $t('svg_placeholder_background_color_label') }}</label>
                        <n-color-picker v-model:value="backgroundColor" />
                    </div>

                    <!-- Высота SVG -->
                    <div class="setting-item">
                        <label for="svg-height">{{ $t('svg_placeholder_height_label') }} (px)</label>
                        <n-input-number id="svg-height" v-model:value="height" min="50" max="1000" placeholder="200" />
                    </div>

                    <!-- Цвет текста -->
                    <div class="setting-item">
                        <label for="text-color">{{ $t('svg_placeholder_text_color_label') }}</label>
                        <n-color-picker v-model:value="textColor" />
                    </div>

                    <!-- Размер текста -->
                    <div class="setting-item">
                        <label for="font-size">{{ $t('svg_placeholder_font_size_label') }} (px)</label>
                        <n-input-number id="font-size" v-model:value="fontSize" min="10" max="100" placeholder="24" />
                    </div>

                    <!-- Текст внутри SVG -->
                    <div class="setting-item">
                        <label for="placeholder-text">{{ $t('svg_placeholder_text_label') }}</label>
                        <n-input id="placeholder-text" v-model:value="text" placeholder="600x400" />
                    </div>
                </div>

                <!-- Кнопка для копирования кода SVG -->
                <n-space justify="center" style="margin-top: 20px;">
                    <n-button @click="copySVGCode">{{ $t('svg_placeholder_copy_button') }}</n-button>
                </n-space>
            </n-card>

            <div class="preview-panel">
            <div v-if="svgDataUrl" class="svg-preview">
                <img :src="svgDataUrl" :alt="text" :width="width" :height="height" />
            </div>
        </div>
        </section>
        <!-- Секция превью SVG -->
        
        <footer>
            <meta itemprop="softwareVersion" content="1.0" />
            <meta itemprop="operatingSystem" content="All" />
            <meta itemprop="applicationCategory" content="Utility" />
            <link itemprop="url" href="https://toolsyit.com/svg-placeholder-generator" />
        </footer>
    </article>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'SVGPlaceholderGenerator',
    components: { ToolHeader },
    setup() {
        const { t } = useI18n();
        const notification = useNotification();
        const toolData = toolsData.development.find(tool => tool.path === '/svg-placeholder-generator');

        const width = ref(600);
        const height = ref(400);
        const backgroundColor = ref('#cccccc');
        const textColor = ref('#000000');
        const fontSize = ref(24);
        const text = ref('');
        const svgDataUrl = ref('');

        const generateSVG = () => {
            const svgContent = `
                <svg width="${width.value}" height="${height.value}" xmlns="http://www.w3.org/2000/svg">
                    <rect width="100%" height="100%" fill="${backgroundColor.value}"/>
                    <text x="50%" y="50%" font-size="${fontSize.value}" style="font-family: monospace;" fill="${textColor.value}" dy=".3em" text-anchor="middle">${text.value || width.value + 'x' + height.value}</text>
                </svg>`;
            svgDataUrl.value = `data:image/svg+xml;base64,${btoa(svgContent)}`;
        };

        watch([width, height, backgroundColor, textColor, fontSize, text], generateSVG, { immediate: true });

        const copySVGCode = () => {
            navigator.clipboard.writeText(svgDataUrl.value);
            notification.success({
                content: t('svg_placeholder_copied_successfully') + '!',
                duration: 2000,
            });
        };

        return {
            toolData,
            width,
            height,
            backgroundColor,
            textColor,
            fontSize,
            text,
            svgDataUrl,
            copySVGCode,
        };
    },
});
</script>

<style scoped>
.tool-container {
    max-width: 650px;
    margin: 0 auto;
}

.svg-generator-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.settings-panel {
    width: 650px;
}

.preview-panel {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.svg-preview {
    width: 100%;
    height: auto;
    text-align: center;
}

.settings-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
}

.setting-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

label {
    font-size: 14px;
    width: 100px;
    /* text-align: right; */
}
</style>
