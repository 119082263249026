<template>
    <article class="tool-container" itemscope itemtype="https://schema.org/SoftwareApplication">

        <ToolHeader :toolData="toolData" />

        <section>
            <n-card>
                <n-space vertical size="large">
                    <n-flex gap="2" vertical style="margin-bottom: 10px;">
                        <label for="input-string">{{ $t('slugify_string_input_string_label') }}</label>
                        <n-input id="input-string" v-model:value="inputString"
                            :placeholder="$t('slugify_string_input_string_placehodler')" type="textarea" rows="3"
                            @input="slugifyString" :autosize="{ minRows: 3, maxRows: 10 }" />
                    </n-flex>

                    <n-flex gap="2" vertical>
                        <label for="slugified-string">{{ $t('slugify_string_result_string_label') }}</label>
                        <n-input id="slugified-string" v-model:value="slugifiedString"
                            :placeholder="$t('slugify_string_result_string_placehodler')" type="textarea" rows="3"
                            :autosize="{ minRows: 3, maxRows: 10 }" readonly />
                    </n-flex>

                    <n-space justify="center">
                        <n-button @click="copySlug">{{ $t('slugify_string_copy_button') }}</n-button>
                    </n-space>
                </n-space>
            </n-card>
        </section>

        <footer>
            <meta itemprop="softwareVersion" content="1.0" />
            <meta itemprop="operatingSystem" content="All" />
            <meta itemprop="applicationCategory" content="Utility" />
            <link itemprop="url" href="https://toolsyit.com/slugify-string" />
        </footer>

    </article>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'SlugifyString',
    components: { ToolHeader },
    setup() {

        const { t } = useI18n(); // Получаем функцию t для локализации

        const toolData = toolsData.development.find(tool => tool.path === '/slugify-string');

        const inputString = ref('');
        const slugifiedString = ref('');
        const notification = useNotification();

        const transliterate = (str) => {
            const cyrillicToLatinMap = {
                'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'ґ': 'g', 'д': 'd', 'е': 'e', 'є': 'ye', 'ж': 'zh',
                'з': 'z', 'и': 'y', 'і': 'i', 'ї': 'yi', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
                'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts',
                'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ь': '', 'ю': 'yu', 'я': 'ya', 'э': 'e', 'ы': 'y', 'ё': 'yo',
                'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Ґ': 'G', 'Д': 'D', 'Е': 'E', 'Є': 'Ye', 'Ж': 'Zh',
                'З': 'Z', 'И': 'Y', 'І': 'I', 'Ї': 'Yi', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
                'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts',
                'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Shch', 'Ь': '', 'Ю': 'Yu', 'Я': 'Ya', 'Э': 'E', 'Ы': 'Y', 'Ё': 'Yo'
            };

            return str.split('').map(char => cyrillicToLatinMap[char] || char).join('');
        };

        const slugifyString = () => {
            const transliterated = transliterate(inputString.value);
            slugifiedString.value = transliterated
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^a-z0-9-]/g, '')
                .replace(/--+/g, '-')
                .replace(/^-+|-+$/g, '');
        };

        const copySlug = () => {
            navigator.clipboard.writeText(slugifiedString.value);
            notification.success({
                content: t('slugify_string_copied_successfully') + '!',
                duration: 2000,
            });
        };

        return {
            inputString,
            slugifiedString,
            slugifyString,
            copySlug,
            toolData
        };
    },
});
</script>

<style scoped>
.input-wrapper {
    margin-bottom: 20px;
}
</style>