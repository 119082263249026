<template>
    <div class="chronometer-container">

        <ToolHeader :toolData="toolData" />

        <n-card>
            <n-space vertical size="large">
                <div class="time-display">{{ formattedTime }}</div>
            </n-space>
        </n-card>

        <div class="control-buttons">
            <n-space justify="space-between">
                <!-- Кнопки, когда таймер остановлен -->
                <template v-if="!isRunning">
                    <n-button @click="resetChronometer" strong secondary>
                        {{ $t('chronometer_reset_button') }}
                    </n-button>
                    <n-button @click="startChronometer" tertiary type="primary">
                        {{ $t('chronometer_start_button') }}
                    </n-button>
                </template>

                <!-- Кнопки, когда таймер запущен -->
                <template v-else>
                    <n-button @click="recordLap" strong secondary>
                        {{ $t('chronometer_lap_button') }}
                    </n-button>
                    <n-button @click="stopChronometer" tertiary type="error">
                        {{ $t('chronometer_stop_button') }}
                    </n-button>
                </template>
            </n-space>
        </div>

        <n-collapse-transition :show="laps.length > 0" class="laps-container">
            <n-card size="small">
                <n-list>
                    <n-list-item v-for="(lap, index) in sortedLaps" :key="lap.id">
                        <div :class="lapClass(lap.time)">
                            <n-space justify="space-between">
                                <div class="lap-row"> Lap {{ laps.length - index }}</div>
                                <div class="lap-row">-</div>
                                <div class="lap-row">{{ formatTime(lap.time) }}</div>
                            </n-space>
                        </div>
                    </n-list-item>
                </n-list>
            </n-card>
        </n-collapse-transition>
    </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { toolsData } from '@/toolsData';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'ChronometerTool',
    components: { ToolHeader },
    setup() {
        const { t } = useI18n();
        const toolData = toolsData.measurement.find(tool => tool.path === '/chronometer');

        const time = ref(0);
        const isRunning = ref(false);
        const laps = ref([]);
        let interval = null;
        let lastLapTime = 0;

        const startChronometer = () => {
            if (!isRunning.value) {
                isRunning.value = true;
                interval = setInterval(() => {
                    time.value += 10;
                }, 10);
            }
        };

        const stopChronometer = () => {
            if (isRunning.value) {
                clearInterval(interval);
                isRunning.value = false;
            }
        };

        const resetChronometer = () => {
            stopChronometer();
            time.value = 0;
            laps.value = [];
            lastLapTime = 0;
        };

        const recordLap = () => {
            const lapTime = time.value - lastLapTime;
            lastLapTime = time.value;
            laps.value.unshift({ id: Date.now(), time: lapTime });
        };

        const formattedTime = computed(() => formatTime(time.value));

        const sortedLaps = computed(() => [...laps.value]);

        const formatTime = (time) => {
            const milliseconds = String(Math.floor((time % 1000) / 10)).padStart(2, '0');
            const seconds = String(Math.floor(time / 1000) % 60).padStart(2, '0');
            const minutes = String(Math.floor(time / 60000) % 60).padStart(2, '0');
            return `${minutes}:${seconds}.${milliseconds}`;
        };

        const lapClass = (lapTime) => {
            const times = laps.value.map(lap => lap.time);
            if (lapTime === Math.min(...times)) return 'lap-min';
            if (lapTime === Math.max(...times)) return 'lap-max';
            return '';
        };

        return {
            t,
            toolData,
            isRunning,
            laps,
            startChronometer,
            stopChronometer,
            resetChronometer,
            recordLap,
            formattedTime,
            formatTime,
            lapClass,
            sortedLaps,
        };
    },
});

</script>

<style scoped>
.laps-container {
    margin-top: 15px;
    width: 350px;
}

.lap-row {
    font-family: monospace;
    font-size: 14px;
}

.n-button {
    min-width: 65px;
}

.time-display {
    font-size: 42px;
    font-family: monospace;
    letter-spacing: 0.01em;
    text-align: center;
    margin: 20px 0;
}

.control-buttons {
    margin-top: 15px;
    width: 150px;
}

.chronometer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    margin: 0 auto;
    padding-top: 32px;
    margin: auto;
}

.lap-min {
    color: green;
}

.lap-max {
    color: red;
}
</style>
