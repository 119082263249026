<template>
  <div class="tool-container">

    <ToolHeader :toolData="toolData" />

    <n-space vertical size="large">
      <n-input v-model:value="userAgent" :placeholder="t('user_agent_parser_input_placeholder')" type="textarea"
        rows="3" clearable />

      <!-- Блоки с результатами -->
      <n-grid :x-gap="16" :y-gap="16" :cols="gridCols">
        <!-- Browser -->
        <n-grid-item>
          <n-card class="info-card">
            <n-space align="center">
              <n-icon size="32">
                <Browser />
              </n-icon>
              <h3 class="info-title">{{ $t('user_agent_parser_browser_label') }}</h3>
            </n-space>
            <n-space>
              <n-tag v-if="browserName" round size="large" type="success">{{ browserName }}</n-tag>
              <n-tag v-if="browserVersion" round size="large">{{ browserVersion }}</n-tag>
              <div v-if="!browserName" class="no-data">{{ $t('user_agent_parser_no_browser_data') }}</div>
            </n-space>
          </n-card>
        </n-grid-item>

        <!-- Engine -->
        <n-grid-item>
          <n-card class="info-card">
            <n-space align="center">
              <n-icon size="32">
                <Engine />
              </n-icon>
              <h3 class="info-title">{{ $t('user_agent_parser_engine_label') }}</h3>
            </n-space>
            <n-space>
              <n-tag v-if="engineName" round size="large" type="success">{{ engineName }}</n-tag>
              <n-tag v-if="engineVersion" round size="large">{{ engineVersion }}</n-tag>
              <div v-if="!engineName" class="no-data">{{ $t('user_agent_parser_no_engine_data') }}</div>
            </n-space>
          </n-card>
        </n-grid-item>

        <!-- OS -->
        <n-grid-item>
          <n-card class="info-card">
            <n-space align="center">
              <n-icon size="32">
                <DeviceDesktopAnalytics />
              </n-icon>
              <h3 class="info-title">{{ $t('user_agent_parser_os_label') }}</h3>
            </n-space>
            <n-space align="center">
              <n-tag v-if="osName" round size="large" type="success">{{ osName }}</n-tag>
              <n-tag v-if="osVersion" round size="large">{{ osVersion }}</n-tag>
              <div v-if="!osName" class="no-data">{{ $t('user_agent_parser_no_os_name') }}</div>
              <div v-if="!osVersion" class="no-data">{{ $t('user_agent_parser_no_os_version') }}</div>
            </n-space>
          </n-card>
        </n-grid-item>

        <!-- Device -->
        <n-grid-item>
          <n-card class="info-card">
            <n-space align="center">
              <n-icon size="32">
                <Devices2 />
              </n-icon>
              <h3 class="info-title">{{ $t('user_agent_parser_device_label') }}</h3>
            </n-space>
            <n-space>
              <n-tag v-if="deviceModel" round size="large">{{ deviceModel }}</n-tag>
              <n-tag v-if="deviceType" round size="large">{{ deviceType }}</n-tag>
              <n-tag v-if="deviceVendor" round size="large">{{ deviceVendor }}</n-tag>
              <div v-if="!deviceModel && !deviceType && !deviceVendor" class="no-data">
                {{ $t('user_agent_parser_no_device_data') }}
              </div>
            </n-space>
          </n-card>
        </n-grid-item>

        <!-- CPU -->
        <n-grid-item>
          <n-card class="info-card">
            <n-space align="center">
              <n-icon size="32">
                <Cpu />
              </n-icon>
              <h3 class="info-title">{{ $t('user_agent_parser_cpu_label') }}</h3>
            </n-space>
            <n-space>
              <n-tag v-if="cpuArchitecture" round size="large" type="success">{{ cpuArchitecture }}</n-tag>
              <div v-if="!cpuArchitecture" class="no-data">{{ $t('user_agent_parser_no_cpu_data') }}</div>
            </n-space>
          </n-card>
        </n-grid-item>
      </n-grid>
    </n-space>
  </div>
</template>


<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import UAParser from 'ua-parser-js';
import { Browser, Engine, DeviceDesktopAnalytics, Devices2, Cpu } from '@vicons/tabler'; // Импорт иконок Tabler
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
  name: 'UserAgentParser',
  components: { Browser, Engine, DeviceDesktopAnalytics, Devices2, Cpu, ToolHeader },
  setup() {

    const { t } = useI18n();

    const toolData = toolsData.development.find(tool => tool.path === '/user-agent-parser');

    const userAgent = ref(navigator.userAgent); // По умолчанию использовать текущий user-agent
    const parser = new UAParser();

    const browserName = ref('');
    const browserVersion = ref('');
    const engineName = ref('');
    const engineVersion = ref('');
    const osName = ref('');
    const osVersion = ref('');
    const deviceModel = ref('');
    const deviceType = ref('');
    const deviceVendor = ref('');
    const cpuArchitecture = ref('');

    const isMobile = ref(window.innerWidth <= 768);
    const gridCols = computed(() => (isMobile.value ? 1 : 2)); // Одна колонка для мобильных, две для десктопа

    // Слушаем изменение размера окна и обновляем количество колонок
    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    // Функция парсинга user-agent строки
    const parseUserAgent = () => {
      const result = parser.setUA(userAgent.value).getResult();

      browserName.value = result.browser.name || '';
      browserVersion.value = result.browser.version || '';
      engineName.value = result.engine.name || '';
      engineVersion.value = result.engine.version || '';
      osName.value = result.os.name || '';
      osVersion.value = result.os.version || '';
      deviceModel.value = result.device.model || '';
      deviceType.value = result.device.type || '';
      deviceVendor.value = result.device.vendor || '';
      cpuArchitecture.value = result.cpu.architecture || '';
    };

    // Сразу парсим при первой загрузке страницы
    parseUserAgent();

    // Слушаем изменения в поле userAgent и парсим каждый раз при изменении
    watch(userAgent, () => {
      parseUserAgent();
    });

    return {
      userAgent,
      browserName,
      browserVersion,
      engineName,
      engineVersion,
      osName,
      osVersion,
      deviceModel,
      deviceType,
      deviceVendor,
      cpuArchitecture,
      gridCols,
      parseUserAgent,
      toolData,
      t
    };
  },
});

</script>


<style scoped>
.info-card {
  min-height: 150px;
}

.n-tag {
  font-size: 16px;
  padding: 6px 12px;
}

.n-input {
  margin-bottom: 16px;
}

.info-title {
  font-size: 18px;
  font-weight: 400;
  margin-right: 8px;
}

.no-data {
  color: #a1a1a1;
  font-size: 14px;
  font-style: italic;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .info-card {
    min-height: 120px;
  }

  .info-title {
    font-size: 16px;
  }

  .n-tag {
    font-size: 14px;
    padding: 4px 8px;
  }
}
</style>
