<template>
  <n-config-provider>
    <div class="tool-container">

      <ToolHeader :toolData="toolData" />

      <n-card>
        <n-space vertical size="large">
          <n-flex gap-2 vertical>
            <span>UUID version</span>
            <n-space style="gap:0.5rem">
              <n-button v-for="version in versions" :key="version.value"
                :type="selectedVersion === version.value ? 'primary' : 'default'" @click="selectVersion(version)"
                secondary>
                {{ version.label }}
              </n-button>
            </n-space>
          </n-flex>

          <n-flex gap-2 vertical>
            <span>Quantity</span>
            <n-input-number v-model:value="quantity" :min="1" :max="512" class="quantity-input"
              @update:value="handleQuantityChange" />
            <n-input type="textarea" :value="generatedUUID" readonly rows="3" :autosize="{ minRows: 3 }" />
          </n-flex>

          <n-space justify="center">
            <n-button @click="copyUUID">Copy</n-button>
            <n-button @click="refreshUUID">Refresh</n-button>
          </n-space>
        </n-space>
      </n-card>
    </div>
  </n-config-provider>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
import { toolsData } from '@/toolsData';
import { useNotification } from 'naive-ui';
import ToolHeader from '@/components/ToolHeader.vue';
import { saveUUIDGeneratorData, getUUIDGeneratorData } from '../../indexedDbService';

export default defineComponent({
  name: 'UuidGenerator',
  components: { ToolHeader },
  setup() {
    const toolData = toolsData.crypto.find(tool => tool.path === '/uuid-generator');

    const versions = [
      { value: 'nil', label: 'NIL' },
      { value: 'v1', label: 'v1' },
      { value: 'v4', label: 'v4' }
    ];

    const selectedVersion = ref('v4');
    const quantity = ref(1);
    const generatedUUID = ref('');
    const previousQuantity = ref(1);
    const notification = useNotification();

    const generateUUID = (count = quantity.value) => {
      let uuids = [];
      for (let i = 0; i < count; i++) {
        switch (selectedVersion.value) {
          case 'v1':
            uuids.push(uuidv1());
            break;
          case 'v4':
            uuids.push(uuidv4());
            break;
          case 'nil':
            uuids.push('00000000-0000-0000-0000-000000000000');
            break;
        }
      }
      return uuids;
    };

    const handleQuantityChange = async () => {
      const diff = quantity.value - previousQuantity.value;
      if (diff > 0) {
        const newUUIDs = generateUUID(diff);
        generatedUUID.value += (generatedUUID.value ? '\n' : '') + newUUIDs.join('\n');
      } else if (diff < 0) {
        const splitUUIDs = generatedUUID.value.split('\n');
        generatedUUID.value = splitUUIDs.slice(0, quantity.value).join('\n');
      }
      previousQuantity.value = quantity.value;
      await saveData(); // Сохраняем данные
    };

    const refreshUUID = async () => {
      generatedUUID.value = generateUUID(quantity.value).join('\n');
      await saveData(); // Сохраняем данные
    };

    const copyUUID = () => {
      navigator.clipboard.writeText(generatedUUID.value).then(() => {
        notification.success({
          content: 'UUIDs copied successfully!',
          duration: 2000,
        });
      });
    };

    const selectVersion = async (version) => {
      selectedVersion.value = version.value;
      await refreshUUID(); // Перегенерируем UUID и сохраняем данные
    };

    const saveData = async () => {
      await saveUUIDGeneratorData({
        version: selectedVersion.value,
        quantity: quantity.value,
        uuids: generatedUUID.value,
      });
    };

    const loadData = async () => {
      const data = await getUUIDGeneratorData();
      selectedVersion.value = data.version;
      quantity.value = data.quantity;
      generatedUUID.value = data.uuids;
      previousQuantity.value = data.quantity;
    };

    onMounted(async () => {
      await loadData(); // Загружаем данные при монтировании компонента
      if (!generatedUUID.value) {
        await refreshUUID(); // Генерируем UUID, если данные отсутствуют
      }
    });

    return {
      toolData,
      versions,
      selectedVersion,
      quantity,
      generatedUUID,
      refreshUUID,
      copyUUID,
      selectVersion,
      handleQuantityChange
    };
  }
});

</script>

<style scoped>
.description {
  margin-bottom: 16px;
  font-size: 14px;
  color: #999;
}

.n-button {
  min-width: 50px;
  text-align: center;
}

.uuid-output {
  margin-bottom: 16px;
  font-size: 14px;
}
</style>
