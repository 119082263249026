<template>
    <div class="tool-container">

        <ToolHeader :toolData="toolData" />

        <n-card>
            <n-space vertical size="large">
                <div class="input-container">
                    <!-- Ввод времени -->
                    <n-input-number v-model:value="inputTime" :min="0" class="time-input"
                        placeholder="Enter your time to convert" />

                    <!-- Выбор единицы времени -->
                    <n-select v-model:value="inputUnit" class="selector" :options="timeUnits" />
                </div>

                <n-divider style="margin-top: 10px;" />

                <!-- Результаты -->
                <n-space vertical>
                    <div v-for="(value, key) in convertedResults" :key="key" class="converted-row">
                        <label class="converted-label">{{ key }}</label>
                        <n-input v-model:value="convertedResults[key]" @input="convertBack(key)" />
                    </div>
                </n-space>
            </n-space>
        </n-card>
    </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { toolsData } from '@/toolsData';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'TimeCalculator',
    components: { ToolHeader },
    setup() {
        const toolData = toolsData.measurement.find(tool => tool.path === '/time-unit-converter');
        const inputTime = ref(null);
        const inputUnit = ref('seconds');

        const timeUnits = [
            { label: 'Milliseconds', value: 'milliseconds' },
            { label: 'Seconds', value: 'seconds' },
            { label: 'Minutes', value: 'minutes' },
            { label: 'Hours', value: 'hours' },
            { label: 'Days', value: 'days' },
            { label: 'Weeks', value: 'weeks' },
            { label: 'Months (30 days)', value: 'months' },
            { label: 'Years (365 days)', value: 'years' }
        ];

        const conversionFactors = {
            milliseconds: 1,
            seconds: 1000,
            minutes: 60000,
            hours: 3600000,
            days: 86400000,
            weeks: 604800000,
            months: 2592000000,
            years: 31536000000
        };

        const convertedResults = ref({
            Milliseconds: 0,
            Seconds: 0,
            Minutes: 0,
            Hours: 0,
            Days: 0,
            Weeks: 0,
            Months: 0,
            Years: 0
        });

        const calculateResults = () => {
            const baseValue = parseFloat(inputTime.value) * conversionFactors[inputUnit.value];
            const formatResult = value => (Number.isInteger(value) ? value : value.toFixed(6));

            convertedResults.value = {
                Milliseconds: formatResult(baseValue),
                Seconds: formatResult(baseValue / conversionFactors.seconds),
                Minutes: formatResult(baseValue / conversionFactors.minutes),
                Hours: formatResult(baseValue / conversionFactors.hours),
                Days: formatResult(baseValue / conversionFactors.days),
                Weeks: formatResult(baseValue / conversionFactors.weeks),
                Months: formatResult(baseValue / conversionFactors.months),
                Years: formatResult(baseValue / conversionFactors.years)
            };
        };

        watch([inputTime, inputUnit], calculateResults);

        const convertBack = (unit) => {
            const reverseFactor = conversionFactors[unit.toLowerCase()];
            inputTime.value = (parseFloat(convertedResults.value[unit]) * reverseFactor) / conversionFactors[inputUnit.value];
        };

        return {
            inputTime,
            inputUnit,
            timeUnits,
            convertedResults,
            toolData,
            convertBack
        };
    }
});
</script>

<style scoped>
.selector {
    min-width: 120px;
    max-width: 150px;
    margin-left: 10px;
}

.time-input {
    flex: 1;
    min-width: 200px;
}

.input-container {
    display: flex;
    justify-content: space-between;
}

.converted-row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.converted-label {
    width: 100px;
    text-align: right;
}

.n-input {
    flex: 1;
}
</style>
