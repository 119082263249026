import { openDB } from 'idb';


// JSON FORMATTER 

const dbPromise = openDB('jsonTabsDB', 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('tabs')) {
            db.createObjectStore('tabs', { keyPath: 'name' });
        }
    },
}).catch((error) => {
    console.error('Ошибка при открытии IndexedDB:', error);
});

export async function saveAllTabs(tabs) {
    try {
        const db = await dbPromise;
        const transaction = db.transaction('tabs', 'readwrite');
        await transaction.objectStore('tabs').clear(); // Очищаем перед сохранением

        for (let i = 0; i < tabs.length; i++) {
            const tab = { ...tabs[i], order: i }; // Добавляем `order` для сохранения порядка
            const sanitizedTab = JSON.parse(JSON.stringify(tab));
            await transaction.objectStore('tabs').put(sanitizedTab);
        }
        await transaction.done;
    } catch (error) {
        console.error('Ошибка при сохранении всех вкладок:', error);
    }
}

// Упавление вкладками JsonFormatter
export async function saveTab(tab) {
    try {
        const db = await dbPromise;
        const sanitizedTab = JSON.parse(JSON.stringify(tab)); // Удаляем неклонируемые свойства
        await db.put('tabs', sanitizedTab);
    } catch (error) {
        console.error('Ошибка при сохранении вкладки:', error);
    }
}

export async function getAllTabs() {
    try {
        const db = await dbPromise;
        let tabs = await db.getAll('tabs');

        // Сортируем вкладки по полю `order`
        tabs = tabs
            .map(tab => ({ ...tab, order: tab.order ?? 0 }))
            .sort((a, b) => a.order - b.order);


        return tabs;
    } catch (error) {
        console.error('Ошибка при получении вкладок:', error);
        return [];
    }
}

export async function deleteTab(name) {
    const db = await dbPromise;
    await db.delete('tabs', name);
}

export async function clearAllTabs() {
    const db = await dbPromise;
    await db.clear('tabs');
}

// УТИЛИТЫ

const utilityDBPromise = openDB('utilityDB', 4, {
    upgrade(db) {
        // Создаём хранилища для различных утилит
        if (!db.objectStoreNames.contains('hexInput')) {
            db.createObjectStore('hexInput', { keyPath: 'id' });
        }
        // Создаем хранилище для состояния свитча
        if (!db.objectStoreNames.contains('switchState')) {
            db.createObjectStore('switchState', { keyPath: 'name' });
        }
        // Добавляем новое хранилище для uuidGenerator
        if (!db.objectStoreNames.contains('uuidGenerator')) {
            db.createObjectStore('uuidGenerator', { keyPath: 'id' });
        }
        // Добавляем новое хранилище для base64Converter
        if (!db.objectStoreNames.contains('base64Converter')) {
            db.createObjectStore('base64Converter', { keyPath: 'id' });
        }
    },
}).catch((error) => {
    console.error('Ошибка при открытии базы данных утилит:', error);
});

// HexaDecimal.vue
export async function saveHexInput(data) {
    try {
        const db = await utilityDBPromise;
        await db.put('hexInput', { id: 'userInput', inputData: data });
    } catch (error) {
        console.error('Ошибка при сохранении данных ввода для Hexadecimal:', error);
    }
}
export async function getHexInput() {
    try {
        const db = await utilityDBPromise;
        const data = await db.get('hexInput', 'userInput');
        return data ? data.inputData : '';
    } catch (error) {
        console.error('Ошибка при получении данных ввода для Hexadecimal:', error);
        return '';
    }
}
export async function saveSwitchState(state) {

    try {
        const db = await utilityDBPromise;
        await db.put('switchState', { name: 'tryToParseJson', value: state });
    } catch (error) {
        console.error('Ошибка при сохранении данных ввода для Hexadecimal:', error);
    }
}
export async function getSwitchState() {
    try {
        const db = await utilityDBPromise;
        const result = await db.get('switchState', 'tryToParseJson');
        return result ? result.value : false;
    } catch (error) {
        console.error('Ошибка при получении данных ввода для Hexadecimal:', error);
        return false;
    }
}

// UuidGenerator.vue
// Сохранение данных UUID Generator
export async function saveUUIDGeneratorData(data) {
    try {
        const db = await utilityDBPromise;
        await db.put('uuidGenerator', { id: 'uuidData', ...data });
    } catch (error) {
        console.error('Ошибка при сохранении данных для UUID Generator:', error);
    }
}
// Получение данных UUID Generator
export async function getUUIDGeneratorData() {
    try {
        const db = await utilityDBPromise;
        const data = await db.get('uuidGenerator', 'uuidData');
        return data || { version: 'v4', quantity: 1, uuids: '' }; // Значения по умолчанию
    } catch (error) {
        console.error('Ошибка при получении данных для UUID Generator:', error);
        return { version: 'v4', quantity: 1, uuids: '' };
    }
}

export async function saveBase64ConverterData(data) {
    try {
        const db = await utilityDBPromise;
        await db.put('base64Converter', { id: 'base64Data', ...data });
    } catch (error) {
        console.error('Ошибка при сохранении данных Base64 Converter:', error);
    }
}

export async function getBase64ConverterData() {
    try {
        const db = await utilityDBPromise;
        const data = await db.get('base64Converter', 'base64Data');
        return data || {
            fileExtension: '',
            base64StringToFile: '',
            fileBase64String: '',
            activeTab: 'base64ToFile', // Значение по умолчанию
        };
    } catch (error) {
        console.error('Ошибка при получении данных Base64 Converter:', error);
        return {
            fileExtension: '',
            base64StringToFile: '',
            fileBase64String: '',
            activeTab: 'base64ToFile',
        };
    }
}

