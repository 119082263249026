<template>
    <n-config-provider>
        <div class="tool-container">

            <ToolHeader :toolData="toolData" />

            <n-card>
                <n-tabs v-model:value="activeTab" size="large" type="line" justify-content="space-evenly">
                    <!-- Вкладка "String to Base64" -->
                    <n-tab-pane name="encode" :tab="t('base64_string_converter_tab_string_to_base64_title')">
                        <n-space vertical size="large">

                            <!-- Ввод строки -->
                            <n-flex gap="2" vertical>
                                <label>{{ $t('base64_string_converter_tab_string_to_base64_input_label') }}</label>
                                <n-input v-model:value="inputStringToEncode"
                                    :placeholder="t('base64_string_converter_tab_string_to_base64_input_placeholder')"
                                    type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" />
                            </n-flex>

                            <!-- Результат кодирования -->
                            <n-flex gap="2" vertical>
                                <label>{{ $t('base64_string_converter_tab_string_to_base64_result_label') }}</label>
                                <n-input :value="encodedResult" readonly
                                    :placeholder="t('base64_string_converter_tab_string_to_base64_result_placeholder')"
                                    type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" />
                            </n-flex>

                            <n-space justify="center">
                                <!-- Кнопка для копирования закодированного Base64 -->
                                <n-button @click="copyToClipboard(encodedResult, 'Base64')">
                                    {{ $t('base64_string_converter_tab_string_to_base64_copy_button') }}
                                </n-button>
                            </n-space>
                        </n-space>
                    </n-tab-pane>

                    <!-- Вкладка "Base64 to String" -->
                    <n-tab-pane name="decode" :tab="t('base64_string_converter_tab_base64_to_string_title')">
                        <n-space vertical size="large">

                            <!-- Ввод строки в Base64 -->
                            <n-flex gap="2" vertical>
                                <label>{{ $t('base64_string_converter_tab_base64_to_string_input_label') }}</label>
                                <n-input v-model:value="inputStringToDecode"
                                    :placeholder="t('base64_string_converter_tab_base64_to_string_input_placeholder')"
                                    type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" />
                            </n-flex>

                            <!-- Результат декодирования -->
                            <n-flex gap="2" vertical>
                                <label>{{ $t('base64_string_converter_tab_base64_to_string_result_label') }}</label>
                                <n-input :value="decodedResult" readonly
                                    :placeholder="t('base64_string_converter_tab_base64_to_string_result_placeholder')"
                                    type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" />
                            </n-flex>

                            <n-space justify="center">
                                <!-- Кнопка для копирования декодированной строки -->
                                <n-button @click="copyToClipboard(decodedResult, 'Decoded string')">
                                    {{ $t('base64_string_converter_tab_base64_to_string_copy_button') }}
                                </n-button>
                            </n-space>
                        </n-space>
                    </n-tab-pane>
                </n-tabs>
            </n-card>
        </div>
    </n-config-provider>
</template>




<script>
import { ref, watch } from 'vue';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';
import ToolHeader from '@/components/ToolHeader.vue';

export default {
    name: 'Base64Converter',
    components: { ToolHeader },
    setup() {
        const { t } = useI18n(); // Получаем функцию t для локализации

        const inputStringToEncode = ref('');
        const encodedResult = ref('');
        const inputStringToDecode = ref('');
        const decodedResult = ref('');
        const notification = useNotification();
        const activeTab = ref('encode'); // Управление активной вкладкой

        const toolData = toolsData.converter.find(tool => tool.path === '/base64-string-converter');

        function encodeBase64() {
            try {
                const encoded = btoa(inputStringToEncode.value);
                encodedResult.value = encoded;
            } catch (error) {
                encodedResult.value = t('base64_string_converter_notification_invalid_input_encoding');
            }
        }

        function decodeBase64() {
            try {
                const decoded = atob(inputStringToDecode.value);
                decodedResult.value = decoded;
            } catch (error) {
                decodedResult.value = t('base64_string_converter_notification_invalid_input_decoding');
            }
        }

        // Функция для копирования текста в буфер обмена
        function copyToClipboard(text, label) {
            navigator.clipboard.writeText(text.value).then(() => {
                notification.success({
                    content: t('base64_string_converter_notification_copied_to_clipboard'),
                    duration: 2000,
                });
            }).catch(() => {
                notification.error({
                    content: `Failed to copy ${label}.`,
                    duration: 2000,
                });
            });
        }

        // Watcher для автоматической кодировки и декодировки
        watch([inputStringToEncode, activeTab], () => {
            if (activeTab.value === 'encode') {
                encodeBase64();
            }
        });

        watch([inputStringToDecode, activeTab], () => {
            if (activeTab.value === 'decode') {
                decodeBase64();
            }
        });

        return {
            t,
            inputStringToEncode,
            encodedResult,
            inputStringToDecode,
            decodedResult,
            activeTab,
            toolData,
            copyToClipboard,
        };
    },
};

</script>

<style scoped></style>