<template>
  <div class="homepage-container">

    <n-collapse-transition :show="haveFavoriteTools">
      <n-space size="small">
        <h2 style="margin-top: 0;">{{ $t('home_page_favorite_tools_title') }}
          <n-tooltip trigger="hover" :show-arrow="false">
            <template #trigger>
              <n-button text size="large">
                <template #icon>
                  <n-icon style="opacity: 0.6;">
                    <Edit />
                  </n-icon>
                </template>
              </n-button>
            </template>
            {{ $t('home_page_favorite_tools_tooltip') }}
          </n-tooltip>
        </h2>

      </n-space>
      <!-- Кастомная сетка с Flexbox -->
      <VueDraggable v-model="favoriteTools" animation="150" ghostClass="dragging-card" class="grid-container"
        @start="onDragStart" @end="onDragEnd">
        <div v-for="tool in favoriteTools" :key="tool.name" class="grid-item" :class="{
          'fade-in': isAdding(tool),
          'fade-out': isRemoving(tool)
        }">
          <n-card :bordered="false" size="large" transition class="tool-card" :class="{ 'dragging': isDragging }"
            @click="navigateTo(tool.path)">
            <div class="card-content">
              <div class="card-header">
                <n-icon class="card-icon" :size="32">
                  <component :is="tool.icon" />
                </n-icon>

                <n-tooltip trigger="hover" :show-arrow="false">
                  <template #trigger>
                    <n-button quaternary circle style="color:#1fa54c">
                      <template #icon>
                        <n-icon :size="21" @click.stop="toggleFavorite(tool)">
                          <Heart16Filled />
                        </n-icon>
                      </template>
                    </n-button>
                  </template>
                  {{ $t('home_page_remove_from_favorites') }}
                </n-tooltip>
              </div>
              <h3 class="card-tool-title">{{ tool.name }}</h3>
              <p class="card-tool-description">{{ $t(tool.description) }}</p>
            </div>
          </n-card>
        </div>
      </VueDraggable>
    </n-collapse-transition>

    <h2>{{ $t('home_page_all_tools_title') }}</h2>

    <div class="grid-container">
      <div v-for="tool in allTools" :key="tool.name" class="grid-item">
        <n-card :bordered="false" size="large" transition class="tool-card" @click="navigateTo(tool.path)">
          <div class="card-content">
            <div class="card-header">
              <n-icon class="card-icon" :size="32">
                <component :is="tool.icon" />
              </n-icon>

              <div v-if="isFavorite(tool)">
                <n-tooltip trigger="hover" :show-arrow="false">
                  <template #trigger>
                    <n-button quaternary circle style="color:#1fa54c">
                      <template #icon>
                        <n-icon :size="21" @click.stop="toggleFavorite(tool)">
                          <Heart16Filled />
                        </n-icon>
                      </template>
                    </n-button>
                  </template>
                  {{ $t('home_page_remove_from_favorites') }}
                </n-tooltip>
              </div>

              <div v-else>
                <n-tooltip trigger="hover" :show-arrow="false">
                  <template #trigger>
                    <n-button quaternary circle>
                      <template #icon>
                        <n-icon :size="21" class="favorite-icon" @click.stop="toggleFavorite(tool)">
                          <Heart16Filled />
                        </n-icon>
                      </template>
                    </n-button>
                  </template>
                  {{ $t('home_page_add_to_favorites') }}
                </n-tooltip>
              </div>

            </div>
            <h3 class="card-tool-title">{{ tool.name }}</h3>
            <p class="card-tool-description">{{ $t(tool.description) }}</p>
          </div>
        </n-card>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted, computed, markRaw } from 'vue';
import { useRouter } from 'vue-router';
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';
import { Edit } from '@vicons/tabler';
import { Heart16Filled } from '@vicons/fluent';
import { saveFavoritesOrder, getFavoritesOrder } from '../../favouritesIDB';
import { VueDraggable } from 'vue-draggable-plus';

export default defineComponent({
  name: 'HomePage',
  components: {
    Heart16Filled,
    VueDraggable,
    Edit
  },
  setup() {
    const { t } = useI18n();
    const favoriteTools = ref([]);
    const allTools = [
      ...toolsData.development,
      ...toolsData.crypto,
      ...toolsData.converter,
      ...toolsData.text,
      ...toolsData.measurement,
    ].map(tool => ({
      ...tool,
      icon: markRaw(tool.icon),
    }));

    const gridCols = ref(4);
    const router = useRouter();

    const addingItems = ref(new Set());
    const removingItems = ref(new Set());

    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1350) {
        gridCols.value = 4;
      } else if (width > 992) {
        gridCols.value = 3;
      } else if (width > 600) {
        gridCols.value = 2;
      } else {
        gridCols.value = 1;
      }
    };

    const loadFavoritesFromIDB = async () => {
      const savedFavorites = await getFavoritesOrder();
      // Восстанавливаем иконки для избранных инструментов
      favoriteTools.value = savedFavorites.map(favTool => {
        const matchingTool = allTools.find(tool => tool.name === favTool.name);
        return {
          ...favTool,
          icon: markRaw(matchingTool ? matchingTool.icon : favTool.icon),
        };
      });
    };

    const dispatchFavoriteToolsUpdate = () => {
      window.dispatchEvent(new CustomEvent('favorite-tools-update', { detail: { favoriteTools: favoriteTools.value } }));
    };

    const toggleFavorite = async (tool) => {
      if (isFavorite(tool)) {
        // Добавляем в набор удаляющихся элементов
        removingItems.value.add(tool.name);

        // Сразу обновляем `favoriteTools`, удаляя элемент
        favoriteTools.value = favoriteTools.value.filter((t) => t.name !== tool.name);

        // Удаляем из набора `removingItems` после завершения анимации
        setTimeout(() => {
          removingItems.value.delete(tool.name);
        }, 300);
      } else {
        // Добавляем элемент в избранное
        addingItems.value.add(tool.name);
        favoriteTools.value.push(tool);

        // Удаляем из набора добавляющихся элементов после завершения анимации
        setTimeout(() => {
          addingItems.value.delete(tool.name);
        }, 300);
      }

      await saveFavoritesOrder(favoriteTools.value);
      dispatchFavoriteToolsUpdate(); // Отправляем ивент после обновления избранных
    };


    const isAdding = (tool) => addingItems.value.has(tool.name);
    const isRemoving = (tool) => removingItems.value.has(tool.name);

    const isFavorite = (tool) => favoriteTools.value.some((t) => t.name === tool.name);

    const saveFavoritesOrderOnEnd = async () => {
      await saveFavoritesOrder(favoriteTools.value);
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener('resize', handleResize);
      await loadFavoritesFromIDB();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize); // Очищаем слушатель при разрушении компонента
    });

    const navigateTo = (path) => {
      router.push(path);
    };

    const haveFavoriteTools = computed(() => favoriteTools.value.length > 0);

    const isDragging = ref(false);

    const onDragStart = () => {
      isDragging.value = true;
    };

    const onDragEnd = async () => {
      isDragging.value = false;
      await saveFavoritesOrder(favoriteTools.value);
      dispatchFavoriteToolsUpdate(); // Отправляем ивент после завершения перетаскивания
    };

    return {
      t,
      favoriteTools,
      allTools,
      gridCols,
      navigateTo,
      toggleFavorite,
      isFavorite,
      haveFavoriteTools,
      saveFavoritesOrderOnEnd,
      onDragStart,
      onDragEnd,
      isDragging,
      isAdding,
      isRemoving
    };
  },
});
</script>

<style scoped>
.homepage-container h2 {
  opacity: 0.8;
  font-weight: 500;
  font-size: 18px;
}

/* Стили для кастомной сетки */
.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.grid-item {
  flex: 1 1 calc(25% - 16px);
  /* Настроим ширину карточек на 4 колонки */
  max-width: calc(25% - 16px);
}

@media (max-width: 1350px) {
  .grid-item {
    flex: 1 1 calc(33.33% - 16px);
    /* 3 колонки */
    max-width: calc(33.33% - 16px);
  }
}

@media (max-width: 992px) {
  .grid-item {
    flex: 1 1 calc(50% - 16px);
    /* 2 колонки */
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 600px) {
  .grid-item {
    flex: 1 1 100%;
    /* 1 колонка */
    max-width: 100%;
  }
}

.tool-card {
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
  border: 2px solid transparent;
  border-radius: 6px;
}

.tool-card:hover {
  border-color: #1fa54c;
}

.card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.card-tool-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.card-tool-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0;
  color: #666;
}

.card-icon {
  opacity: 0.4;
}

.favorite-icon {
  cursor: pointer;
  opacity: 0.4;
}

/* Стили для отключения hover и обводки во время перетаскивания */
.tool-card.dragging {
  border-color: transparent !important;
  box-shadow: none !important;
  transform: none !important;
  pointer-events: none;
}

.tool-card.dragging:hover {
  border-color: transparent;
  box-shadow: none;
  transform: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

.fade-in {
  animation: fadeIn 0.3s ease forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease forwards;
}
</style>
