<template>
  <n-config-provider>
    <div class="tool-container">
      <ToolHeader :toolData="toolData" />

      <n-card>
        <n-space vertical size="large">
          <!-- Контейнер для инпута и селектора -->
          <div class="input-container">
            <!-- Инпут для ввода строки даты -->
            <n-input class="date-input" v-model:value="inputDate" placeholder="Enter a date string..." clearable />

            <!-- Селектор справа от инпута -->
            <n-select class="format-select" v-model:value="formatIndex" :options="formatOptions"
              placeholder="Select format" />
          </div>

          <n-divider style="margin-top: 10px;" />

          <!-- Вывод результатов преобразования -->
          <n-space vertical>
            <div class="converted-row" v-for="format in formats" :key="format.name">
              <label class="converted-label">{{ format.name }}</label>
              <n-input :value="formatDateUsingFormatter(format.fromDate, normalizedDate)" readonly>
                <template #suffix>
                  <n-button text style="font-size: 16px" @click="copyToClipboard(format.fromDate, normalizedDate, format.name)">
                    <n-icon :component="Copy" />
                  </n-button>
                </template>
              </n-input>
            </div>
          </n-space>
        </n-space>
      </n-card>
    </div>
  </n-config-provider>
</template>


<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { formatISO, formatISO9075, formatRFC3339, formatRFC7231, fromUnixTime, getUnixTime, getTime } from 'date-fns';
import { Copy } from '@vicons/tabler';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import ToolHeader from '@/components/ToolHeader.vue';

export default {
  name: 'DateTimeConverter',
  components: { ToolHeader },
  setup() {
    const toolData = toolsData.converter.find(tool => tool.path === '/date-time-converter');

    const inputDate = ref('');
    const formatIndex = ref(6);
    const currentTime = ref(new Date());
    const notification = useNotification();

    let timer;
    onMounted(() => {
      timer = setInterval(() => {
        currentTime.value = new Date();
      }, 1);
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    const formats = [
      {
        name: 'JS locale date',
        fromDate: (date) => date.toString(),
        toDate: (date) => new Date(date),
      },
      {
        name: 'ISO 8601',
        fromDate: formatISO,
        toDate: (date) => new Date(date),
      },
      {
        name: 'ISO 9075',
        fromDate: formatISO9075,
        toDate: (date) => new Date(date),
      },
      {
        name: 'RFC 3339',
        fromDate: formatRFC3339,
        toDate: (date) => new Date(date),
      },
      {
        name: 'RFC 7231',
        fromDate: formatRFC7231,
        toDate: (date) => new Date(date),
      },
      {
        name: 'Unixtime',
        fromDate: (date) => String(getUnixTime(date)),
        toDate: (sec) => fromUnixTime(+sec),
      },
      {
        name: 'Timestamp',
        fromDate: (date) => String(getTime(date)),
        toDate: (ms) => new Date(parseInt(ms)),
      },
      {
        name: 'UTC format',
        fromDate: (date) => date.toUTCString(),
        toDate: (date) => new Date(date),
      },
    ];

    const formatOptions = formats.map((format, index) => ({
      label: format.name,
      value: index,
    }));

    const normalizedDate = computed(() => {
      if (!inputDate.value) {
        return currentTime.value;
      }

      const selectedFormat = formats[formatIndex.value];
      try {
        return selectedFormat.toDate(inputDate.value);
      } catch {
        return undefined;
      }
    });

    function formatDateUsingFormatter(formatter, date) {
      if (!date) {
        return 'Invalid date...';
      }

      try {
        return formatter(date);
      } catch {
        return 'Invalid date...';
      }
    }

    function copyToClipboard(formatter, date, label) {
      const formattedDate = formatDateUsingFormatter(formatter, date);
      navigator.clipboard.writeText(formattedDate).then(() => {
        notification.success({
          content: `${label} copied successfully!`,
          duration: 2000,
        });
      });
    }

    function autoSelectFormat(dateString) {
      for (let i = 0; i < formats.length; i++) {
        try {
          const parsedDate = formats[i].toDate(dateString);
          if (parsedDate.toString() !== 'Invalid Date') {
            formatIndex.value = i;
            break;
          }
        } catch (e) {
          continue;
        }
      }
    }
    watch(inputDate, (newVal) => {
      if (newVal) {
        autoSelectFormat(newVal);
      }
    });

    return {
      toolData,
      inputDate,
      formatIndex,
      formatOptions,
      formats,
      normalizedDate,
      formatDateUsingFormatter,
      copyToClipboard,
      Copy
    };
  },
};
</script>

<style scoped>
.input-container {
  display: flex;
  justify-content: space-between;
}

.date-input {
  flex: 1;
  min-width: 200px;
}

.format-select {
  min-width: 120px;
  max-width: 150px;
  margin-left: 10px;
}
.converted-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.converted-label {
  width: 105px;
  text-align: right;
}

.n-input {
  flex: 1;
}
</style>