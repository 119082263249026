<template>
    <header class="tool-header">
        <div class="title-container">
            <h1 class="tool-title">{{ toolData.name }}</h1>
            <n-tooltip trigger="hover" :show-arrow="false">
                <template #trigger>
                    <n-button quaternary circle :style="{ color: isFavorite ? '#1fa54c' : '#666' }"
                        @click="toggleFavorite">
                        <template #icon>
                            <n-icon :size="21">
                                <Heart16Filled />
                            </n-icon>
                        </template>
                    </n-button>
                </template>
                {{ isFavorite ? $t('home_page_remove_from_favorites') : $t('home_page_add_to_favorites') }}
            </n-tooltip>
        </div>
        <div class="separator"></div>
        <p class="tool-description">{{ $t(toolData.description) }}</p>
    </header>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Heart16Filled } from '@vicons/fluent';
import { saveFavoritesOrder, getFavoritesOrder } from '../../favouritesIDB';

export default {
    name: 'ToolHeader',
    components: { Heart16Filled },
    props: {
        toolData: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const isFavorite = ref(false);
        const favoriteTools = ref([]);

        // Проверка, есть ли утилита в избранном
        const checkFavoriteStatus = () => {
            isFavorite.value = favoriteTools.value.some(tool => tool.name === props.toolData.name);
        };

        // Загрузка избранных из IDB
        const loadFavorites = async () => {
            favoriteTools.value = await getFavoritesOrder() || [];
            checkFavoriteStatus();
        };

        // Переключение статуса избранного
        const toggleFavorite = async () => {
            if (isFavorite.value) {
                favoriteTools.value = favoriteTools.value.filter(tool => tool.name !== props.toolData.name);
            } else {
                favoriteTools.value.push(props.toolData);
            }
            await saveFavoritesOrder(favoriteTools.value);
            isFavorite.value = !isFavorite.value;
            dispatchFavoriteToolsUpdate();
        };

        const dispatchFavoriteToolsUpdate = () => {
            window.dispatchEvent(new CustomEvent('favorite-tools-update', { detail: { favoriteTools: favoriteTools.value } }));
        };

        onMounted(loadFavorites);

        return {
            toggleFavorite,
            isFavorite
        };
    }
};
</script>

<style scoped>
.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
</style>
