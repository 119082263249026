<template>
    <div class="tool-container">

        <ToolHeader :toolData="toolData" />

        <n-card>
            <n-tabs v-model:value="activeTab" size="large" type="line" justify-content="space-evenly" animated>
                <!-- Вкладка "String to Unicode" -->
                <n-tab-pane name="encode" tab="String to Unicode">
                    <n-space vertical size="large">

                        <!-- Выбор типа кодировки -->
                        <n-flex gap="2" vertical style="margin-bottom: 15px;">
                            <label>Select encoding type</label>
                            <n-select v-model:value="selectedType" :options="encodeOptions"
                                placeholder="Select encoding type" />
                        </n-flex>

                        <!-- Ввод строки -->
                        <n-flex gap="2" vertical>
                            <label>String to encode</label>
                            <n-input v-model:value="inputStringToEncode" placeholder="Enter a string to encode..."
                                type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" />
                        </n-flex>

                        <!-- Результат кодирования -->
                        <n-flex gap="2" vertical>
                            <label>Encoded result</label>
                            <n-input :value="encodedResult" readonly placeholder="Encoded result will appear here..."
                                type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" />
                        </n-flex>

                        <n-space justify="center">
                            <!-- Кнопка для копирования закодированной строки -->
                            <n-button @click="copyToClipboard(encodedResult, 'Encoded string')">Copy Encoded
                                String</n-button>
                        </n-space>
                    </n-space>
                </n-tab-pane>

                <!-- Вкладка "Unicode to String" -->
                <n-tab-pane name="decode" tab="Unicode to String">
                    <n-space vertical size="large">

                        <!-- Тип декодирования (определяется автоматически, если не выбран) -->
                        <n-flex gap="2" vertical style="margin-bottom: 15px;">
                            <label>Select or Auto-detect decoding type</label>
                            <n-select v-model:value="selectedType" :options="encodeOptions"
                                placeholder="Auto-detect or select decoding type" />
                        </n-flex>

                        <!-- Ввод строки для декодирования -->
                        <n-flex gap="2" vertical>
                            <label>Encoded string to decode</label>
                            <n-input v-model:value="inputStringToDecode"
                                placeholder="Enter an encoded string to decode..." type="textarea"
                                :autosize="{ minRows: 3, maxRows: 10, }" />
                        </n-flex>

                        <!-- Результат декодирования -->
                        <n-flex gap="2" vertical>
                            <label>Decoded result</label>
                            <n-input :value="decodedResult" readonly placeholder="Decoded result will appear here..."
                                type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" />
                        </n-flex>

                        <n-space justify="center">
                            <!-- Кнопка для копирования декодированной строки -->
                            <n-button @click="copyToClipboard(decodedResult, 'Decoded string')">Copy Decoded
                                String</n-button>
                        </n-space>
                    </n-space>
                </n-tab-pane>
            </n-tabs>
        </n-card>
    </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import ToolHeader from '@/components/ToolHeader.vue';

export default {
    name: 'UnicodeConverter',
    components: { ToolHeader },
    setup() {
        const inputStringToEncode = ref('');
        const encodedResult = ref('');
        const inputStringToDecode = ref('');
        const decodedResult = ref('');
        const notification = useNotification();
        const activeTab = ref('encode');
        const selectedType = ref('html');

        const encodeOptions = ref([
            { label: 'HTML Entities', value: 'html' },
            { label: 'Unicode Escape', value: 'unicode' }
        ]);

        const toolData = toolsData.converter.find(tool => tool.path === '/unicode-converter');

        function encodeString() {
            if (selectedType.value === 'html') {
                encodedResult.value = inputStringToEncode.value.replace(/./g, c => `&#${c.charCodeAt(0)};`);
            } else if (selectedType.value === 'unicode') {
                encodedResult.value = inputStringToEncode.value.replace(/./g, c => `\\u${c.charCodeAt(0).toString(16).padStart(4, '0')}`);
            }
        }

        function autoDetectDecodeType() {
            if (/&#\d+;/.test(inputStringToDecode.value)) {
                selectedType.value = 'html';
            } else if (/\\u[\dA-Fa-f]{4}/.test(inputStringToDecode.value)) {
                selectedType.value = 'unicode';
            }
        }

        function decodeString() {
            if (selectedType.value === 'html') {
                decodedResult.value = inputStringToDecode.value.replace(/&#(\d+);/g, (_, code) => String.fromCharCode(code));
            } else if (selectedType.value === 'unicode') {
                decodedResult.value = inputStringToDecode.value.replace(/\\u([\dA-Fa-f]{4})/g, (_, code) => String.fromCharCode(parseInt(code, 16)));
            }
        }

        // Watchers для автоматического кодирования и декодирования
        watch([inputStringToEncode, selectedType, activeTab], () => {
            if (activeTab.value === 'encode' && selectedType.value) {
                encodeString();
            }
        });

        watch([inputStringToDecode, activeTab], () => {
            if (activeTab.value === 'decode') {
                autoDetectDecodeType(); // Автоопределение типа
                decodeString();
            }
        });

        // Копирование текста в буфер обмена
        function copyToClipboard(text, label) {
            navigator.clipboard.writeText(text).then(() => {
                notification.success({
                    content: `${label} copied successfully!`,
                    duration: 2000,
                });
            }).catch(() => {
                notification.error({
                    content: `Failed to copy ${label}.`,
                    duration: 2000,
                });
            });
        }

        return {
            inputStringToEncode,
            encodedResult,
            inputStringToDecode,
            decodedResult,
            activeTab,
            selectedType,
            encodeOptions,
            toolData,
            copyToClipboard
        };
    },
};
</script>

<style scoped></style>
