<template>
    <div class="not-found-container">
      <n-result
        status="404"
        title="404 Not Found"
        description="You know life is always ridiculous."
      >
        <template #footer>
          <n-button @click="goHome">Go to home page</n-button>
        </template>
      </n-result>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default defineComponent({
    name: 'NotFound',
    setup() {
      const router = useRouter();
  
      const goHome = () => {
        router.push('/');
      };
  
      return {
        goHome,
      };
    },
  });
  </script>
  
  <style scoped>
  .not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Задает высоту контейнера в 100% высоты экрана */
    text-align: center; /* Выравнивает текст по центру */
  }
  </style>
  