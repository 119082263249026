<template>
    <n-config-provider>
        <div class="tool-container">

            <ToolHeader :toolData="toolData" />

            <n-card>
                <n-tabs v-model:value="activeTab" size="large" type="line" justify-content="space-evenly">
                    <!-- Вкладка Base64 to File -->
                    <n-tab-pane name="base64ToFile" :tab="t('base64_file_converter_tab_base64_to_file')">
                        <n-space vertical size="large">

                            <div class="input-container">
                                <!-- Инпут для ввода строки даты -->
                                <n-input class="filename-input" v-model:value="fileName"
                                    :placeholder="t('base64_file_converter_input_file_placeholder')" />

                                <!-- Селектор справа от инпута -->
                                <n-input class="extantion-input" v-model:value="fileExtension"
                                    :placeholder="t('base64_file_converter_file_extension_placeholder')" />
                            </div>

                            <n-input v-model:value="base64StringToFile"
                                :placeholder="t('base64_file_converter_textarea_placeholder')" type="textarea"
                                :autosize="{ minRows: 5, maxRows: 10 }" @input="handleBase64Input" />

                            <n-space justify="center">
                                <n-button @click="toggleImagePreview" :disabled="!isImage">
                                    {{ showImagePreview ? $t('base64_file_converter_hide_image_button') :
                                        $t('base64_file_converter_preview_image_button') }}
                                </n-button>
                                <n-button @click="downloadFile">{{ $t('base64_file_converter_download_file_button')
                                    }}</n-button>
                            </n-space>

                        </n-space>
                        <n-collapse-transition :show="showImagePreview">
                            <div class="preview-container">
                                <img :src="imagePreview" @click="isImageModalOpen = true" class="clickable-image"
                                    alt="Preview Image" />
                                <n-flex class="html-code-preview-container" gap="2" vertical>
                                    <label>HTML-code</label>
                                    <n-input v-model:value="imageHtmlCode" readonly type="text" size="large"
                                        class="html-input"
                                        placeholder="<img src='data:image/png;base64,...' alt='Image' />"
                                        :autosize="{ minRows: 3, maxRows: 5 }">
                                        <template #suffix>
                                            <n-button text @click="copyToClipboard(imageHtmlCode)" class="copy-button">
                                                <n-icon size="18">
                                                    <Copy />
                                                </n-icon>
                                            </n-button>
                                        </template>
                                    </n-input>
                                </n-flex>
                            </div>
                        </n-collapse-transition>

                        <n-modal v-model:show="isImageModalOpen" class="image-modal" :mask-closable="true"
                            :close-on-esc="true">
                            <img :src="imagePreview" @click="isImageModalOpen = false" alt="Enlarged Image"
                                class="enlarged-image" />
                        </n-modal>
                    </n-tab-pane>

                    <!-- Вкладка File to Base64 -->
                    <n-tab-pane name="fileToBase64" :tab="t('base64_file_converter_tab_file_to_base64')">
                        <n-space vertical>
                            <n-upload list-type="image" accept="*" @change="handleFileChange" @remove="handleFileRemove"
                                :file-list="fileList" drag>
                                <n-upload-dragger>
                                    <div v-if="isLoading" class="loading-spinner">
                                        <n-spin size="large" />
                                        <span style="font-size: 14px; opacity: 0.7; display: block;">{{
                                            $t('base64_file_converter_processing_file_label') }}</span>
                                    </div>
                                    <div v-else>
                                        <n-text style="font-size: 14px; opacity: 0.7;">
                                            {{ $t('base64_file_converter_drag_and_drop_area_label') }}
                                        </n-text>
                                        <n-divider title-placement="center" class="divider-wrapper">
                                            <span style="font-size: 14px; opacity: 0.7; font-weight: 400;">{{
                                                $t('base64_file_converter_divider_text') }}</span>
                                        </n-divider>
                                        <n-button>
                                            <template #icon>
                                                <n-icon>
                                                    <Folders />
                                                </n-icon>
                                            </template>
                                            {{ $t('base64_file_converter_browse_files_button_text') }}
                                        </n-button>
                                    </div>
                                </n-upload-dragger>
                            </n-upload>

                            <n-input v-model:value="fileBase64String" readonly
                                :placeholder="t('base64_file_converter_filetobase64_result_placeholse')" type="textarea"
                                :autosize="{ minRows: 5, maxRows: 10 }" />

                            <n-space justify="center">
                                <n-button @click="copyToClipboard(fileBase64String)">
                                    {{ $t('base64_file_converter_filetobase64_copy_button') }}
                                </n-button>
                                <n-button @click="clearBase64TabData">
                                    {{ $t('base64_file_converter_filetobase64_clear_button') }}
                                </n-button>
                            </n-space>
                        </n-space>
                    </n-tab-pane>
                </n-tabs>
            </n-card>
        </div>
    </n-config-provider>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import { Folders, Copy } from '@vicons/tabler';
import { useI18n } from 'vue-i18n';
import { saveBase64ConverterData, getBase64ConverterData } from '../../indexedDbService';
import ToolHeader from '@/components/ToolHeader.vue';

export default {
    name: 'Base64FileConverter',
    components: {
        Folders,
        Copy,
        ToolHeader
    },
    setup() {

        const { t } = useI18n(); // Получаем функцию t для локализации

        const fileName = ref('file');
        const fileExtension = ref('');
        const base64StringToFile = ref('');
        const fileBase64String = ref('');
        const imagePreview = ref(null);
        const showImagePreview = ref(false);
        const isImage = ref(false);
        const activeTab = ref('base64ToFile');
        const notification = useNotification();
        const isLoading = ref(false); // Добавляем состояние загрузки
        const fileList = ref([]); // Управляем списком файлов
        let fileReader = null; // Добавляем FileReader в качестве переменной

        const toolData = toolsData.converter.find(tool => tool.path === '/base64-file-converter');

        const imageHtmlCode = ref('');

        const isImageModalOpen = ref(false);

        function handleBase64Input() {
            try {
                const mimeType = base64StringToFile.value.match(/^data:(.*?);base64,/)[1];
                fileExtension.value = mimeType.split('/')[1];

                isImage.value = mimeType.startsWith('image/');
                if (isImage.value) {
                    imagePreview.value = base64StringToFile.value;
                    imageHtmlCode.value = `<img src="${imagePreview.value}" alt="Image" />`; // Генерация HTML-кода
                } else {
                    imagePreview.value = null;
                    imageHtmlCode.value = '';
                }
            } catch {
                notification.error({
                    content: t('base64_file_converter_notification_invalid_base64_string'),
                    duration: 2000
                });
                resetImagePreview();
            }
        }

        function resetImagePreview() {
            fileExtension.value = '';
            imagePreview.value = null;
            imageHtmlCode.value = '';
            isImage.value = false;
        }

        function previewFile() {
            if (isImage.value) {
                showImagePreview.value = true;
            } else {
                notification.error({
                    content: 'This is not a valid image file.',
                    duration: 2000
                });
            }
        }

        function downloadFile() {
            try {
                const link = document.createElement('a');
                const blob = base64ToBlob(base64StringToFile.value);
                link.href = URL.createObjectURL(blob);
                link.download = `${fileName.value || 'file'}.${fileExtension.value || 'txt'}`;
                link.click();
            } catch (error) {
                notification.error({
                    content: t('base64_file_converter_notification_invalid_base64_string'),
                    duration: 2000
                });
            }
        }

        function base64ToBlob(base64) {
            const byteString = atob(base64.split(',')[1]);
            const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }
            return new Blob([arrayBuffer], { type: mimeString });
        }

        async function handleFileChange({ fileList: newFileList }) {
            if (newFileList.length > 0) {
                handleFileRemove();

                if (fileReader) {
                    fileReader.abort(); // Прерываем предыдущий FileReader, если он есть
                }

                isLoading.value = true; // Устанавливаем состояние загрузки в true
                fileReader = new FileReader();

                fileReader.onload = () => {

                    setTimeout(() => {
                        isLoading.value = false; // Сбрасываем состояние загрузки после завершения чтения
                        fileBase64String.value = fileReader.result;
                        notification.success({
                            content: t('base64_file_converter_notification_file_loaded_successfully'),
                            duration: 2000
                        });

                    }, 500);
                };

                fileReader.onerror = () => {
                    isLoading.value = false;
                    notification.error({
                        content: t('base64_file_converter_notification_error_reading_file'),
                        duration: 2000
                    });
                };

                fileReader.readAsDataURL(newFileList[0].file);

            }
        }

        function handleFileRemove() {
            fileBase64String.value = ''; // Очищаем Base64 строку
            isLoading.value = false; // Сбрасываем состояние загрузки
            if (fileReader) {
                fileList.value = [];
                fileReader.abort(); // Прерываем FileReader
                fileReader = null; // Очищаем FileReader
            }
        }

        function copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                notification.success({
                    content: t('base64_file_converter_notification_copied_to_clipboard'),
                    duration: 2000
                });
            }).catch(() => {
                notification.error({
                    content: 'Failed to copy!',
                    duration: 2000
                });
            });
        }

        function toggleImagePreview() {
            showImagePreview.value = !showImagePreview.value;
        }

        function clearBase64TabData() {
            fileBase64String.value = '';
        }

        // Функция для сохранения данных в IDB
        async function saveDataToIDB() {
            const data = {
                fileExtension: fileExtension.value,
                base64StringToFile: base64StringToFile.value,
                fileBase64String: fileBase64String.value,
                activeTab: activeTab.value,
            };
            await saveBase64ConverterData(data);
        }

        // Восстанавливаем данные при монтировании компонента
        onMounted(async () => {
            const data = await getBase64ConverterData();
            fileExtension.value = data.fileExtension;
            base64StringToFile.value = data.base64StringToFile;
            fileBase64String.value = data.fileBase64String;
            activeTab.value = data.activeTab; // Восстанавливаем активную вкладку

            if (base64StringToFile.value) {
                handleBase64Input();
            }
        });

        // Следим за изменениями и сохраняем данные
        watch(
            [fileExtension, base64StringToFile, fileBase64String, activeTab],
            saveDataToIDB
        );

        return {
            t,
            fileName,
            fileExtension,
            base64StringToFile,
            fileBase64String,
            imagePreview,
            showImagePreview,
            activeTab,
            toolData,
            isImage,
            imageHtmlCode,
            previewFile,
            downloadFile,
            handleFileChange,
            handleFileRemove,
            copyToClipboard,
            handleBase64Input,
            isLoading,
            fileList,
            toggleImagePreview,
            isImageModalOpen,
            clearBase64TabData
        };
    },
};
</script>

<style scoped>
.divider-wrapper {
    margin-top: 18px;
    margin-bottom: 18px;
}

.preview-container img {
    max-width: 100%;
    max-height: 300px;
    margin-top: 20px;
}

.input-container {
    display: flex;
    justify-content: space-between;
}

.filename-input {
    flex: 1;
    min-width: 200px;
}

.extantion-input {
    min-width: 120px;
    max-width: 200px;
    margin-left: 10px;
}

.html-code-preview-container {
    margin-top: 20px;
}

.clickable-image {
    cursor: zoom-in;
    transition: transform 0.2s ease;
}
</style>
