<template>
    <n-config-provider>
        <div class="tool-container">

            <ToolHeader :toolData="tool" />

            <n-card>
                <n-space vertical size="large">
                    <n-input type="textarea" v-model:value="inputText" rows="5" :autosize="{ minRows: 3, maxRows: 15 }"
                        placeholder="Enter your string..." @input="obfuscateText" />

                    <n-space class="inputs-row">
                        <div class="input-group">
                            <label>Keep first</label>
                            <n-input-number v-model:value="keepFirst" :min="0" :max="inputText.length - keepLast"
                                @update:value="obfuscateText" />
                        </div>

                        <div class="input-group">
                            <label>Keep last</label>
                            <n-input-number v-model:value="keepLast" :min="0" :max="inputText.length - keepFirst"
                                @update:value="obfuscateText" />
                        </div>

                        <div class="switch-group">
                            <label>Keep spaces</label>
                            <n-switch style="margin-top:10px" v-model:value="keepSpaces" @change="obfuscateText"
                                :round="false" />
                        </div>
                    </n-space>

                    <n-input type="textarea" :value="obfuscatedText" readonly rows="3"
                        :autosize="{ minRows: 3, maxRows: 5 }" />

                    <n-space justify="center">
                        <n-button @click="copyText">{{ $t('copy_button') }}</n-button>
                    </n-space>
                </n-space>
            </n-card>
        </div>
    </n-config-provider>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { toolsData } from '@/toolsData';
import { useNotification } from 'naive-ui';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'StringObfuscator',
    components: { ToolHeader },
    setup() {
        const tool = toolsData.text.find(t => t.path === '/string-obfuscator'); // Получаем данные из toolsData
        const inputText = ref('Lorem ipsum dolor sit amet');
        const keepFirst = ref(4);
        const keepLast = ref(4);
        const keepSpaces = ref(true);
        const obfuscatedText = ref('');

        const notification = useNotification();

        const obfuscateText = () => {
            const text = inputText.value;
            const textLength = text.length;

            if (keepFirst.value + keepLast.value > textLength) {
                obfuscatedText.value = text;
                return;
            }

            const visibleFirst = text.slice(0, keepFirst.value);
            const visibleLast = text.slice(-keepLast.value);
            let middlePart = '*'.repeat(textLength - keepFirst.value - keepLast.value);

            if (keepSpaces.value) {
                middlePart = text
                    .slice(keepFirst.value, -keepLast.value)
                    .replace(/\S/g, '*');
            }

            obfuscatedText.value = visibleFirst + middlePart + visibleLast;
        };

        const copyText = () => {
            navigator.clipboard.writeText(obfuscatedText.value);
            notification.success({
                content: 'Obfuscated string copied successfully!',
                duration: 2000,
            });
        };

        onMounted(() => {
            obfuscateText();
        });

        return {
            tool,
            inputText,
            keepFirst,
            keepLast,
            keepSpaces,
            obfuscatedText,
            obfuscateText,
            copyText,
        };
    },
});
</script>

<style scoped>
.stat-label {
    display: block;
    font-size: 14px;
    opacity: 0.6;
}

.stat-value {
    display: block;
    font-size: 20px;
}

.inputs-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-group,
.switch-group {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.switch-group {
    display: flex;
    align-items: self-start;
}

.switch-group label {
    margin-right: 8px;
}
</style>