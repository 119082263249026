<template>

  <n-config-provider>
    <div :class="['json-container', 'json-formatter', { 'full-screen': isFullScreen }]">

      <!-- Анимационная зона для перетаскивания -->
      <!-- <div v-if="isDragging" class="drop-zone">
        <p>Перетащите сюда JSON файл</p>
      </div> -->

      <JsonFormatterHeader v-if="!isFullScreen" :toolData="toolData" />

      <!-- Карточка с вкладками -->
      <n-card :class="['json-formatter', { 'full-screen': isFullScreen, 'dragging-card': isDragging }]" size="small"
        @dragover.prevent @dragenter="handleDragEnter" @dragleave="handleDragLeave" @drop="handleDrop">

        <div :class="['drop-overlay', { visible: isDragging }]">
          <n-icon class="drop-icon" size="60" color="var(--n-primary-color)">
            <Files />
          </n-icon>
          <div class="drop-overlay-content">
            <p>{{ $t('json_formatter_drag_import_title') }}</p>
            <small>{{ $t('json_formatter_drag_import_desc') }}</small>
          </div>
        </div>

        <!-- Вкладки вместо заголовка -->
        <template #header>
          <n-scrollbar x-scrollable>
            <div class="custom-tabs">
              <VueDraggable v-model="tabs" animation="150" ghostClass="dragging-card" @start="isTabsDragging = true"
                @end="onTabsDragEnd" class="tabs-list">
                <div v-for="tab in tabs" :key="tab.name" class="tab-item"
                  :style="tab.name === activeTab ? activeTabStyle : {}" :class="{ active: tab.name === activeTab }"
                  @click="setActiveTab(tab)" @dblclick="editTab(tab)"
                  @contextmenu.prevent="showTabContextMenu($event, tab)">

                  <div class="tab-title">
                    <n-input v-if="tab.editing" v-model:value="tab.title" :autofocus="true"
                      @blur="finishEditingTab(tab)" @keydown.enter="finishEditingTab(tab)"
                      :placeholder="$t('json_formatter_tab_name_input_placeholder')" />
                    <span v-else>{{ tab.title }}</span>
                  </div>
                  <n-button text>
                    <n-icon class="close-icon" @click.stop="openCloseTabModal(tab)">
                      <X />
                    </n-icon>
                  </n-button>
                </div>
              </VueDraggable>

              <!-- Кнопка для добавления новой вкладки -->
              <div class="tab-add" @click="addTab">
                <n-icon>
                  <Plus />
                </n-icon>
              </div>
            </div>
          </n-scrollbar>
          <!-- dropdown контексного меню таба(правая кнопка мыши)  -->
          <n-dropdown v-model:show="tabContextMenu.show" :on-clickoutside="closeTabContextMenu"
            :options="tabContextMenu.options" :x="tabContextMenu.x" :y="tabContextMenu.y"
            @select="handleTabOptionSelect" trigger="manual" placement="bottom-start" />
        </template>

        <template #header-extra>

          <div class="hide-when-mobile">
            <n-space style="margin-left: 15px;margin-right: 5px">
              <!-- Кнопка для первой модалки -->
              <n-tooltip placement="bottom" trigger="hover">
                <template #trigger>
                  <n-button text @click="openDownloadModal">
                    <template #icon>
                      <n-icon>
                        <FileDownload />
                      </n-icon>
                    </template>
                  </n-button>
                </template>
                <span>{{ $t('json_formatter_download_JSON_button') }} JSON</span>
              </n-tooltip>

              <!-- Кнопка Full screen -->
              <n-tooltip placement="bottom" trigger="hover">
                <template #trigger>
                  <n-button text @click="toggleFullScreen">
                    <template #icon>
                      <n-icon>
                        <ArrowsDiagonalMinimize2 v-if="isFullScreen" />
                        <ArrowsDiagonal v-else />
                      </n-icon>
                    </template>
                  </n-button>
                </template>
                <span v-if="isFullScreen">{{ $t('json_formatter_minimize_view_button') }}</span>
                <span v-else>{{ $t('json_formatter_maximize_view_button') }}</span>
              </n-tooltip>

              <!-- Кнопка для второй модалки (Settings) -->
              <n-tooltip placement="bottom" trigger="hover">
                <template #trigger>
                  <n-button text @click="showSettingsModal = true">
                    <template #icon>
                      <n-icon>
                        <Settings />
                      </n-icon>
                    </template>
                  </n-button>
                </template>
                <span>{{ $t('json_formatter_settings_button') }}</span>
              </n-tooltip>
            </n-space>
          </div>

          <div class="hide-when-desktop">
            <n-space style="margin-left: 15px;margin-right: 5px">
              <n-dropdown :options="settingsOptions" @select="handleOptionSelect">
                <n-button text>
                  <template #icon>
                    <n-icon>
                      <Settings />
                    </n-icon>
                  </template>
                </n-button>
              </n-dropdown>
            </n-space>
          </div>

          <!-- Модалка подтверждения закрытия вкладки -->
          <n-modal v-model:show="showCloseTabModal" style="width: 350px;">
            <n-card>
              <n-space vertical size="large">
                <p style="margin-top: 0; margin-bottom: 1px; opacity: 0.7;">
                  {{ $t('json_formatter_modal_confirm_close_tab_content') + ' «' + tabToClose.title + '»?' }}
                </p>
              </n-space>

              <n-space style="margin-top: 10px" justify="end">
                <n-button tertiary size="small" @click="showCloseTabModal = false">
                  {{ $t('no') }}
                </n-button>
                <n-button tertiary type="primary" size="small"
                  @click="() => { closeTab(tabToClose.name); showCloseTabModal = false; }">
                  {{ $t('yes') }}
                </n-button>
              </n-space>
            </n-card>
          </n-modal>

          <!-- Модалка настроек -->
          <n-modal v-model:show="showSettingsModal" preset="dialog" :title="$t('json_formatter_modal_settings_title')"
            :positive-text="$t('json_formatter_modal_settings_positive_button')"
            :negative-text="$t('json_formatter_modal_settings_negative_button')" @positive-click="saveSettings">
            <n-space vertical>
              <div class="converted-row">
                <label class="converted-label">{{ $t('json_formatter_modal_settings_editor_theme_label') }}</label>
                <n-select class="converted-input" v-model:value="selectedTheme" :options="themeOptions"
                  placeholder="Select Theme" />
              </div>

              <n-collapse-transition :show="showDefaultThemeOptions">
                <n-space vertical>
                  <div class="converted-row">
                    <label class="converted-label">
                      {{ $t('json_formatter_modal_settings_editor_theme_label_default_dark') }}
                    </label>
                    <n-select class="converted-input" v-model:value="defaultDarkTheme" :options="darkThemeOptions"
                      placeholder="Select Dark Theme" />
                  </div>

                  <div class="converted-row">
                    <label class="converted-label">
                      {{ $t('json_formatter_modal_settings_editor_theme_label_default_light') }}
                    </label>
                    <n-select class="converted-input" v-model:value="defaultLightTheme" :options="lightThemeOptions"
                      placeholder="Select Light Theme" />
                  </div>
                </n-space>
              </n-collapse-transition>
            </n-space>
          </n-modal>

        </template>

        <!-- Модалка для загрузки JSON -->
        <n-modal v-model:show="showModal" style="width: 550px;">
          <n-card>
            <n-space vertical size="large">
              <p style="margin-top: 0; margin-bottom: 1px;opacity: 0.7;">
                {{ $t('json_formatter_modal_download_import_label') }}
              </p>
              <n-upload :show-file-list="false" accept="*" :on-change="handleDropForImportModal" drag>
                <n-upload-dragger>
                  <div v-if="isLoadingImportJson" class="loading-spinner">
                    <n-spin size="large" />
                    <span style="font-size: 14px; opacity: 0.7; display: block;">
                      {{ $t('json_formatter_modal_download_import_processing_file') }}
                    </span>
                  </div>
                  <div v-else style="padding-top: 15px;padding-bottom: 15px">
                    <n-text style="font-size: 14px; opacity: 0.7;">
                      {{ $t('json_formatter_modal_download_import_area_label') }}
                    </n-text>
                  </div>
                </n-upload-dragger>
              </n-upload>

              <n-divider title-placement="center" class="divider-wrapper">
                <span style="font-size: 14px; opacity: 0.7; font-weight: 400;">
                  {{ $t('json_formatter_modal_download_modal_divider_text') }}
                </span>
              </n-divider>

              <!-- Текущий таб -->
              <div class="input-container">
                <n-input-group>
                  <n-input v-model:value="currentTab.title" class="filename-input"
                    :placeholder="$t('json_formatter_modal_download_input_filename_placeholder')" />
                  <n-input-group-label>.json</n-input-group-label>
                </n-input-group>
                <n-button class="download-input" tertiary type="primary" @click="downloadTab(currentTab)">
                  {{ $t('json_formatter_modal_download_positive_button') }}
                </n-button>
              </div>

              <!-- Другие табы -->
              <n-space vertical v-if="otherTabs.length">
                <n-list>
                  <p style="margin-bottom: 1px;opacity: 0.7;">
                    {{ $t('json_formatter_modal_download_modal_other_tabs_label') }}</p>
                  <n-scrollbar style="max-height: 140px" trigger="none">
                    <n-list-item v-for="tab in otherTabs" :key="tab.name">
                      <div class="input-container">
                        <n-input-group>
                          <n-input v-model:value="tab.title" class="filename-input"
                            :placeholder="$t('json_formatter_modal_download_input_filename_placeholder')" />
                          <n-input-group-label>.json</n-input-group-label>
                        </n-input-group>
                        <n-button class="download-input" tertiary type="primary" @click="downloadTab(tab)">{{
                          $t('json_formatter_modal_download_positive_button') }}</n-button>
                      </div>
                    </n-list-item>
                  </n-scrollbar>
                </n-list>
              </n-space>
            </n-space>

            <n-space style="margin-top: 10px" justify="end">
              <n-button size="small" @click="showModal = false"> {{ $t('json_formatter_modal_download_negative_button')
                }}</n-button>
            </n-space>

          </n-card>
        </n-modal>

        <n-space size="large" vertical>
          <n-alert v-if="errorMessage" type="error" class="custom-alert">
            <n-space align="center">
              <span class="json-error-alert">{{ errorMessage }}</span>
              <n-button size="small" strong secondary type="error" @click="scrollToErrorPosition">
                {{ $t('json_formatter_scroll_to_error_button') }}
              </n-button>
            </n-space>
          </n-alert>
          <div v-if="isEditorLoading" class="loading-container">
            <n-spin size="large" />
            <p>{{ $t('json_formatter_loading_editor_message') }}</p>
          </div>

          <div class="editor-container">
            <!-- Редактор для активной вкладки -->
            <codemirror v-if="currentTab && currentTab.json !== undefined" ref="cm" v-model="currentTab.json"
              :extensions="extensions" :placeholder="getPlaceholder()" :basic="true" @update="onEditorUpdate"
              :class="[{ 'full-screen': isFullScreen }, { 'error-alert': errorMessage }, 'json-editor']" />
            <div class="float-button-group">
              <n-float-button class="settings-float-button" :right="20" :bottom="20" menu-trigger="click">
                <n-icon>
                  <Braces />
                </n-icon>
                <template #menu>
                  <!-- <n-float-button-group class="settings-float-button" :right="20" :bottom="20"> -->
                  <n-tooltip trigger="hover" placement="left" :show-arrow="false">
                    <template #trigger>
                      <n-float-button @click="collapseAllObjects">
                        <n-icon size="21">
                          <UnfoldLessFilled />
                        </n-icon>
                      </n-float-button>
                    </template>
                    {{ $t('json_formatter_controls_menu_fold_all') }}
                  </n-tooltip>

                  <n-tooltip trigger="hover" placement="left" :show-arrow="false">
                    <template #trigger>
                      <n-float-button @click="expandAllObjects">
                        <n-icon size="21">
                          <UnfoldMoreFilled />
                        </n-icon>
                      </n-float-button>
                    </template>
                    {{ $t('json_formatter_controls_menu_unfold_all') }}
                  </n-tooltip>

                  <n-tooltip trigger="hover" placement="left" :show-arrow="false">
                    <template #trigger>
                      <n-float-button @click="scrollToTop">
                        <n-icon>
                          <ArrowBarToUp />
                        </n-icon>
                      </n-float-button>
                    </template>
                    {{ $t('json_formatter_controls_menu_scroll_to_top') }}
                  </n-tooltip>

                  <n-tooltip trigger="hover" placement="left" :show-arrow="false">
                    <template #trigger>
                      <n-float-button @click="scrollToBottom">
                        <n-icon>
                          <ArrowBarToDown />
                        </n-icon>
                      </n-float-button>

                    </template>
                    {{ $t('json_formatter_controls_menu_scroll_to_bottom') }}
                  </n-tooltip>
                  <!-- </n-float-button-group> -->
                </template>
              </n-float-button>
            </div>
          </div>
        </n-space>

        <template #footer>
          <n-space>
            <n-button @click="formatJSON(currentTab)">{{ $t('format_button') }}</n-button>
            <n-button @click="minifyJSON(currentTab)">{{ $t('compress_button') }}</n-button>
            <n-button @click="clearJSON(currentTab)">{{ $t('clear_button') }}</n-button>
            <n-button @click="copyJSON(currentTab)">{{ $t('copy_button') }}</n-button>
          </n-space>
        </template>
      </n-card>
    </div>
  </n-config-provider>
</template>

<script>
import { ref, watch, onMounted, nextTick, onUnmounted, h, computed, watchEffect } from 'vue';
import { useNotification, NIcon, useThemeVars } from 'naive-ui';
import Codemirror from 'vue-codemirror6';
import { json } from '@codemirror/lang-json';
import { linter } from '@codemirror/lint';

import { ensureSyntaxTree, syntaxTree, foldEffect, foldable, unfoldAll } from '@codemirror/language';
import { EditorView } from '@codemirror/view';

import { oneDark } from '@codemirror/theme-one-dark';
import { quietlight } from '@uiw/codemirror-theme-quietlight';
import { githubLight, githubDark } from '@uiw/codemirror-theme-github';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { toolsData } from '@/toolsData';
import { ArrowBarToDown, ArrowBarToUp, Braces, Edit, Trash, Download, FileDownload, Settings, ArrowsDiagonalMinimize2, ArrowsDiagonal, Brush, Plus, X, Files } from '@vicons/tabler';
import { UnfoldLessFilled, UnfoldMoreFilled } from '@vicons/material';
import { useDialog } from 'naive-ui';
import { useI18n } from 'vue-i18n';
import { saveTab, getAllTabs, deleteTab, clearAllTabs, saveAllTabs } from '../../indexedDbService';
import JsonFormatterHeader from '@/components/JsonFormatterHeader.vue';
import { VueDraggable } from 'vue-draggable-plus';

export default {
  components: {
    VueDraggable,
    Plus, X, Files, Braces, ArrowBarToDown, ArrowBarToUp,
    UnfoldLessFilled, UnfoldMoreFilled,
    JsonFormatterHeader,
    Codemirror,
    FileDownload,
    Settings,
    ArrowsDiagonalMinimize2,
    ArrowsDiagonal
  },
  setup() {

    const themeVars = useThemeVars();

    const isTabsDragging = ref(false);

    watchEffect(() => {
      const root = document.documentElement;
      root.style.setProperty('--n-primary-color', themeVars.value.primaryColor);
      root.style.setProperty('--n-tab-color', themeVars.value.tabColor);
    });

    const activeTabStyle = computed(() => ({
      // color: themeVars.value.primaryColor
    }));

    const { t } = useI18n(); // Получаем функцию t для локализации

    const errorMessage = ref(''); // Хранит сообщение об ошибке

    const errorPosition = ref(null);
    let editorView = null;
    const cm = ref(null);

    // Функция для проверки валидности JSON
    const jsonLinter = (view) => {
      let diagnostics = [];
      const doc = view.state.doc.toString();

      if (doc) {
        try {
          JSON.parse(doc);
          errorMessage.value = '';
          errorPosition.value = null; // Очищаем позицию ошибки
        } catch (error) {
          const errorPositionMatch = error.message.match(/at position (\d+)/);
          let pos = 0;

          if (errorPositionMatch) {
            pos = parseInt(errorPositionMatch[1], 10);
            errorPosition.value = pos; // Сохраняем позицию ошибки
          } else {
            errorPosition.value = null;
          }

          errorMessage.value = `Invalid JSON: ${error.message}`;

          diagnostics.push({
            from: pos - 5 > 0 ? pos - 5 : 0,
            to: pos + 5 < doc.length ? pos + 5 : doc.length,
            severity: 'error',
            message: 'Invalid JSON: ' + error.message,
          });
        }
      } else {
        errorMessage.value = '';
        errorPosition.value = null;
      }

      return diagnostics;
    };

    const scrollToErrorPosition = () => {
      if (errorPosition.value !== null && editorView) {

        editorView.focus();
        editorView.dispatch({
          effects: EditorView.scrollIntoView(errorPosition.value, {
            y: 'center',
            x: 'nearest',
          }),
        });
        editorView.focus();
      } else {
        console.warn('Cannot scroll: errorPosition or editorView is null');
      }
    }

    // Функция для получения editorView
    const onEditorUpdate = ({ view }) => {
      if (view) {
        editorView = view;
      }
    };

    function renderIcon(icon) {
      return () => {
        return h(NIcon, null, {
          default: () => h(icon)
        });
      };
    }


    const isEditorLoading = ref(false);

    const tabContextMenu = ref({
      show: false,
      x: 0,
      y: 0,
      options: [],
      tab: null,
    });

    const closeTabContextMenu = () => {
      tabContextMenu.value.show = false;
    };

    const getTabOptions = (tab) => {
      return [
        {
          label: t('json_formatter_tab_contextmenu_rename_tab'),
          key: 'rename',
          icon: renderIcon(Edit),
          tabName: tab.name,
        },
        {
          type: 'divider',
          key: 'd1'
        },
        {
          label: t('json_formatter_tab_contextmenu_close_tab'),
          key: 'close',
          icon: renderIcon(Trash),
          tabName: tab.name,
          disabled: tabs.value.length === 1, // Не позволяем закрыть последнюю вкладку
        },
        {
          label: t('json_formatter_confirm_button_close_all_tabs'),
          key: 'closeall',
          icon: renderIcon(X),
          tabName: tab.name,
          disabled: tabs.value.length === 1, // Не позволяем закрыть последнюю вкладку
        },
        {
          type: 'divider',
          key: 'd1'
        },
        {
          label: t('json_formatter_tab_contextmenu_download_tab'),
          key: 'download',
          icon: renderIcon(Download),
          tabName: tab.name,
        },
      ];
    };

    const showTabContextMenu = (event, tab) => {
      event.preventDefault();

      tabContextMenu.value.tab = tab;
      tabContextMenu.value.options = getTabOptions(tab);
      tabContextMenu.value.x = event.clientX;
      tabContextMenu.value.y = event.clientY;
      tabContextMenu.value.show = true;
    };

    const handleTabOptionSelect = (key) => {
      const tab = tabContextMenu.value.tab;

      if (!tab) return;

      switch (key) {
        case 'rename':
          editTab(tab);
          break;
        case 'close':
          openCloseTabModal(tab);
          break;
        case 'closeall':
          confirmCloseAllTabs(tab);
          break;
        case 'download':
          downloadTab(tab);
          break;
        default:
          console.warn('Unknown tab option:', key);
      }

      // Скрываем контекстное меню после выбора
      tabContextMenu.value.show = false;
    };


    const isFullScreen = ref(localStorage.getItem('jsonFullScreen') === 'true');

    const toggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value;
      localStorage.setItem('jsonFullScreen', isFullScreen.value);
    };

    const settingsOptions = computed(() => [
      {
        label: isFullScreen.value ? t('json_formatter_minimize_view_button') : t('json_formatter_maximize_view_button'),
        key: "changeview",
        icon: renderIcon(isFullScreen.value ? ArrowsDiagonalMinimize2 : ArrowsDiagonal)
      },
      {
        label: t('json_formatter_settings_button'),
        key: "settings",
        icon: renderIcon(Brush)
      },
      {
        label: t('json_formatter_download_JSON_button'),
        key: "downloadJson",
        icon: renderIcon(FileDownload)
      }
    ]);

    const handleOptionSelect = (key) => {
      switch (key) {
        case "changeview":
          toggleFullScreen();
          break;
        case "settings":
          showSettingsModal.value = true;
          break;
        case "downloadJson":
          openDownloadModal();
          break;
        default:
          console.warn("Unknown action:", key);
      }
    };

    const toolData = toolsData.development.find(tool => tool.path === '/json-formatter');
    const showModal = ref(false);
    const showCloseTabModal = ref(false);
    const showSettingsModal = ref(false);
    const fileName = ref('');
    const notification = useNotification();

    const isDragging = ref(false); // Отслеживание перетаскивания

    const dialog = useDialog();

    const setActiveTab = (tab) => {
      activeTab.value = tab.name;
    }

    const tabToClose = ref(null); // Сохраняем вкладку, которую нужно закрыть
    const openCloseTabModal = (tab) => {

      if (tabs.value.length === 1) {
        notification.error({
          content: t('json_formatter_modal_confirm_close_last_tab_notification') + '!',
          duration: 2000
        });
        return;
      }

      if (tab.json) {
        tabToClose.value = tab;
        showCloseTabModal.value = true;
      } else {
        closeTab(tab.name);
      }
    };

    // Подтверждение закрытия всех вкладок
    const confirmCloseAllTabs = (currentTab) => {
      dialog.create({
        title: t('json_formatter_modal_confirm_close_all_tabs_title'),
        content: t('json_formatter_modal_confirm_close_all_tabs_content') + '?',
        positiveText: t('yes'),
        negativeText: t('no'),
        onPositiveClick: () => {
          closeAllTabs(currentTab);
        }
      });
    };

    // Закрытие всех вкладок
    const closeAllTabs = async (keepTab) => {
      // Сохраняем только указанную вкладку
      tabs.value = tabs.value.filter((tab) => tab.name === keepTab.name);

      // Устанавливаем активной оставшуюся вкладку
      activeTab.value = keepTab.name;
      currentTab.value = keepTab;

      // Очищаем остальные вкладки из IndexedDB
      await clearAllTabs();

      // Сохраняем оставшуюся вкладку
      await saveTab(keepTab);
    };

    // Чтение данных из IndexedDB
    const tabs = ref([]);
    const activeTab = ref('');
    const currentTab = ref(null);

    const getPlaceholder = () => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const shortcutKey = isMac ? 'Cmd' : 'Ctrl';

      if (tabs.value.length > 1) {
        return t('json_formatter_editor_placeholder') + '.\n\n' + t('json_formatter_editor_placeholder2') + ` ${shortcutKey} + ← / → `;
      }
      return t('json_formatter_editor_placeholder');
    };

    // Функция для обработки нажатий клавиш
    const handleKeyDown = (event) => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'ArrowRight') {
          // Ctrl/Cmd + стрелка вправо
          event.preventDefault();
          switchToNextTab();
        } else if (event.key === 'ArrowLeft') {
          // Ctrl/Cmd + стрелка влево
          event.preventDefault();
          switchToPreviousTab();
        }
      }
    };

    // Функция для переключения на следующую вкладку
    const switchToNextTab = () => {
      const currentIndex = tabs.value.findIndex(tab => tab.name === activeTab.value);
      const nextIndex = (currentIndex + 1) % tabs.value.length;
      activeTab.value = tabs.value[nextIndex].name;
    };

    // Функция для переключения на предыдущую вкладку
    const switchToPreviousTab = () => {
      const currentIndex = tabs.value.findIndex(tab => tab.name === activeTab.value);
      const prevIndex = (currentIndex - 1 + tabs.value.length) % tabs.value.length;
      activeTab.value = tabs.value[prevIndex].name;
    };

    watch(tabs, async (newTabs) => {
      await saveAllTabs(newTabs);
    }, { deep: true });

    watch(activeTab, (newTabName) => {
      isEditorLoading.value = true;
      errorMessage.value = false;
      localStorage.setItem('activeTab', newTabName);
      currentTab.value = tabs.value.find(tab => tab.name === newTabName);
      isEditorLoading.value = false;
    });

    const defaultDarkTheme = ref(localStorage.getItem('codemirror-default-dark-theme') || 'dark');  // По умолчанию — тёмная тема
    const defaultLightTheme = ref(localStorage.getItem('codemirror-default-light-theme') || 'light');  // По умолчанию — светлая тема

    // Функция для безопасного извлечения темы из localStorage
    function getThemeFromStorage(key, defaultTheme) {
      const themeName = localStorage.getItem(key);

      switch (themeName) {
        case 'light':
          return quietlight;
        case 'githubLight':
          return githubLight;
        case 'dark':
          return oneDark;
        case 'dracula':
          return dracula;
        case 'githubDark':
          return githubDark;
        default:
          return defaultTheme; // Возвращаем тему по умолчанию, если ничего не найдено
      }
    }

    const themes = {
      default: {
        label: t('json_formatter_modal_settings_editor_theme_default_option_label'),
        getExtensions: () => {
          const siteTheme = localStorage.getItem('theme');
          const customDefaultLightTheme = getThemeFromStorage('codemirror-default-light-theme', quietlight);
          const customDefaultDarkTheme = getThemeFromStorage('codemirror-default-dark-theme', oneDark);

          return siteTheme === 'dark'
            ? customDefaultDarkTheme
            : customDefaultLightTheme;
        }
      },
      light: {
        label: 'Quiet Light',
        getExtensions: () => quietlight
      },
      dark: {
        label: 'One Dark',
        getExtensions: () => oneDark
      },
      dracula: {
        label: 'Dracula Dark',
        getExtensions: () => dracula
      },
      githubLight: {
        label: 'Github Light',
        getExtensions: () => githubLight
      },
      githubDark: {
        label: 'Github Dark',
        getExtensions: () => githubDark
      }
    };

    const darkThemeOptions = [
      { label: 'One Dark', value: 'dark' },
      { label: 'Dracula', value: 'dracula' },
      { label: 'Github Dark', value: 'githubDark' }
    ];

    const lightThemeOptions = [
      { label: 'Quiet Light', value: 'light' },
      { label: 'Github Light', value: 'githubLight' }
    ];

    // Генерация опций для селектора тем
    const themeOptions = ref(Object.entries(themes).map(([value, { label }]) => ({ value, label })));
    const selectedTheme = ref(localStorage.getItem('codemirror-theme') || 'default');
    const showDefaultThemeOptions = ref(selectedTheme.value === 'default');
    const extensions = ref([]);

    // Следим за изменениями выбранной темы
    watch(selectedTheme, (newTheme) => {
      showDefaultThemeOptions.value = newTheme === 'default';
    });

    const fullParsePlugin = EditorView.updateListener.of((update) => {
      ensureSyntaxTree(update.state, update.state.doc.length, 1e9);
    });

    const disableDropPlugin = EditorView.domEventHandlers({
      drop: (event) => {
        event.preventDefault();
        return true; // предотвратить дальнейшую обработку
      },
      dragover: (event) => {
        event.preventDefault();
        return true;
      }
    });

    // Применение выбранной темы
    const applyTheme = () => {
      extensions.value = [
        json(),
        linter(jsonLinter),
        themes[selectedTheme.value].getExtensions(),
        fullParsePlugin,
        disableDropPlugin
      ];
    };

    // Отслеживаем изменения в теме сайта
    window.addEventListener('theme-change', () => {
      if (selectedTheme.value === 'default') {
        applyTheme(); // Обновляем тему
      }
    });

    applyTheme();

    const formatJSON = (tab) => {
      try {
        tab.json = JSON.stringify(JSON.parse(tab.json), null, 4);
      } catch (error) {
        notification.error({
          content: t('json_formatter_notification_invalid') + ' JSON',
          duration: 2000,
        });
      }
    };

    const minifyJSON = (tab) => {
      try {
        tab.json = JSON.stringify(JSON.parse(tab.json));
      } catch (error) {
        notification.error({
          content: t('json_formatter_notification_invalid') + ' JSON',
          duration: 2000
        });
      }
    };

    const clearJSON = (tab) => {
      tab.json = '';
    };

    const copyJSON = (tab) => {
      try {
        navigator.clipboard.writeText(tab.json);
        notification.success({
          content: 'JSON ' + t('json_formatter_notification_copied_successfully') + '!',
          duration: 2000
        });
      } catch (error) {
        notification.error({
          content: 'Failed to copy JSON',
          duration: 2000
        });
      }
    };

    const getNextTabName = () => {
      const existingNumbers = tabs.value
        .map(tab => parseInt(tab.name.split('-')[1]))
        .filter(number => !isNaN(number));
      const maxNumber = existingNumbers.length ? Math.max(...existingNumbers) : 0;
      return maxNumber;
    };

    let dragCounter = 0;

    // Функция для обработки Drag Enter
    const handleDragEnter = (event) => {

      if (!isTabsDragging.value) {

        event.preventDefault();
        dragCounter++;
        isDragging.value = true;
      }
    };

    // Функция для обработки Drag Leave
    const handleDragLeave = () => {
      dragCounter--; // Уменьшаем счетчик
      if (dragCounter === 0) {
        isDragging.value = false;
      }
    };


    // Функция для обработки Drop событий
    const handleDrop = (event) => {
      if (!isTabsDragging.value) {

        event.preventDefault();
        const files = event.dataTransfer.files;

        if (files.length && files[0].type === 'application/json') {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const jsonContent = JSON.stringify(JSON.parse(reader.result), null, 4);

              // Получаем имя файла
              let fileName = files[0].name || getNextTabName();

              // Если имя файла длиннее 50 символов, обрезаем его
              if (fileName.length > 35) {
                fileName = fileName.substring(0, 35) + '...';
              }

              // Создаем новую вкладку с именем файла
              const newTab = {
                name: `tab-${getNextTabName() + 1}`,
                title: fileName,
                json: jsonContent,
                editing: false
              };

              tabs.value.push(newTab);
              activeTab.value = newTab.name;
              currentTab.value = newTab;

              notification.success({
                content: 'JSON ' + t('json_formatter_load_file_json_notification_success') + '!',
                duration: 2000
              });
              isDragging.value = false;
              dragCounter = 0;
            } catch (error) {

              isDragging.value = false;
              dragCounter = 0;

              notification.error({
                content: t('json_formatter_load_file_json_notification_error'),
                duration: 2000
              });
            }
          };
          reader.readAsText(files[0]);
        } else {

          isDragging.value = false;
          dragCounter = 0;

          notification.error({
            content: t('json_formatter_load_file_json_notification_wrong_file_type'),
            duration: 2000
          });
        }
      }
    };

    const isLoadingImportJson = ref(false);
    const handleDropForImportModal = (fileList) => {
      const file = fileList.file;

      if (file.type === 'application/json') {
        isLoadingImportJson.value = true;
        const reader = new FileReader();
        reader.onload = () => {
          try {
            setTimeout(() => {
              const jsonContent = JSON.stringify(JSON.parse(reader.result), null, 4);

              let fileName = file.name || getNextTabName();
              if (fileName.length > 35) {
                fileName = fileName.substring(0, 35) + '...';
              }

              const newTab = {
                name: `tab-${getNextTabName() + 1}`,
                title: fileName,
                json: jsonContent,
                editing: false
              };

              tabs.value.push(newTab);
              activeTab.value = newTab.name;
              currentTab.value = newTab;

              showModal.value = false;
              isLoadingImportJson.value = false;
              notification.success({
                content: 'JSON ' + t('json_formatter_load_file_json_notification_success') + '!',
                duration: 2000
              });
            }, 500);
          } catch (error) {
            isLoadingImportJson.value = false;
            notification.error({
              content: t('json_formatter_load_file_json_notification_error'),
              duration: 2000
            });
          }
        };
        reader.readAsText(file.file); // Убедитесь, что используете правильный объект файла
      } else {
        isLoadingImportJson.value = false;
        notification.error({
          content: t('json_formatter_load_file_json_notification_wrong_file_type'),
          duration: 2000
        });
      }
    };

    const addTab = async () => {
      const newTabName = `tab-${getNextTabName() + 1}`;
      const newTab = {
        name: newTabName,
        title: '',
        json: '',
        editing: true
      };
      tabs.value.push(newTab);
      activeTab.value = newTab.name;
      currentTab.value = newTab;

      nextTick(() => {
        // Устанавливаем фокус на инпут при начале редактирования
        const input = document.querySelector(`.json-formatter input[autofocus]`);
        if (input) {
          input.focus();
        }
      });

      await saveTab(newTab);
    };

    const editTab = (tab) => {
      tab.editing = true;
      nextTick(() => {
        // Устанавливаем фокус на инпут при начале редактирования
        const input = document.querySelector(`.json-formatter input[autofocus]`);
        if (input) {
          input.focus();
        }
      });
    };

    const finishEditingTab = (tab) => {
      tab.editing = false;
      if (!tab.title.trim()) {
        const newTabName = `tab-${getNextTabName()}`;
        tab.title = newTabName;
      }
    };

    const closeTab = async (name) => {
      if (tabs.value.length === 1) {
        notification.error({
          content: t('json_formatter_modal_confirm_close_last_tab_notification') + '!',
          duration: 2000
        });
        return;
      }

      showCloseTabModal.value = false;

      // Проверяем, является ли закрываемая вкладка активной
      const isActiveTab = activeTab.value === name;

      // Удаляем вкладку из списка
      tabs.value = tabs.value.filter(tab => tab.name !== name);

      // Если закрываемая вкладка была активной, то устанавливаем первую вкладку как активную
      if (isActiveTab && tabs.value.length) {
        activeTab.value = tabs.value[0].name;
        currentTab.value = tabs.value[0];
      }

      // Удаляем вкладку из IndexedDB
      await deleteTab(name);
    };


    const openDownloadModal = () => {
      fileName.value = currentTab.value.title || `json_${new Date().toLocaleString('en-GB').replace(/[/ ]/g, '_').replace(/[, ]/g, '')}`;
      showModal.value = true;
    };

    const saveSettings = () => {
      localStorage.setItem('codemirror-theme', selectedTheme.value);
      if (selectedTheme.value === 'default') {
        localStorage.setItem('codemirror-default-dark-theme', defaultDarkTheme.value);
        localStorage.setItem('codemirror-default-light-theme', defaultLightTheme.value);
      }
      applyTheme();
      notification.success({
        content: t('json_formatter_modal_settings_success_save_notification') + '!',
        duration: 2000
      });
      showSettingsModal.value = false;
    };

    const otherTabs = computed(() =>
      tabs.value.filter((tab) => tab.name !== currentTab.value.name)
    );

    const downloadTab = (tab) => {
      const blob = new Blob([tab.json], { type: 'application/json' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${tab.title}.json`;
      link.click();
    };


    onMounted(async () => {
      isEditorLoading.value = true;
      window.addEventListener('keydown', handleKeyDown);

      await nextTick();
      // Загружаем вкладки из IndexedDB
      const savedTabs = await getAllTabs();

      if (savedTabs.length) {
        tabs.value = savedTabs;

        const lastActiveTabName = localStorage.getItem('activeTab');

        const tabToActivate = tabs.value.find(tab => tab.name === lastActiveTabName) || savedTabs[0];
        activeTab.value = tabToActivate.name;
        currentTab.value = tabToActivate;
      } else {
        const defaultTab = { name: 'tab-1', title: 'tab-1', json: '', editing: false };
        tabs.value.push(defaultTab);
        activeTab.value = defaultTab.name;
        currentTab.value = defaultTab;
        await saveTab(defaultTab);
      }

      await nextTick();

      // Инициализируем editorView из компонента codemirror
      if (cm.value && cm.value.view) {
        editorView = cm.value.view;
      } else {
        console.warn('EditorView is not available yet');
      }

    });

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown);
    });

    const onTabsDragEnd = () => {
      isTabsDragging.value = false;
    };


    // Сворачивает все объекты в JSON-редакторе
    const collapseAllObjects = () => {
      const view = cm.value?.view;
      if (!view) return;

      const state = view.state;
      const foldRanges = [];

      // Сбор всех фолдабельных диапазонов
      syntaxTree(state).iterate({
        enter(node) {
          if (foldable(state, node.from, node.to)) {
            const range = foldable(state, node.from, node.to);
            if (range) {
              foldRanges.push(range);
            }
          }
        },
      });

      // Выполняем сворачивание всех собранных диапазонов
      if (foldRanges.length > 0) {
        view.dispatch({
          effects: foldRanges.map(range => foldEffect.of(range)),
        });
      }
    };

    // Разворачивает все объекты в JSON-редакторе
    const expandAllObjects = () => {
      const view = cm.value?.view;
      if (view) {
        unfoldAll(view); // Применяем разворачивание ко всему редактору
      }
    };

    // Метод для прокрутки к началу редактора
    const scrollToTop = () => {
      if (cm.value && cm.value.view) {
        cm.value.view.scrollDOM.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    // Метод для прокрутки к концу редактора
    const scrollToBottom = () => {
      if (cm.value && cm.value.view) {
        cm.value.view.scrollDOM.scrollTo({
          top: cm.value.view.scrollDOM.scrollHeight,
          behavior: 'smooth'
        });
      }
    };

    return {
      onTabsDragEnd,
      setActiveTab,
      toolData,
      showModal,
      showSettingsModal,
      fileName,
      tabs,
      activeTab,
      currentTab,
      themeOptions,
      selectedTheme,
      extensions,
      formatJSON,
      minifyJSON,
      clearJSON,
      copyJSON,
      addTab,
      editTab,
      closeTab,
      openDownloadModal,
      saveSettings,
      downloadTab,
      otherTabs,
      handleDropForImportModal,
      isLoadingImportJson,
      finishEditingTab,
      errorMessage,
      getPlaceholder,
      confirmCloseAllTabs,
      toggleFullScreen,
      isFullScreen,
      isDragging,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
      showDefaultThemeOptions,
      darkThemeOptions,
      lightThemeOptions,
      defaultDarkTheme,
      defaultLightTheme,
      settingsOptions,
      handleOptionSelect,
      isEditorLoading,
      tabContextMenu,
      showTabContextMenu,
      handleTabOptionSelect,
      closeTabContextMenu,
      onEditorUpdate,
      scrollToErrorPosition,
      editorView,
      cm,
      activeTabStyle,
      showCloseTabModal,
      openCloseTabModal,
      isTabsDragging,
      tabToClose,
      collapseAllObjects,
      expandAllObjects,
      scrollToTop,
      scrollToBottom,
    };
  }
};
</script>


<style scoped>
.converted-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.converted-label {
  width: 225px;
  text-align: right;
}

.converted-input {
  flex: 1;
  min-width: 230px;
}

/* .dragging-card {
  border: 1.5px dashed var(--n-primary-color);
  background-color: rgba(0, 0, 0, 0.1);
} */

.drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px dashed var(--n-primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 9999;
  pointer-events: none;
  text-align: center;
  opacity: 0;
  transition: opacity 0.4s ease, background-color 0.6s;
}

.drop-overlay.visible {
  opacity: 1;
}

.drop-icon {
  margin-bottom: 15px;
}

.drop-overlay-content p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.drop-overlay-content small {
  font-size: 14px;
  opacity: 0.7;
  margin-top: 5px;
}

.json-header {
  width: 85%;
}

.json-header.full-screen {
  display: none;
}

.json-container {
  width: 100%;
  margin: 0 auto;
  padding-top: 24px;
  transition: all 0.3s ease-in-out;
}

.json-container.full-screen {
  padding-top: 0px;
}

.json-editor {
  flex-grow: 1;
  height: 100%;
  max-height: calc(100vh - 50px) !important;
  width: 100%;
  font-family: monospace;
  font-size: 13px;
  overflow: auto;
}

.json-formatter .n-card-header {
  padding-bottom: 0 !important;
}

.json-formatter {
  max-width: 85%;
  transition: all 0.3s ease-in-out;
}

.json-formatter.full-screen {
  max-width: 100%;
}

.custom-alert {
  border-left: 4px solid #f03e3e;
  background-color: var(--n-card-color);
  color: var(--n-text-color);
  border-radius: 4px;
  font-size: 14px !important;
  font-family: monospace;
}

.custom-alert .n-alert-body {
  padding-left: 10px;
}

.custom-alert .n-alert-title {
  font-weight: normal;
  font-size: 14px;
}

.input-container {
  display: flex;
  justify-content: space-between;
}

.filename-input {
  flex: 1;
  min-width: 180px;
}

.download-input {
  min-width: 100px;
  max-width: 200px;
  margin-left: 10px;
  margin-right: 15px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  height: calc(100vh - 385px);
}

.full-screen .loading-container {
  max-height: calc(100vh - 265px);
  min-height: calc(100vh - 265px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-container p {
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.6;
}

.json-error-alert {
  font-size: 15px;
}

.custom-tabs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 5px;
  position: relative;
}

.custom-tabs::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--n-border-color);
  z-index: 0;
}

.tabs-list {
  display: flex;
  flex-wrap: nowrap;
  z-index: 1;
}

.tab-item {
  display: flex;
  align-items: center;
  padding: 9px 12px;
  margin-right: 4px;
  border: 1px solid var(--n-border-color);
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  transition: background-color 0.6s, color 0.6s;
  background-color: var(--n-tab-color);
  color: var(--n-text-color);
  position: relative;
}

.tab-item.active {
  background-color: var(--n-card-color);
}

.tab-item span {
  font-size: 14px;
  font-weight: 400;
}

.tab-item.active span {
  font-size: 14px;
  font-weight: 400;
  /* color: var(--n-primary-color); */
  transition: color 0.6s;
}

.tab-item.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  transition: background-color 0.6s;
  background-color: var(--n-primary-color);
}

.tab-title {
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.close-icon {
  cursor: pointer;
  margin-left: 5px;
  opacity: 0.6;
  transition: opacity 0.3s;
}

.close-icon:hover {
  opacity: 1;
}

.tab-add {
  cursor: pointer;
  padding: 11px 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--n-border-color);
  border-radius: 4px;
  border-bottom: none;
  background-color: var(--n-tab-color);
}

.tab-close-all {
  margin-left: 10px;
}

@media (max-width: 1650px) {
  .json-formatter {
    max-width: 95%;
  }

  .json-header {
    width: 95%;
  }
}

@media (max-width: 1450px) {
  .json-error-alert {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .json-formatter {
    max-width: 100%;
  }

  .json-header {
    width: 100%;
  }

  .json-formatter.full-screen {
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .hide-when-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .hide-when-mobile {
    display: none;
  }

  .converted-input {
    flex: 1;
    min-width: 180px;
  }
}

.editor-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.float-button-group {
  transform: translate(0);
}

.settings-float-button {
  position: absolute;
  z-index: 1000;
}
</style>
