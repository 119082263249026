<template>
    <div class="tool-container">

        <ToolHeader :toolData="toolData" />

        <n-card>
            <n-space vertical size="large">
                <div class="input-container">
                    <!-- Ввод данных -->
                    <n-input-number v-model:value="inputSize" class="data-input" :min="0"
                        placeholder="Enter your size to convert" />

                    <!-- Выбор единицы данных -->
                    <n-select v-model:value="inputUnit" class="selector" :options="dataUnits" />
                </div>

                <n-divider style="margin-top: 10px;" />

                <!-- Результаты -->
                <n-space vertical>
                    <div v-for="(value, key) in convertedResults" :key="key" class="converted-row">
                        <label class="converted-label">{{ key }}</label>
                        <n-input v-model:value="convertedResults[key]" @input="convertBack(key)" />
                    </div>
                </n-space>
            </n-space>
        </n-card>
    </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { toolsData } from '@/toolsData';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'DataSizeConverter',
    components: { ToolHeader },
    setup() {
        const toolData = toolsData.measurement.find(tool => tool.path === '/data-size-converter');
        const inputSize = ref(null);
        const inputUnit = ref('bytes');

        const dataUnits = [
            { label: 'Bytes', value: 'bytes' },
            { label: 'Kilobytes', value: 'kilobytes' },
            { label: 'Megabytes', value: 'megabytes' },
            { label: 'Gigabytes', value: 'gigabytes' },
            { label: 'Terabytes', value: 'terabytes' }
        ];

        const conversionFactors = {
            bytes: 1,
            kilobytes: 1024,
            megabytes: 1024 * 1024,
            gigabytes: 1024 * 1024 * 1024,
            terabytes: 1024 * 1024 * 1024 * 1024
        };

        const convertedResults = ref({
            Bytes: 0,
            Kilobytes: 0,
            Megabytes: 0,
            Gigabytes: 0,
            Terabytes: 0
        });

        const calculateResults = () => {
            const baseValue = parseFloat(inputSize.value) * conversionFactors[inputUnit.value];
            const formatResult = value => (Number.isInteger(value) ? value : value.toFixed(6));

            convertedResults.value = {
                Bytes: formatResult(baseValue),
                Kilobytes: formatResult(baseValue / conversionFactors.kilobytes),
                Megabytes: formatResult(baseValue / conversionFactors.megabytes),
                Gigabytes: formatResult(baseValue / conversionFactors.gigabytes),
                Terabytes: formatResult(baseValue / conversionFactors.terabytes)
            };
        };

        watch([inputSize, inputUnit], calculateResults);

        const convertBack = (unit) => {
            const reverseFactor = conversionFactors[unit.toLowerCase()];
            inputSize.value = (parseFloat(convertedResults.value[unit]) * reverseFactor) / conversionFactors[inputUnit.value];
        };

        return {
            inputSize,
            inputUnit,
            dataUnits,
            convertedResults,
            toolData,
            convertBack
        };
    }
});
</script>


<style scoped>
.selector {
    min-width: 120px;
    max-width: 150px;
    margin-left: 10px;
}

.data-input {
    flex: 1;
    min-width: 200px;
}

.input-container {
    display: flex;
    justify-content: space-between;
}

.converted-row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.converted-label {
    width: 100px;
    text-align: right;
}

.n-input {
    flex: 1;
}
</style>
