import { Alarm, Keyboard, EyeOff, ChartBar, Exchange, FileText, Braces, BrowserCheck, Space, ArrowsShuffle, ShieldCheck, Clock, Palette, LetterCase, LockAccess, Fingerprint, CalendarTime, FileCode, Binary, TextWrap } from '@vicons/tabler';

export const toolsData = {
    development: [
        {
            name: 'Json Formatter',
            description: 'json_formatter_description2',
            icon: Braces,
            path: '/json-formatter',
            keywords: [
                'json', 'formatter', 'validate', 'clean', 'reformat', 'data', 'editor',
                'json форматирование', 'форматирование', 'валидатор', 'очистка', 'json редактор', 'редактор',
                'форматування', 'валідатор', 'редактор', 'очистити'
            ]
        },
        // {
        //     name: 'SVG Placeholder Generator',
        //     description: 'svg_placeholder_generator_description',
        //     icon: Photo,
        //     path: '/svg-placeholder-generator',
        //     keywords: [
        //         'svg', 'placeholder', 'generator', 'mockup', 'design', 'template', 'responsive', 'placeholder generator',
        //         'svg заполнитель', 'макет', 'дизайн', 'шаблон', 'адаптивность', 'генератор svg', 'генератор заполнителей',
        //         'генератор макетов', 'макетування', 'шаблон заповнення', 'дизайн', 'svg генератор', 'адаптивний макет'
        //     ]
        // },
        {
            name: 'Slugify string',
            description: 'slugify_string_description',
            icon: Space,
            path: '/slugify-string',
            keywords: [
                'slugify', 'url safe', 'filename', 'id', 'string converter', 'text',
                'преобразовать строку', 'slug', 'url безопасный', 'имя файла', 'идентификатор безопасный',
                'перетворення строки', 'слаг', 'безпечний url', 'назва файлу', 'безпечний ідентифікатор'
            ]
        },
        {
            name: 'User-agent parser',
            description: 'user_agent_parser_description',
            icon: BrowserCheck,
            path: '/user-agent-parser',
            keywords: [
                'user-agent', 'parser', 'browser', 'device', 'os', 'cpu', 'engine', 'ua parser',
                'user-agent парсер', 'анализ user-agent', 'определение', 'браузер', 'устройство', 'парсер UA',
                'парсер user-agent', 'аналіз user-agent', 'визначення', 'браузер', 'пристрій', 'парсер UA'
            ]
        },
        {
            name: 'Keycode Info',
            description: 'keycode_info_description',
            icon: Keyboard,
            path: '/keycode-info',
            keywords: [
                'keycode', 'keyboard', 'keypress', 'key', 'code',
                'клавитура', 'информация', 'клавиша',
                'кравіатура', 'інформація', 'клавіша',
            ]
        }
    ],
    crypto: [
        {
            name: 'Token Generator',
            description: 'token_generator_description',
            icon: ArrowsShuffle,
            path: '/token-generator',
            keywords: [
                'token', 'random string', 'generator', 'chars', 'symbols', 'uppercase', 'lowercase', 'crypto',
                'генератор', 'токен', 'случайный', 'символы', 'верхний регистр', 'нижний регистр',
                'генератор', 'токен', 'випадковий', 'символи', 'верхній регістр', 'нижній регістр'
            ]
        },
        {
            name: 'UUIDs Generator',
            description: 'uuid_generator_description',
            icon: Fingerprint,
            path: '/uuid-generator',
            keywords: [
                'uuid', 'unique identifier', 'generator', 'universally unique', '128-bit', 'random', 'crypto',
                'генератор', 'уникальный', 'идентификатор', '128 бит', 'уид', 'юид', 'случайный',
                'генератор', 'унікальний', 'ідентифікатор', '128 біт', 'уід', 'випадковий'
            ]
        },
        {
            name: 'Hash Text',
            description: 'hash_text_description',
            icon: LockAccess,
            path: '/hash-text',
            keywords: [
                'hash', 'text', 'md5', 'sha1', 'sha256', 'sha512', 'hash generator', 'crypto',
                'хеш', 'строка', 'генератор хешей', 'md5', 'sha1', 'шифрование',
                'хеш', 'рядок', 'генератор хешів', 'md5', 'sha1', 'шифрування'
            ]
        },
        {
            name: 'Password strength analyser',
            description: 'password_strength_analyzer_description',
            icon: ShieldCheck,
            path: '/password-strength-analyser',
            keywords: [
                'password', 'strength', 'analyser', 'security', 'crack time', 'password safety', 'crypto',
                'анализатор паролей', 'безопасность пароля', 'оценка надежности пароля', 'время взлома',
                'аналізатор паролів', 'безпека пароля', 'оцінка надійності пароля', 'час злому'
            ]
        }
    ],
    converter: [
        {
            name: 'Time formats Converter',
            description: 'time_formats_converter_description',
            icon: CalendarTime,
            path: '/date-time-converter',
            keywords: [
                'date', 'time', 'converter', 'formats', 'datetime', 'conversion',
                'конвертер даты и времени', 'дата', 'время', 'форматы', 'перевод времени',
                'конвертер дати і часу', 'дата', 'час', 'формати', 'перетворення часу'
            ]
        },
        {
            name: 'Base64 string Converter',
            description: 'base64_string_converter_description',
            icon: FileCode,
            path: '/base64-string-converter',
            keywords: [
                'base64', 'converter', 'encode', 'decode', 'text conversion',
                'конвертер base64', 'кодировать', 'декодировать', 'текст', 'шифрование',
                'конвертер base64', 'кодувати', 'декодувати', 'текст', 'шифрування'
            ]
        },
        {
            name: 'Base64 file Converter',
            description: 'base64_file_converter_description',
            icon: FileCode,
            path: '/base64-file-converter',
            keywords: [
                'base64', 'converter', 'encode', 'decode', 'text conversion',
                'конвертер base64', 'кодировать', 'декодировать', 'текст', 'шифрование',
                'конвертер base64', 'кодувати', 'декодувати', 'текст', 'шифрування'
            ]
        },
        {
            name: 'Hexadecimal to String',
            description: 'hexadecimal_to_string_description',
            icon: Binary,
            path: '/hexadecimal-converter',
            keywords: [
                'hexadecimal', 'binary', 'string', 'converter', 'hex to text', 'conversion',
                'шестнадцатеричный конвертер', 'бинарный в строку', 'перевод хекс', 'конверсия',
                'шістнадцятковий конвертер', 'бінарний в рядок', 'перетворення хекс', 'конвертація'
            ]
        },
        {
            name: 'Color Converter',
            description: 'color_converter_description',
            icon: Palette,
            path: '/color-converter',
            keywords: [
                'color', 'converter', 'hex', 'rgb', 'hsl', 'css', 'color conversion',
                'конвертер цветов', 'цвет', 'rgb', 'hsl', 'hex', 'css',
                'конвертер кольорів', 'колір', 'rgb', 'hsl', 'hex', 'css'
            ]
        },
        {
            name: 'Case Converter',
            description: 'case_converter_description',
            icon: LetterCase,
            path: '/case-converter',
            keywords: [
                'case', 'converter', 'uppercase', 'lowercase', 'titlecase', 'text transformation',
                'конвертер регистра', 'верхний регистр', 'нижний регистр', 'текст',
                'конвертер регістру', 'верхній регістр', 'нижній регістр', 'текст'
            ]
        },
        {
            name: 'Unicode Converter',
            description: 'unicode_converter_description',
            icon: TextWrap,
            path: '/unicode-converter',
            keywords: [
                'unicode', 'converter', 'text', 'parse', 'utf', 'text conversion',
                'unicode конвертер', 'текст в юникод', 'анализ текста', 'конверсия',
                'конвертер unicode', 'текст в юнікод', 'аналіз тексту', 'конвертація'
            ]
        }
    ],
    text: [
        {
            name: 'Lorem Ipsum Generator',
            description: 'lorem_ipsum_generator_description',
            icon: FileText,
            path: '/lorem-ipsum-generator',
            keywords: [
                'lorem ipsum', 'generator', 'placeholder', 'text', 'dummy text',
                'lorem ipsum генератор', 'текст-заполнитель', 'демонстрация шрифта',
                'генератор lorem ipsum', 'текст-заповнювач', 'демонстрація шрифту'
            ]
        },
        {
            name: 'Text Statistics',
            description: 'text_statistics_description',
            icon: ChartBar,
            path: '/text-statistics',
            keywords: [
                'text', 'statistics', 'characters', 'words', 'size', 'byte count', 'analysis',
                'статистика текста', 'количество символов', 'количество слов', 'размер в байтах', 'анализ',
                'статистика тексту', 'кількість символів', 'кількість слів', 'розмір у байтах', 'аналіз'
            ]
        },
        {
            name: 'String Obfuscator',
            description: 'string_obfuscator_description',
            icon: EyeOff,
            path: '/string-obfuscator',
            keywords: [
                'string', 'obfuscate', 'mask', 'secret', 'iban', 'token', 'security',
                'обфускация строки', 'замаскировать', 'секрет', 'безопасность', 'обфускатор',
                'обфускація рядка', 'маскування', 'секрет', 'безпека', 'обфускатор'
            ]
        }
    ],
    measurement: [
        {
            name: 'Chronometer',
            description: 'chronometer_description',
            icon: Alarm,
            path: '/chronometer',
            keywords: [
                'time', 'calculator', 'duration', 'time conversion', 'time difference',
                'временной калькулятор', 'вычисление времени', 'конвертация времени',
                'часовий калькулятор', 'обчислення часу', 'конвертація часу'
            ]
        },
        {
            name: 'Time Unit Converter',
            description: 'time_unit_converter_description',
            icon: Clock,
            path: '/time-unit-converter',
            keywords: [
                'time', 'calculator', 'duration', 'time conversion', 'time difference',
                'временной калькулятор', 'вычисление времени', 'конвертация времени',
                'часовий калькулятор', 'обчислення часу', 'конвертація часу'
            ]
        },
        {
            name: 'Data Size Converter',
            description: 'data_size_converter_description',
            icon: Exchange,
            path: '/data-size-converter',
            keywords: [
                'bytes', 'calculator', 'data size', 'conversion', 'data units', 'megabytes', 'gigabytes',
                'калькулятор байт', 'размер данных', 'конвертация данных', 'мегабайты', 'гигабайты',
                'калькулятор байтів', 'розмір даних', 'конвертація даних', 'мегабайти', 'гігабайти'
            ]
        }
    ]
};