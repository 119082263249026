<template>
  <n-layout-sider collapse-mode="width" :collapsed-width="0" width="240" v-model:collapsed="localCollapsed"
    class="sider" :native-scrollbar="false">
    <div class="menu-wrapper" ref="sidebar" @click.stop>
      <!-- Логотип и описание для мобильной версии -->
      <div class="logo-container show-mobile">
        <img src="@/assets/logo.svg" alt="Logo" class="logo" />
        <div class="logo-text">
          <span class="logo-title">Toolsy IT</span>
          <span class="logo-subtitle">Useful tools for developers</span>
        </div>
      </div>

      <!-- Элементы "Язык" и "Тема" для мобильной версии -->
      <div class="mobile-settings show-mobile">
        <n-select v-model:value="localLanguage" :options="languages" size="medium" class="language-select" />
      </div>

      <!-- Collapse для групп меню, включая Избранное -->
      <n-collapse :default-expanded-names="expandedGroups">
        <!-- Группа избранного -->
        <n-collapse-item :title="t('sidebar_favorites_group_title')" name="favorites-group"
          v-if="favoriteMenuOptions.length">
          <n-menu :options="favoriteMenuOptions" :value="currentPath" @update:value="navigateTo" />
        </n-collapse-item>

        <!-- Другие группы -->
        <n-collapse-item :title="t('sidebar_development_group_title')" name="development-group">
          <n-menu :options="devMenuOptions" :value="currentPath" @update:value="navigateTo" />
        </n-collapse-item>
        <n-collapse-item :title="t('sidebar_crypto_group_title')" name="crypto-group">
          <n-menu :options="cryptoMenuOptions" :value="currentPath" @update:value="navigateTo" />
        </n-collapse-item>
        <n-collapse-item :title="t('sidebar_converter_group_title')" name="converter-group">
          <n-menu :options="converterMenuOptions" :value="currentPath" @update:value="navigateTo" />
        </n-collapse-item>
        <n-collapse-item :title="t('sidebar_text_group_title')" name="text-group">
          <n-menu :options="textMenuOptions" :value="currentPath" @update:value="navigateTo" />
        </n-collapse-item>
        <n-collapse-item :title="t('sidebar_measurement_group_title')" name="measurement-group">
          <n-menu :options="measurementMenuOptions" :value="currentPath" @update:value="navigateTo" />
        </n-collapse-item>
      </n-collapse>

      <div class="footer">
        <span class="version-info">Toolsy IT - v2024.11.09-bab6b5c</span>
        <p>{{ $t('sidebar_footer_found_bug') }}
          <a href="mailto:support@toolsyit.com" class="support-link">support@toolsyit.com</a>
        </p>
      </div>

    </div>
  </n-layout-sider>

  <!-- Зона для клика вне сайдбара -->
  <div v-if="!localCollapsed && isMobile" class="overlay" @click="handleOverlayClick"></div>
</template>

<script>
import { defineComponent, ref, computed, watch, h, onMounted, onUnmounted, markRaw } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';
import { getFavoritesOrder } from '../../favouritesIDB';

export default defineComponent({
  name: 'AppSidebar',
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    language: String,
    isDark: Boolean,
    toggleTheme: Function,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const localCollapsed = ref(props.collapsed);
    const localLanguage = ref(localStorage.getItem('language') || 'en');
    const localIsDark = ref(props.isDark);
    const favoriteTools = ref([]);
    const languages = ref([
      { label: 'English', value: 'en' },
      { label: 'Українська', value: 'uk' },
    ]);

    const router = useRouter();

    const handleFavoriteToolsUpdate = async (event) => {
      favoriteTools.value = event.detail.favoriteTools;
      await loadIconsForFavorites(); // Загружаем иконки для новых избранных
    };

    // Подписка на событие в onMounted
    onMounted(async () => {
      await loadFavorites();
      window.addEventListener('favorite-tools-update', handleFavoriteToolsUpdate);
    });

    // Удаление подписки в onUnmounted вне onMounted
    onUnmounted(() => {
      window.removeEventListener('favorite-tools-update', handleFavoriteToolsUpdate);
    });

    const loadFavorites = async () => {
      const savedFavorites = await getFavoritesOrder();
      favoriteTools.value = savedFavorites.map(favTool => {
        const matchingTool = toolsData.development.concat(toolsData.crypto, toolsData.converter, toolsData.text, toolsData.measurement).find(tool => tool.name === favTool.name);
        return {
          ...favTool,
          icon: matchingTool ? markRaw(matchingTool.icon) : null,
        };
      });
    };

    const loadIconsForFavorites = () => {
      favoriteTools.value = favoriteTools.value.map(tool => {
        const matchingTool = toolsData.development
          .concat(toolsData.crypto, toolsData.converter, toolsData.text, toolsData.measurement)
          .find(t => t.name === tool.name);
        return {
          ...tool,
          icon: matchingTool ? matchingTool.icon : tool.icon, // Обновляем иконку, если она отсутствует
        };
      });
    };

    watch(() => props.language, (newVal) => {
      localLanguage.value = newVal;
    });

    watch(() => props.isDark, (newVal) => {
      localIsDark.value = newVal;
    });

    watch(() => props.collapsed, (newVal) => {
      localCollapsed.value = newVal;
    });

    watch(localLanguage, (newLang) => {
      localStorage.setItem('language', newLang);
      emit('update:language', newLang);
    });

    watch(localCollapsed, (newVal) => {
      emit('update:collapsed', newVal);
    });

    const route = useRoute();
    const currentPath = computed(() => route.path);

    const navigateTo = (path) => {
      router.push(path);

      const width = window.innerWidth;

      if (width < 769) {
        localCollapsed.value = true;
      }
    };

    const favoriteMenuOptions = computed(() =>
      favoriteTools.value.map((tool) => ({
        label: tool.name,
        key: tool.path,
        icon: () => h(tool.icon),
      }))
    );

    const devMenuOptions = computed(() =>
      toolsData.development.map((tool) => ({
        label: tool.name,
        key: tool.path,
        icon: () => h(tool.icon),
      }))
    );

    const cryptoMenuOptions = computed(() =>
      toolsData.crypto.map((tool) => ({
        label: tool.name,
        key: tool.path,
        icon: () => h(tool.icon),
      }))
    );

    const converterMenuOptions = computed(() =>
      toolsData.converter.map((tool) => ({
        label: tool.name,
        key: tool.path,
        icon: () => h(tool.icon),
      }))
    );

    const textMenuOptions = computed(() =>
      toolsData.text.map((tool) => ({
        label: tool.name,
        key: tool.path,
        icon: () => h(tool.icon),
      }))
    );

    const measurementMenuOptions = computed(() =>
      toolsData.measurement.map((tool) => ({
        label: tool.name,
        key: tool.path,
        icon: () => h(tool.icon),
      }))
    );

    const handleOverlayClick = () => {
      localCollapsed.value = true;
    };

    const expandedGroups = ['favorites-group', 'development-group', 'crypto-group', 'converter-group', 'text-group', 'measurement-group'];

    return {
      t,
      localCollapsed,
      localLanguage,
      localIsDark,
      currentPath,
      navigateTo,
      favoriteMenuOptions,
      devMenuOptions,
      cryptoMenuOptions,
      converterMenuOptions,
      textMenuOptions,
      measurementMenuOptions,
      handleOverlayClick,
      languages,
      expandedGroups,
    };
  },
});
</script>

<style scoped>
.n-icon {
  width: 20px !important;
  height: 20px !important;
}

.sider {
  flex-shrink: 0;
  position: relative;
}

.menu-wrapper {
  padding: 8px;
  padding-bottom: 150px;
}

/* Логотип и описание для мобильной версии */
.logo-container {
  text-align: center;
  padding: 16px 0;
}

.logo {
  width: 60px;
  height: 60px;
}

.logo-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-title {
  font-size: 16px;
  font-weight: bold;
}

.logo-subtitle {
  font-size: 12px;
  color: #a1a1a1;
}

.mobile-settings {
  margin-bottom: 15px;
}

/* Показываем только в мобильной версии */
@media (max-width: 768px) {
  .show-mobile {
    display: block;
  }
}

@media (min-width: 769px) {
  .show-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .sider {
    position: absolute;
    height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

.footer {
  margin-top: auto;
  padding: 16px;
  padding-top: 32px;
  text-align: center;
  font-size: 12px;
  color: #666;
}

.version-info {
  display: block;
}

.support-link {
  color: #007acc;
  text-decoration: none;
}

.support-link:hover {
  text-decoration: underline;
}
</style>
