<template>
  <n-layout-header class="header">
    <div class="header-left">
      <img src="@/assets/logo.svg" alt="Logo" class="logo hide-mobile" @click="navigateHome" />
      <div class="logo-text hide-mobile" @click="navigateHome">
        <span class="logo-title">Toolsy IT</span>
        <span class="logo-subtitle">Useful tools for developers</span>
      </div>

      <n-button circle quaternary size="large" class="menu-button" @click="$emit('toggleSidebar')">
        <n-icon>
          <Menu2 />
        </n-icon>
      </n-button>

      <n-button circle quaternary size="large" class="home-button" @click="navigateHome">
        <n-icon>
          <Home2 />
        </n-icon>
      </n-button>
    </div>

    <n-input :placeholder="$t('search')" size="medium" class="search-input" @click="openSearch" readonly>
      <template #prefix>
        <n-icon>
          <Search />
        </n-icon>
      </template>
      <template #suffix>
        <span class="shortcut">{{ getOSHotkeys() }}</span>
      </template>
    </n-input>

    <!-- Right side with language, theme and login -->
    <div class="header-right">
      <n-select v-model:value="language" :options="languages" size="medium" class="language-select hide-mobile" />
      <n-button circle quaternary size="large" @click="$emit('toggleTheme')" class="">
        <n-icon>
          <Moon v-if="isDark" />
          <Sun v-else />
        </n-icon>
      </n-button>

      <!-- <n-button strong quaternary size="large" @click="handleLogin" class="login-button">
        <span class="hide-mobile hide-2">{{ isLoggedIn ? 'Profile' : 'Login' }}</span>
        <n-icon class="icon-login">
          <UserCircle />
        </n-icon>
      </n-button> -->
    </div>
  </n-layout-header>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Moon, Sun, Menu2, Home2, Search } from '@vicons/tabler'; //UserCircle

export default defineComponent({
  name: 'AppHeader',
  props: {
    isDark: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Menu2,
    Home2,
    Moon,
    Sun,
    Search,
  },
  methods: {
    openSearch() {
      this.$emit('open-search');
    },
  },
  setup() {
    const { locale } = useI18n();
    const language = ref(localStorage.getItem('language') || 'en');
    const languages = ref([
      { label: 'English', value: 'en' },
      { label: 'Українська', value: 'uk' },
    ]);
    const isSearchOpen = ref(false);
    const isLoggedIn = ref(false);
    const router = useRouter();

    const getOSHotkeys = () => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const shortcutKey = isMac ? 'Cmd' : 'Ctrl';

      return ` ${shortcutKey} + K`;
    };

    // Watch for changes in the selected language and update locale
    watch(language, (newLang) => {
      locale.value = newLang;
      localStorage.setItem('language', newLang); // Save language to localStorage
      updateMetaTags(); // Обновляем мета-теги при изменении языка
    });

    const updateMetaTags = () => {
      const route = router.currentRoute.value; // Текущий маршрут
      const description =
        route.meta?.descriptions?.[locale.value] ||
        route.meta?.descriptions?.en ||
        '';

      document.title = route.meta?.title || 'Toolsy IT';
      updateMetaTag('description', description);
      updateMetaTag('og:title', route.meta?.title || 'Toolsy IT', 'property');
      updateMetaTag('og:description', description, 'property');
      updateMetaTag('og:url', window.location.href, 'property');
    };

    const updateMetaTag = (name, content, type = 'name') => {
      let tag = document.querySelector(`meta[${type}="${name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute(type, name);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    };

    const handleLogin = () => {
      if (!isLoggedIn.value) {
        console.log('Open login dialog');
      } else {
        console.log('Go to profile');
      }
    };

    const navigateHome = () => {
      router.push('/');
    };

    return {
      language,
      languages,
      isLoggedIn,
      handleLogin,
      navigateHome,
      isSearchOpen,
      getOSHotkeys,
    };
  },
});

</script>

<style scoped>
.n-icon {
  font-size: 26px;
}

.icon-login {
  margin-left: 8px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  height: 64px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.logo-text {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logo-title {
  font-size: 19px;
  font-weight: bold;
}

.logo-subtitle {
  font-size: 12px;
  color: #a1a1a1;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search-input {
  min-width: 200px;
  margin: 0 16px;
  flex-grow: 1;
}

.language-select {
  min-width: 110px;
}

.login-button {
  display: flex;
  align-items: center;
}

.shortcut {
  margin-left: 8px;
  opacity: 0.5;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }

  .search-input {
    min-width: 100px;
    width: 50%;
  }

  .header {
    padding: 12px;
    height: 56px;
  }

  .header-left {
    gap: 8px;
  }

  .menu-button {
    margin-left: 0;
  }

  /* Выровнять иконку профиля на мобильных устройствах */
  .login-button {
    justify-content: center;
    margin-right: 0;
  }

  .icon-login {
    margin-left: 0px;
  }
}

@media (max-width: 864px) {
  .hide-2 {
    display: none;
  }

  .icon-login {
    margin-left: 0px;
  }
}
</style>
