<template>
    <n-config-provider>
        <div class="tool-container">

            <ToolHeader :toolData="tool" />

            <n-card>
                <n-space vertical size="large">
                    <n-space vertical size="large" class="sliders-container">
                        <div class="converted-row">
                            <label class="converted-label">{{ $t('paragraphs') }}</label>
                            <n-slider v-model:value="paragraphs" :min="1" :max="50" step="1"
                                @update:value="generateText" />
                        </div>

                        <div class="converted-row">
                            <label class="converted-label">{{ $t('sentences_per_paragraph') }}</label>
                            <n-slider v-model:value="sentencesPerParagraphRange" :min="1" :max="50" range step="1"
                                @update:value="generateText" />
                        </div>

                        <div class="converted-row">
                            <label class="converted-label">{{ $t('words_per_sentence') }}</label>
                            <n-slider v-model:value="wordsPerSentenceRange" :min="3" :max="50" range step="1"
                                @update:value="generateText" />
                        </div>
                    </n-space>

                    <n-space vertical>
                        <n-space justify="">
                            <label>{{ $t('start_with') }} «Lorem Ipsum»?</label>
                            <n-switch v-model:value="startWithLorem" @change="generateText" :round="false" />
                        </n-space>
                    </n-space>

                    <!-- Текстовое поле для сгенерированного текста -->
                    <n-input type="textarea" :value="generatedText" readonly rows="3"
                        :autosize="{ minRows: 5, maxRows: 20 }" />

                    <!-- Кнопки -->
                    <n-space justify="center">
                        <n-button @click="copyText">{{ $t('copy_button') }}</n-button>
                        <n-button @click="generateText">{{ $t('refresh_button') }}</n-button>
                    </n-space>
                </n-space>
            </n-card>
        </div>
    </n-config-provider>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { toolsData } from '@/toolsData';
import { useNotification } from 'naive-ui';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'LoremIpsumGenerator',
    components: { ToolHeader },
    setup() {
        const tool = toolsData.text.find(t => t.path === '/lorem-ipsum-generator');

        const paragraphs = ref(1);
        const sentencesPerParagraphRange = ref([3, 8]); // Диапазон предложений в абзаце
        const wordsPerSentenceRange = ref([7, 15]); // Диапазон слов в предложении
        const startWithLorem = ref(true);
        const generatedText = ref('');

        const notification = useNotification();

        const words = [
            'lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur', 'adipiscing', 'elit', 'sed', 'do', 'eiusmod',
            'tempor', 'incididunt', 'ut', 'labore', 'et', 'dolore', 'magna', 'aliqua', 'enim', 'ad', 'minim',
            'veniam', 'quis', 'nostrud', 'exercitation', 'ullamco', 'laboris', 'nisi', 'aliquip', 'ex', 'ea', 'commodo',
            'consequat', 'duis', 'aute', 'irure', 'dolor', 'in', 'reprehenderit', 'voluptate', 'velit', 'esse',
            'cillum', 'eu', 'fugiat', 'nulla', 'pariatur'
        ];

        const loremIpsumText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ';

        const generateText = () => {
            let text = startWithLorem.value ? loremIpsumText : '';
            for (let p = 0; p < paragraphs.value; p++) {
                let paragraph = '';
                const sentencesCount = getRandomInRange(sentencesPerParagraphRange.value[0], sentencesPerParagraphRange.value[1]);
                for (let s = 0; s < sentencesCount; s++) {
                    let sentence = '';
                    const wordsCount = getRandomInRange(wordsPerSentenceRange.value[0], wordsPerSentenceRange.value[1]);
                    for (let w = 0; w < wordsCount; w++) {
                        sentence += words[Math.floor(Math.random() * words.length)] + ' ';
                    }
                    paragraph += sentence.trim() + '. ';
                }
                text += paragraph.trim() + '\n\n';
            }
            generatedText.value = text.trim();
        };

        const copyText = () => {
            navigator.clipboard.writeText(generatedText.value);
            notification.success({
                content: 'Text copied successfully!',
                duration: 2000,
            });
        };

        const getRandomInRange = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        onMounted(() => {
            generateText();
        });

        return {
            tool,
            paragraphs,
            sentencesPerParagraphRange,
            wordsPerSentenceRange,
            startWithLorem,
            generatedText,
            generateText,
            copyText,
        };
    },
});
</script>

<style scoped>
.sliders-container {
    margin-bottom: 20px;
}

.converted-row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.converted-label {
    width: 235px;
    text-align: right;
}

@media (max-width: 768px) {
    .converted-label {
        width: 168px;
        text-align: right;
    }
}
</style>
