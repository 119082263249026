<template>
  <n-config-provider>
    <div v-if="isRendered" class="search-overlay" @click.self="closeSearch"
      :class="{ 'fade-in': isActive, 'fade-out': !isActive }">
      <div class="search-container-wrapper">
        <div class="search-container"
          :class="{ 'animated-search-bar': isActive, 'animated-search-bar-leave': !isActive }">
          <div class="search-bar">
            <n-card size="small">
              <n-input ref="searchInput" class="search-input" v-model:value="query" @input="performSearch"
                @keydown="handleKeydown" :placeholder="$t('super_search_modal_input_placeholder')" clearable
                size="large" prefix-icon="search-outline" />
            </n-card>
          </div>
          <div class="search-results" v-if="results.length">
            <n-card size="small">
              <n-space vertical size="small">
                <div v-for="(result, index) in results" :key="result.name" class="search-result"
                  :class="{ 'is-active': index === activeIndex }" @click="navigateTo(result.path)">
                  <div class="result-card">
                    <div class="result-icon">
                      <component :is="result.icon" />
                    </div>
                    <div class="result-info">
                      <div class="result-name">{{ result.name }}</div>
                      <div class="result-description">{{ $t(result.description) }}</div>
                    </div>
                  </div>
                </div>
              </n-space>
            </n-card>
          </div>
        </div>
      </div>
    </div>
  </n-config-provider>
</template>


<script>
import { shallowRef } from 'vue';
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  },
  data() {
    return {
      isActive: false,
      isRendered: false,
      query: '',
      results: [],
      activeIndex: 0,
      allTools: [
        ...toolsData.development.map(tool => ({ ...tool, icon: shallowRef(tool.icon) })),
        ...toolsData.crypto.map(tool => ({ ...tool, icon: shallowRef(tool.icon) })),
        ...toolsData.converter.map(tool => ({ ...tool, icon: shallowRef(tool.icon) })),
        ...toolsData.text.map(tool => ({ ...tool, icon: shallowRef(tool.icon) })),
        ...toolsData.measurement.map(tool => ({ ...tool, icon: shallowRef(tool.icon) })),
      ],
    };
  },
  methods: {
    openSearch() {
      this.isRendered = true;
      this.isActive = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
      document.removeEventListener('keydown', this.handleKeydown);
    },
    closeSearch() {
      this.isActive = false;
      setTimeout(() => {
        this.isRendered = false;
        this.query = '';
        this.results = [];
        this.activeIndex = 0;
      }, 200);
      document.addEventListener('keydown', this.handleKeydown);
    },
    performSearch() {
      const queryLower = this.query.toLowerCase();
      this.results = this.allTools.filter((tool) => {
        return tool.keywords.some((keyword) => keyword.toLowerCase().includes(queryLower));
      }).slice(0, 5);

      this.activeIndex = 0;
    },
    navigateTo(path) {
      this.$router.push(path);
      this.closeSearch();
    },
    moveDown() {
      if (this.activeIndex < this.results.length - 1) {
        this.activeIndex++;
      }
    },
    moveUp() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },
    selectActiveResult() {
      if (this.results[this.activeIndex]) {
        this.navigateTo(this.results[this.activeIndex].path);
      }
    },
    handleKeydown(event) {

      // Закрытие по клавише ESC
      if (event.key === 'Escape') {
        this.closeSearch();
      }

      // Перемещение вниз
      if (event.key === 'ArrowDown') {
        this.moveDown();
        event.preventDefault(); // Предотвращаем стандартное поведение стрелки вниз
      }

      // Перемещение вверх
      if (event.key === 'ArrowUp') {
        this.moveUp();
        event.preventDefault(); // Предотвращаем стандартное поведение стрелки вверх
      }

      // Выбор активного результата по клавише Enter
      if (event.key === 'Enter') {
        this.selectActiveResult();
        event.preventDefault();
      }

      if ((event.ctrlKey || event.metaKey) && event.code === 'KeyK') {
        event.preventDefault();
        this.openSearch();
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style scoped>
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.no-results {
  margin-top: 10px;
  width: 600px;
  max-width: 90%;
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.5);
  }

  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.n-card__content {
  /* padding: 10px !important; */
}

.search-result {
  display: flex;
  align-items: center;
  border-radius: 4px;
  /* border-radius: .375rem; */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.search-result:hover {
  background-color: #18a058;
  /* background-color: #233633; */
  color: #ffffff;
  /* color: #63e2b7; */
}

.is-active {
  background-color: #18a058;
  /* background-color: #233633; */
  color: #ffffff;
  /* color: #63e2b7; */

}

.result-info {
  flex: 1;
}

.animated-search-bar {
  animation: slideDown 0.4s ease forwards;
}

.animated-search-bar-leave {
  animation: slideUp 0.4s ease forwards;
}

.fade-in {
  animation: fadeIn 0.4s ease forwards;
}

.fade-out {
  animation: fadeOut 0.4s ease forwards;
}

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.search-container-wrapper {
  margin-top: 100px;
}

.search-input {
  font-size: larger;
  padding: 5px;
}

.search-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 600px;
  max-width: 90%;
}

.close-btn {
  margin-left: 10px;
}

.search-results {
  margin-top: 10px;
  max-height: 600px;
  overflow-y: auto;
  width: 600px;
  max-width: 90%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
}

.search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.result-card {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  padding-left: 10px;
  padding-bottom: 0;
}

/* .result-card:hover,
.search-result.active .result-card {
  background-color: #1fa54c;
  transform: scale(1.01);
} */

.result-icon {
  margin-right: 15px;
  font-size: 24px;
  /* color: #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  opacity: 0.5;
}

.result-info {
  flex: 1;
}

.result-name {
  font-size: 16px;
  font-weight: 700;
}

.result-description {
  font-size: 13px;
  opacity: 0.7;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 0;
}
</style>