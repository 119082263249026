<template>
  <n-config-provider :theme="mergedTheme">

    <n-notification-provider :max="4" placement="bottom-right">
      <div id="app" class="app-container">
        <!-- Подключаем Header с передачей темы -->
        <AppHeader @toggleSidebar="toggleSidebar" @open-search="openSearch" :isDark="isDark"
          @toggleTheme="toggleTheme" />

        <SuperSearch ref="searchBar" />

        <n-layout has-sider>
          <!-- Сайдбар с передачей информации о мобильной версии и функции переключения темы -->
          <AppSidebar v-model:collapsed="isCollapsed" :isMobile="isMobile" :isDark="isDark"
            :toggleTheme="toggleTheme" />

          <!-- Контент -->
          <n-layout-content class="content" :native-scrollbar="false" embedded>
            <n-dialog-provider>
              <router-view />
            </n-dialog-provider>
          </n-layout-content>
        </n-layout>
      </div>
    </n-notification-provider>
  </n-config-provider>
</template>

<script>
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue';
import { darkTheme } from 'naive-ui';
import AppHeader from './components/AppHeader.vue';
import AppSidebar from './components/AppSidebar.vue';
import SuperSearch from './components/SuperSearch.vue';

export default defineComponent({
  name: 'App',
  components: {
    AppHeader,
    AppSidebar,
    SuperSearch,
  },
  methods: {
    openSearch() {
      this.$refs.searchBar.openSearch();
    },
  },
  setup() {
    const isCollapsed = ref(localStorage.getItem('sidebarCollapsed') === 'true' ? true : false); // Состояние сайдбара
    const isDark = ref(true); // Состояние темы
    const isMobile = ref(window.innerWidth <= 768); // Проверка на мобильную версию

    // Инициализация темы из localStorage
    onMounted(() => {
      const savedTheme = localStorage.getItem('theme');
      if (savedTheme) {
        isDark.value = savedTheme === 'dark';
      } else {
        localStorage.setItem('theme', 'dark');
      }

      // При загрузке страницы, если мобильное устройство — скрываем сайдбар
      if (isMobile.value) {
        isCollapsed.value = true;
      }
    });

    // Обработчик изменения размера экрана
    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768;
      if (isMobile.value) {
        isCollapsed.value = true; // Автоматически закрываем сайдбар на мобильных устройствах
      }
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize); // Отслеживание изменений размера окна
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize); // Удаление обработчика при разрушении компонента
    });

    const toggleSidebar = () => {
      isCollapsed.value = !isCollapsed.value; // Переключение состояния сайдбара
      localStorage.setItem('sidebarCollapsed', isCollapsed.value.toString());
    };

    const toggleTheme = () => {
      isDark.value = !isDark.value;
      localStorage.setItem('theme', isDark.value ? 'dark' : 'light');
      window.dispatchEvent(new CustomEvent('theme-change', { detail: { theme: isDark.value ? 'dark' : 'light' } }));
    };

    // Объединяем темы для передачи в Naive UI
    const mergedTheme = computed(() => (isDark.value ? darkTheme : null));

    return {
      isCollapsed,
      isDark,
      isMobile, // Передаем информацию о мобильной версии
      mergedTheme,
      toggleSidebar,
      toggleTheme, // Передаем функцию переключения темы
    };
  },
});

if (typeof window !== 'undefined') {
  const observerErr = () => {
    let resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
    let resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');

    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.style.display = 'none';
    }
    if (resizeObserverErr) {
      resizeObserverErr.style.display = 'none';
    }
  };

  window.addEventListener('error', (event) => {
    if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
      event.stopImmediatePropagation();
      observerErr();
    }
  });
}

</script>

<style>
.sider::-webkit-scrollbar {
  width: 0;
}

.cm-editor {
  height: calc(100vh - 400px) !important;
  overflow: auto !important;
  transition: all 0.3s ease-in-out;
}

.error-alert .cm-editor {
  height: calc(100vh - 466px) !important;
  overflow: auto !important;
  transition: all 0.3s ease-in-out;
}

.full-screen .cm-editor {
  height: calc(100vh - 230px) !important;
  overflow: auto !important;
  transition: all 0.3s ease-in-out;
}

.full-screen .error-alert .cm-editor {
  height: calc(100vh - 296px) !important;
  overflow: auto !important;
  transition: all 0.3s ease-in-out;
}

.json-editor .cm-scroller {
  scroll-behavior: smooth;
  overflow: auto;
}

/* Стили для самого скроллбара */
.json-editor .cm-scroller::-webkit-scrollbar {
  width: 7px; 
  height: 7px;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

/* Стили для "ползунка" скроллбара */
.json-editor .cm-scroller::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px; 
  transition: background-color 0.3s ease-in-out; 
}

.json-editor .cm-scroller::-webkit-scrollbar-thumb:hover {
  background-color: #999; 
}

.json-formatter .n-card-header {
  padding-bottom: 0 !important;
}

.sider {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100vh - 64px);
}

.app-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.n-layout {
  overflow: hidden;
}

.content {
  padding: 24px;
  flex-grow: 1;
  overflow: auto;
}

.tool-container {
  max-width: 650px;
  margin: 0 auto;
  padding-top: 32px;
}

.separator {
  width: 200px;
  height: 2px;
  background: #a1a1a1;
  opacity: .2;
  margin: 0;
}

.tool-title {
  margin: 0;
  font-size: 38px;
  font-weight: 500;
}

.tool-description {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .tool-container {
    max-width: 650px;
    margin: 0 auto;
    padding: 0px;
  }
}
</style>
