<template>
    <div class="tool-container">
        <ToolHeader :toolData="toolData" />

        <n-card>
            <n-space vertical size="large">

                <n-flex gap="2" vertical style="margin-bottom: 10px;">
                    <label>Input Hexadecimal or Binary Data</label>
                    <n-input v-model:value="inputData" placeholder="Enter your hex or binary data here" type="textarea"
                        @input="convertData" :autosize="{ minRows: 3, maxRows: 10, }" />
                </n-flex>

                <n-flex gap="2" vertical>
                    <label>Converted String</label>
                    <n-input v-model:value="convertedString" placeholder="The converted string will appear here"
                        type="textarea" :autosize="{ minRows: 3, maxRows: 10, }" readonly />
                </n-flex>

                <n-space style="margin-bottom: 10px;">
                    <n-switch v-model:value="tryToParseJson" size="medium" :round="false" @update:value="convertData" />

                    <label>Try to parse result as JSON</label>
                </n-space>

                <n-space justify="center">
                    <n-button @click="copyConvertedString">Copy String</n-button>
                </n-space>
            </n-space>
        </n-card>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import { saveHexInput, getHexInput, saveSwitchState, getSwitchState } from '../../indexedDbService';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'HexBinaryToString',
    components: { ToolHeader },
    setup() {

        const toolData = toolsData.converter.find(tool => tool.path === '/hexadecimal-converter');

        const tryToParseJson = ref(false);

        const inputData = ref('');
        const convertedString = ref('');
        const notification = useNotification();

        onMounted(async () => {
            inputData.value = await getHexInput(); // Подтягиваем данные при загрузке
            tryToParseJson.value = await getSwitchState(); // Загрузка состояния свитча
            if (inputData.value) convertData(); // Выполняем конвертацию при наличии данных
        });

        watch(inputData, (newInput) => saveHexInput(newInput)); // Сохраняем данные при каждом изменении
        watch(tryToParseJson, (newState) => saveSwitchState(newState));

        const removeNonPrintableChars = (str) => {
            // Удаляем все неотображаемые символы (ASCII < 32, кроме пробелов и переносов строк)
            return str.replace(/[^\x20-\x7E\n\r]+/g, '');
        };

        const extractJson = (str) => {
            const jsonMatch = str.match(/{[\s\S]*}$/); // Ищем JSON с первого открывающего '{'
            return jsonMatch ? jsonMatch[0] : str; // Возвращаем JSON или оригинальную строку, если не нашли
        };

        const convertData = () => {
            try {
                const cleanedInput = inputData.value.replace(/[\s,]/g, '');

                if (/^[0-9a-fA-F]+$/g.test(cleanedInput)) {
                    let result = cleanedInput.match(/.{1,2}/g)
                        .map(byte => String.fromCharCode(parseInt(byte, 16)))
                        .join('')
                        .replace(/\0+$/, '');

                    if (tryToParseJson.value) {
                        result = removeNonPrintableChars(result); // Удаляем неотображаемые символы
                    }

                    // Извлекаем JSON, если он присутствует
                    if (tryToParseJson.value) {
                        convertedString.value = extractJson(result);
                    } else {
                        convertedString.value = result;
                    }
                } else if (/^[01]+$/g.test(cleanedInput)) {
                    let result = cleanedInput.match(/.{1,8}/g)
                        .map(byte => String.fromCharCode(parseInt(byte, 2)))
                        .join('')
                        .replace(/\0+$/, '');

                    if (tryToParseJson.value) {
                        result = removeNonPrintableChars(result); // Удаляем неотображаемые символы
                    }

                    // Извлекаем JSON, если он присутствует
                    if (tryToParseJson.value) {
                        convertedString.value = extractJson(result);
                    } else {
                        convertedString.value = result;
                    }
                } else {
                    convertedString.value = 'Invalid input: Please enter valid hexadecimal or binary data';
                }

                if (tryToParseJson.value) {
                    // Проверка на JSON и форматирование
                    try {
                        const parsedJson = JSON.parse(convertedString.value);
                        convertedString.value = JSON.stringify(parsedJson, null, 4);
                    } catch (jsonError) {
                        // Если строка не является JSON, оставляем её как есть
                    }
                }
            } catch (error) {
                convertedString.value = 'Conversion error: ' + error.message;
            }
        };


        const copyConvertedString = () => {
            navigator.clipboard.writeText(convertedString.value);
            notification.success({
                content: 'Converted string copied successfully!',
                duration: 2000,
            });
        };

        return {
            inputData,
            convertedString,
            convertData,
            copyConvertedString,
            toolData,
            tryToParseJson
        };
    },
});
</script>

<style scoped>
.input-wrapper {
    margin-bottom: 20px;
}
</style>