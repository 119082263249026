<template>
    <n-config-provider>
        <div class="tool-container">
            <ToolHeader :toolData="toolData" />

            <n-card>
                <n-space vertical size="large">

                    <!-- Color picker -->
                    <div class="color-row">
                        <n-color-picker v-model:value="color" :show-preview="true" placement="bottom-end"
                            @input="updateAllFormats(color)" />
                    </div>

                    <n-divider style="margin-top: 10px;" />

                    <n-space vertical>
                        <!-- Color formats -->
                        <div class="color-row">
                            <label>hex:</label>
                            <n-input v-model:value="hex" @input="updateFromHex">
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copy(hex)">
                                        <n-icon :component="Copy" />
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="color-row">
                            <label>rgb:</label>
                            <n-input v-model:value="rgb" @input="updateFromRgb">
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copy(rgb)">
                                        <n-icon :component="Copy" />
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="color-row">
                            <label>hsl:</label>
                            <n-input v-model:value="hsl" @input="updateFromHsl">
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copy(hsl)">
                                        <n-icon :component="Copy" />
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="color-row">
                            <label>hwb:</label>
                            <n-input v-model:value="hwb" @input="updateFromHwb">
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copy(hwb)">
                                        <n-icon :component="Copy" />
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="color-row">
                            <label>lch:</label>
                            <n-input v-model:value="lch" @input="updateFromLch">
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copy(lch)">
                                        <n-icon :component="Copy" />
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="color-row">
                            <label>cmyk:</label>
                            <n-input v-model:value="cmyk" @input="updateFromCmyk">
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copy(cmyk)">
                                        <n-icon :component="Copy" />
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="color-row">
                            <label>name:</label>
                            <n-input :value="cssName" readonly>
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copy(cssName)">
                                        <n-icon :component="Copy" />
                                    </n-button>
                                </template>
                            </n-input>
                        </div>
                    </n-space>
                </n-space>
            </n-card>
        </div>
    </n-config-provider>
</template>

<script>
import { ref, watch } from 'vue';
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';
import cmykPlugin from 'colord/plugins/cmyk';
import hwbPlugin from 'colord/plugins/hwb';
import lchPlugin from 'colord/plugins/lch';
import namer from 'color-namer';
import { useNotification } from 'naive-ui';
import { Copy } from '@vicons/tabler';
import { toolsData } from '@/toolsData';
import ToolHeader from '@/components/ToolHeader.vue';


// Расширяем colord плагинами для дополнительных форматов
extend([namesPlugin, cmykPlugin, hwbPlugin, lchPlugin]);

export default {
    name: 'ColorConverter',
    components: { ToolHeader },
    setup() {
        const notification = useNotification();

        const toolData = toolsData.converter.find(tool => tool.path === '/color-converter');

        // Основное значение цвета, от которого происходят конверсии
        const color = ref('#56a270');

        // Форматы для отображения и ввода
        const hex = ref('');
        const rgb = ref('');
        const hsl = ref('');
        const hwb = ref('');
        const lch = ref('');
        const cmyk = ref('');
        const cssName = ref('');

        // Функция для обновления всех значений форматов из основного цвета
        function updateAllFormats(newColor) {
            const colordInstance = colord(newColor);
            if (colordInstance.isValid()) {
                color.value = newColor;
                hex.value = colordInstance.toHex();
                rgb.value = colordInstance.toRgbString();
                hsl.value = colordInstance.toHslString();
                hwb.value = colordInstance.toHwbString();
                const lchValue = colordInstance.toLch();
                lch.value = `lch(${lchValue.l.toFixed(2)}, ${lchValue.c.toFixed(2)}, ${lchValue.h.toFixed(2)})`;
                cmyk.value = colordInstance.toCmykString();
                const names = namer(newColor);
                cssName.value = names.ntc[0].name || 'No matching name';
            }
        }

        // Инициализация всех форматов на основе начального значения цвета
        updateAllFormats(color.value);

        // Обработчики ввода для каждого формата
        function updateFromHex() {
            if (colord(hex.value).isValid()) {
                updateAllFormats(hex.value);
            }
        }

        function updateFromRgb() {
            if (colord(rgb.value).isValid()) {
                updateAllFormats(rgb.value);
            }
        }

        function updateFromHsl() {
            if (colord(hsl.value).isValid()) {
                updateAllFormats(hsl.value);
            }
        }

        function updateFromHwb() {
            try {
                const hwbParts = hwb.value.match(/hwb\((\d+(\.\d+)?),\s*(\d+(\.\d+)?)%,\s*(\d+(\.\d+)?)%\)/);
                if (hwbParts) {
                    const parsedHwb = {
                        h: parseFloat(hwbParts[1]),
                        w: parseFloat(hwbParts[3]) / 100,
                        b: parseFloat(hwbParts[5]) / 100,
                        mode: 'hwb',
                    };
                    const hwbColor = colord(parsedHwb);
                    if (hwbColor.isValid()) {
                        updateAllFormats(hwbColor.toHex());
                    }
                }
            } catch (error) {
                console.error('Invalid HWB format', error);
            }
        }

        function updateFromLch() {
            try {
                const lchParts = lch.value.match(/lch\((\d+(\.\d+)?),\s*(\d+(\.\d+)?),\s*(\d+(\.\d+)?)\)/);
                if (lchParts) {
                    const lchColor = colord({
                        l: parseFloat(lchParts[1]),
                        c: parseFloat(lchParts[3]),
                        h: parseFloat(lchParts[5]),
                        mode: 'lch',
                    });
                    if (lchColor.isValid()) {
                        updateAllFormats(lchColor.toHex());
                    }
                }
            } catch (error) {
                console.error('Invalid LCH format');
            }
        }

        function updateFromCmyk() {
            try {
                const cmykParts = cmyk.value.match(/cmyk\((\d+(\.\d+)?)%,\s*(\d+(\.\d+)?)%,\s*(\d+(\.\d+)?)%,\s*(\d+(\.\d+)?)%\)/);
                if (cmykParts) {
                    const parsedCmyk = {
                        c: parseFloat(cmykParts[1]) / 100,
                        m: parseFloat(cmykParts[3]) / 100,
                        y: parseFloat(cmykParts[5]) / 100,
                        k: parseFloat(cmykParts[7]) / 100,
                        mode: 'cmyk',
                    };
                    const cmykColor = colord(parsedCmyk);
                    if (cmykColor.isValid()) {
                        updateAllFormats(cmykColor.toHex());
                    }
                }
            } catch (error) {
                console.error('Invalid CMYK format', error);
            }
        }

        function copy(value) {
            navigator.clipboard.writeText(value.value || value).then(() => {
                notification.success({
                    content: 'Copied successfully!',
                    duration: 2000,
                });
            });
        }

        // Следим за изменением значения в color picker и обновляем все форматы
        watch(color, (newColor) => {
            updateAllFormats(newColor);
        });

        return {
            color,
            hex,
            rgb,
            hsl,
            hwb,
            lch,
            cmyk,
            cssName,
            copy,
            updateFromHex,
            updateFromRgb,
            updateFromHsl,
            updateFromHwb,
            updateFromLch,
            updateFromCmyk,
            Copy,
            toolData
        };
    },
};
</script>


<style>
.tool-header {
    margin-bottom: 20px;
}

.color-row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.color-row label {
    width: 80px;
    text-align: right;
}

.n-color-picker,
n-input {
    flex: 1;
}
</style>