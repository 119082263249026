import { openDB } from 'idb';

const dbPromise = openDB('favoritesDB', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('favorites')) {
      db.createObjectStore('favorites', { keyPath: 'id' });
    }
  },
});

// Функция для сохранения порядка избранных
export async function saveFavoritesOrder(favorites) {
  try {
    const db = await dbPromise;
    // Создаем "чистую" версию данных, удаляя неклонируемые свойства
    const sanitizedFavorites = favorites.map(tool => {
      return {
        name: tool.name,
        description: tool.description,
        path: tool.path,
        // Добавьте любые другие свойства, которые нужно сохранить
      };
    });
    await db.put('favorites', { id: 'favoriteTools', items: sanitizedFavorites });
  } catch (error) {
    console.error('Ошибка при сохранении порядка избранных:', error);
  }
}

// Функция для получения порядка избранных
export async function getFavoritesOrder() {
  try {
    const db = await dbPromise;
    const result = await db.get('favorites', 'favoriteTools');
    return result ? result.items : [];
  } catch (error) {
    console.error('Ошибка при загрузке порядка избранных:', error);
    return [];
  }
}
