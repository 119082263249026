/* global gtag */

import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import JsonFormatter from './tools/JsonFormatter.vue';
import UuidGenerator from './tools/UuidGenerator.vue';
import TokenGenerator from './tools/TokenGenerator.vue';
import HashText from './tools/HashText.vue';
import PasswordStrengthAnalyzer from './tools/PasswordStrengthAnalyzer.vue';
import DateTimeConverter from './tools/DateTimeConverter.vue';
import SlugifyString from './tools/SlugifyString.vue';
import UserAgentParser from './tools/UserAgentParser.vue';
import HexaDecimalConverter from './tools/HexaDecimal.vue';
import Base64StringConverter from './tools/Base64StringConverter.vue';
import ColorConverter from './tools/ColorConverter.vue';
import CaseConverter from './tools/CaseConverter.vue';
import UnicodeConverter from './tools/UnicodeConverter.vue';
import TimeCalculator from './tools/TimeCalculator.vue';
import LoremIpsumGenerator from './tools/LoremIpsumGenerator.vue';
import TextStatistics from './tools/TextStatistics.vue';
import StringObfuscator from './tools/StringObfuscator.vue';
import DataSizeConverter from './tools/DataSizeConverter.vue';
import Base64FileConverter from './tools/Base64FileConverter.vue';
import KeycodeInfo from './tools/KeycodeInfo.vue';
import ChronometerTool from './tools/ChronometerTool.vue';
import SVGPlaceholderGenerator from './tools/SVGPlaceholderGenerator.vue';
import NotFound from '@/components/NotFound.vue'; // Импортируем 404 страницу

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'Toolsy IT - A collection of useful developer tools.',
      descriptions: {
        en: 'Toolsy IT - A collection of useful developer tools.',
        ua: 'Toolsy IT - Колекція корисних інструментів для розробників.',
        ru: 'Toolsy IT - Коллекция полезных инструментов для разработчиков.'
      }
    }
  },
  {
    path: '/:pathMatch(.*)*', // Обрабатывает любые неизвестные маршруты
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 - Toolsy IT',
      descriptions: {
        en: 'Oops! The page you are looking for does not exist. Use the menu to find the tools you need.',
        uk: 'На жаль! Сторінка, яку ви шукаєте, не існує. Використовуйте меню для пошуку потрібних інструментів.',
        ru: 'К сожалению! Страница, которую вы ищете, не существует. Используйте меню для поиска нужных инструментов.'
      }
    }
  },
  {
    path: '/json-formatter',
    name: 'JsonFormatter',
    component: JsonFormatter,
    meta: {
      title: 'JSON Formatter Tool - Toolsy IT',
      descriptions: {
        en: 'Format, validate, and minify JSON data quickly with our free online JSON Formatter Tool. Perfect for developers who need clean and readable JSON.',
        uk: 'Форматуй, перевіряй та мінімізуй JSON дані швидко з нашим безкоштовним онлайн-інструментом JSON Formatter. Ідеально підходить для розробників, яким потрібний чистий та читабельний JSON.',
        ru: 'Форматируйте, проверяйте и минимизируйте JSON данные с помощью нашего бесплатного онлайн-инструмента JSON Formatter. Идеально для разработчиков, которым нужен чистый и читаемый JSON.'
      }
    }
  },
  {
    path: '/uuid-generator',
    name: 'UuidGenerator',
    component: UuidGenerator,
    meta: {
      title: 'UUID Generator Tool - Toolsy IT',
      descriptions: {
        en: 'Generate UUIDs (v1, v4 and NIL) quickly and easily with our free online UUID Generator Tool. Perfect for developers and system architects needing unique identifiers.',
        uk: 'Генеруйте UUID (v1, v4 та NIL) швидко та легко за допомогою нашого безкоштовного онлайн-інструменту UUID Generator. Ідеально підходить для розробників та системних архітекторів, яким потрібні унікальні ідентифікатори.',
        ru: 'Генерируйте UUID (v1, v4 и NIL) быстро и легко с помощью нашего бесплатного онлайн-инструмента UUID Generator. Идеально подходит для разработчиков и системных архитекторов, которым нужны уникальные идентификаторы.'
      }
    }
  },
  {
    path: '/token-generator',
    name: 'TokenGenerator',
    component: TokenGenerator,
    meta: {
      title: 'Token Generator Tool - Toolsy IT',
      descriptions: {
        en: 'Generate secure tokens instantly with our free Token Generator Tool. Ideal for developers needing API keys, session tokens, or authentication keys.',
        uk: 'Генеруйте надійні токени миттєво за допомогою нашого безкоштовного генератора токенів. Ідеально підходить для розробників, яким потрібні API-ключі, сесійні токени або ключі автентифікації.',
        ru: 'Генерируйте безопасные токены мгновенно с помощью нашего бесплатного генератора токенов. Идеально подходит для разработчиков, которым нужны API-ключи, сессионные токены или ключи аутентификации.'
      }
    }
  },
  {
    path: '/hash-text',
    name: 'HashText',
    component: HashText,
    meta: {
      title: 'Hash Text Tool - Toolsy IT',
      descriptions: {
        en: 'Generate secure hash values using various algorithms such as MD5, SHA-1, SHA-256, SHA-224, SHA-512, SHA-384, SHA-3, and RIPEMD-160. Choose from multiple encoding options, including Binary, Hexadecimal, Base64, and Base64url.',
        uk: 'Генеруйте надійні хеш-значення за допомогою різних алгоритмів, таких як MD5, SHA-1, SHA-256, SHA-224, SHA-512, SHA-384, SHA-3 та RIPEMD-160. Оберіть одну з кількох варіантів кодування: двійкове, шістнадцяткове, Base64 або Base64url.',
        ru: 'Генерируйте безопасные хеш-значения с использованием различных алгоритмов, таких как MD5, SHA-1, SHA-256, SHA-224, SHA-512, SHA-384, SHA-3 и RIPEMD-160. Выберите один из нескольких вариантов кодирования: двоичное, шестнадцатеричное, Base64 или Base64url.'
      }
    }
  },
  {
    path: '/password-strength-analyser',
    name: 'PasswordStrengthAnalyzer',
    component: PasswordStrengthAnalyzer,
    meta: {
      title: 'Password Strength Analyzer Tool - Toolsy IT',
      descriptions: {
        en: 'Check the strength of your passwords with our Password Strength Analyzer Tool. Ensure your passwords are secure and resistant to hacking by testing them in real-time.',
        uk: 'Перевірте надійність ваших паролів за допомогою нашого інструменту для аналізу паролів. Переконайтеся, що ваші паролі захищені та стійкі до злому, протестувавши їх у режимі реального часу.',
        ru: 'Проверьте надёжность ваших паролей с помощью нашего инструмента анализа паролей. Убедитесь, что ваши пароли защищены и устойчивы к взлому, протестировав их в режиме реального времени.'
      }
    }
  },
  {
    path: '/date-time-converter',
    name: 'DateTimeConverter',
    component: DateTimeConverter,
    meta: {
      title: 'DateTime Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Convert dates and timestamps between multiple formats, including ISO, RFC, Unix, and UTC formats, with our DateTime Converter tool.',
        uk: 'Перетворюйте дати та часові мітки між різними форматами, включаючи ISO, RFC, Unix та UTC, за допомогою нашого інструменту DateTime Converter.',
        ru: 'Преобразуйте даты и временные метки между разными форматами, включая ISO, RFC, Unix и UTC, с помощью нашего инструмента DateTime Converter.'
      }
    }
  },
  {
    path: '/slugify-string',
    name: 'SlugifyString',
    component: SlugifyString,
    meta: {
      title: 'Slugify String Tool - Toolsy IT',
      descriptions: {
        en: 'Convert any text into a slug-friendly format with our Slugify String tool. Ideal for creating URLs, SEO-friendly slugs, and clean file names.',
        uk: 'Перетворюйте будь-який текст у зручний для URL-адрес формат за допомогою нашого інструмента Slugify String. Ідеально підходить для створення URL-адрес, SEO-дружніх слагів та чистих назв файлів.',
        ru: 'Преобразуйте любой текст в удобный для URL формат с помощью инструмента Slugify String. Идеально подходит для создания URL, SEO-дружественных слагов и чистых имен файлов.'
      }
    }
  },
  {
    path: '/user-agent-parser',
    name: 'UserAgentParser',
    component: UserAgentParser,
    meta: {
      title: 'User Agent Parser Tool - Toolsy IT',
      descriptions: {
        en: 'Analyze and parse user-agent strings to extract browser, OS, engine, and device details with the User Agent Parser Tool. Ideal for developers troubleshooting compatibility issues.',
        uk: 'Аналізуйте та розбирайте рядки user-agent для визначення браузера, ОС, рушія та пристрою за допомогою інструмента User Agent Parser. Ідеально підходить для розробників, які вирішують питання сумісності.',
        ru: 'Анализируйте и разбирайте строки user-agent для извлечения информации о браузере, ОС, движке и устройстве с помощью инструмента User Agent Parser. Идеально для разработчиков, решающих проблемы совместимости.'
      }
    }
  },
  {
    path: '/hexadecimal-converter',
    name: 'HexaDecimalConverter',
    component: HexaDecimalConverter,
    meta: {
      title: 'Hexadecimal Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Convert hexadecimal or binary data to text effortlessly with the Hexadecimal Converter Tool. Perfect for decoding and debugging encoded data.',
        uk: 'Конвертуйте шістнадцяткові або бінарні дані у текст за допомогою Hexadecimal Converter Tool. Ідеально підходить для декодування та відлагодження закодованих даних.',
        ru: 'Преобразуйте шестнадцатеричные или двоичные данные в текст с помощью Hexadecimal Converter Tool. Идеально подходит для декодирования и отладки закодированных данных.'
      }
    }
  },
  {
    path: '/base64-string-converter',
    name: 'Base64StringConverter',
    component: Base64StringConverter,
    meta: {
      title: 'Base64 String Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Easily encode and decode strings using Base64 with our Base64 String Converter Tool. Perfect for data encoding, decoding, and troubleshooting.',
        uk: 'Легко кодуйте та декодуйте рядки за допомогою інструмента Base64 String Converter Tool. Ідеально підходить для кодування даних та усунення несправностей.',
        ru: 'Легко кодируйте и декодируйте строки с помощью Base64 String Converter Tool. Идеально подходит для кодирования данных и устранения неполадок.'
      }
    }
  },
  {
    path: '/color-converter',
    name: 'ColorConverter',
    component: ColorConverter,
    meta: {
      title: 'Color Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Convert colors between HEX, RGB, HSL, CMYK, and more using our Color Converter Tool. Quickly switch between formats for seamless design workflows.',
        uk: 'Перетворюйте кольори між HEX, RGB, HSL, CMYK та іншими форматами за допомогою Color Converter Tool. Швидко перемикайтесь між форматами для зручної роботи з дизайном.',
        ru: 'Конвертируйте цвета между HEX, RGB, HSL, CMYK и другими форматами с помощью Color Converter Tool. Легко переключайтесь между форматами для работы с дизайном.'
      }
    }
  },
  {
    path: '/case-converter',
    name: 'CaseConverter',
    component: CaseConverter,
    meta: {
      title: 'Case Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Easily convert text between different cases with our Case Converter Tool. From lowercase to camelCase, snake_case, and more.',
        uk: 'Легко перетворюйте текст між різними регістрами за допомогою Case Converter Tool. Від lowercase до camelCase, snake_case та інших.',
        ru: 'Легко конвертируйте текст между разными регистрами с помощью Case Converter Tool. От lowercase до camelCase, snake_case и других.'
      }
    }
  },
  {
    path: '/unicode-converter',
    name: 'UnicodeConverter',
    component: UnicodeConverter,
    meta: {
      title: 'Unicode Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Easily convert between string and Unicode representations with our Unicode Converter Tool. Supports HTML entities and Unicode escape formats.',
        uk: 'Легко конвертуйте між рядками та представленням Unicode за допомогою Unicode Converter Tool. Підтримує HTML-символи та Unicode-формати.',
        ru: 'Легко конвертируйте между строками и представлением Unicode с помощью Unicode Converter Tool. Поддерживает HTML-сущности и Unicode-форматы.'
      }
    }
  },
  {
    path: '/time-unit-converter',
    name: 'TimeCalculator',
    component: TimeCalculator,
    meta: {
      title: 'Time Unit Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Convert time units quickly and accurately using our Time Unit Converter Tool. Supports milliseconds, seconds, minutes, hours, days, weeks, months, and years.',
        uk: 'Конвертуйте одиниці часу швидко та точно за допомогою Time Unit Converter Tool. Підтримує мілісекунди, секунди, хвилини, години, дні, тижні, місяці та роки.',
        ru: 'Конвертируйте единицы времени быстро и точно с помощью Time Unit Converter Tool. Поддерживает миллисекунды, секунды, минуты, часы, дни, недели, месяцы и годы.'
      }
    }
  },
  {
    path: '/lorem-ipsum-generator',
    name: 'LoremIpsumGenerator',
    component: LoremIpsumGenerator,
    meta: {
      title: 'Lorem Ipsum Generator Tool - Toolsy IT',
      descriptions: {
        en: 'Generate random placeholder text with the Lorem Ipsum Generator Tool. Customize the number of paragraphs, sentences, and words for your project needs.',
        uk: 'Генеруйте випадковий текст-заповнювач за допомогою Lorem Ipsum Generator Tool. Налаштуйте кількість абзаців, речень і слів для своїх потреб.',
        ru: 'Создавайте случайный текст-заполнитель с помощью Lorem Ipsum Generator Tool. Настройте количество абзацев, предложений и слов в соответствии с вашими требованиями.'
      }
    }
  },
  {
    path: '/text-statistics',
    name: 'TextStatistics',
    component: TextStatistics,
    meta: {
      title: 'Text Statistics Tool - Toolsy IT',
      descriptions: {
        en: 'Analyze your text quickly with the Text Statistics Tool. Get character, word, line counts, and byte size in real-time.',
        uk: 'Аналізуйте текст швидко за допомогою Text Statistics Tool. Отримуйте кількість символів, слів, рядків та розмір у байтах в реальному часі.',
        ru: 'Анализируйте текст с помощью Text Statistics Tool. Получайте количество символов, слов, строк и размер в байтах в реальном времени.'
      }
    }
  },
  {
    path: '/string-obfuscator',
    name: 'StringObfuscator',
    component: StringObfuscator,
    meta: {
      title: 'String Obfuscator Tool - Toolsy IT',
      descriptions: {
        en: 'Easily obfuscate sensitive strings by hiding parts of them. Customize how many characters to keep visible at the beginning and end with our String Obfuscator Tool.',
        uk: 'Легко обфускуйте конфіденційні рядки, приховуючи їх частини. Налаштуйте, скільки символів залишити видимими на початку та в кінці за допомогою нашого String Obfuscator Tool.',
        ru: 'Просто обфусцируйте конфиденциальные строки, скрывая их части. Настройте количество символов, видимых в начале и в конце, с помощью нашего String Obfuscator Tool.'
      }
    }
  },
  {
    path: '/data-size-converter',
    name: 'DataSizeConverter',
    component: DataSizeConverter,
    meta: {
      title: 'Data Size Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Convert data sizes between bytes, kilobytes, megabytes, gigabytes, and terabytes with our Data Size Converter Tool. Perfect for accurate data size comparisons and conversions.',
        uk: 'Конвертуйте розміри даних між байтами, кілобайтами, мегабайтами, гігабайтами та терабайтами за допомогою нашого Data Size Converter Tool. Ідеально підходить для точного порівняння та конвертації розмірів даних.',
        ru: 'Конвертируйте размеры данных между байтами, килобайтами, мегабайтами, гигабайтами и терабайтами с помощью нашего Data Size Converter Tool. Идеально подходит для точного сравнения и конвертации размеров данных.'
      }
    }
  },
  {
    path: '/base64-file-converter',
    name: 'Base64FileConverter',
    component: Base64FileConverter,
    meta: {
      title: 'Base64 File Converter Tool - Toolsy IT',
      descriptions: {
        en: 'Convert files to and from Base64 format with our Base64 File Converter Tool. Upload a file or paste Base64 string to convert back into a file.',
        uk: 'Конвертуйте файли в формат Base64 та назад за допомогою нашого Base64 File Converter Tool. Завантажте файл або вставте рядок Base64 для зворотного перетворення у файл.',
        ru: 'Конвертируйте файлы в формат Base64 и обратно с помощью нашего Base64 File Converter Tool. Загрузите файл или вставьте строку Base64 для обратного преобразования в файл.'
      }
    }
  },
  {
    path: '/keycode-info',
    name: 'KeycodeInfo',
    component: KeycodeInfo,
    meta: {
      title: 'Keycode Info Tool - Toolsy IT',
      descriptions: {
        en: 'Explore keycodes and key details by pressing any key with the Keycode Info Tool. Get information such as key name, keycode, physical key location, and any active modifiers like Ctrl, Shift, Alt, or Meta.',
        uk: 'Досліджуйте коди клавіш і деталі натискань за допомогою нашого Keycode Info Tool. Отримуйте інформацію, таку як назва клавіші, код клавіші, фізичне розташування та активні модифікатори, як-от Ctrl, Shift, Alt або Meta.',
        ru: 'Исследуйте коды клавиш и детали нажатий с помощью нашего Keycode Info Tool. Получите информацию, такую как название клавиши, код клавиши, физическое расположение и активные модификаторы, такие как Ctrl, Shift, Alt или Meta.'
      }
    }
  },
  {
    path: '/chronometer',
    name: 'ChronometerTool',
    component: ChronometerTool,
    meta: {
      title: 'Chronometer Tool - Toolsy IT',
      description: {
        en: 'Measure precise time intervals with our Chronometer Tool. Record laps and monitor elapsed time efficiently.',
        uk: 'Вимірюйте точні часові інтервали за допомогою нашого Chronometer Tool. Фіксуйте кола та відстежуйте час.',
        ru: 'Измеряйте точные временные интервалы с помощью нашего Chronometer Tool. Записывайте круги и следите за временем.'
      }
    }
  },
  {
    path: '/svg-placeholder-generator',
    name: 'SVGPlaceholderGenerator',
    component: SVGPlaceholderGenerator,
    meta: {
      title: 'SVG Placeholder Generator - Toolsy IT',
      description: {
        en: 'Create SVG placeholders with customizable dimensions, colors, and text using our SVG Placeholder Generator. Ideal for quick mockups and responsive layouts.',
        uk: 'Створюйте SVG-заповнювачі з налаштовуваними розмірами, кольорами та текстом за допомогою нашого SVG Placeholder Generator. Ідеально підходить для швидких макетів та адаптивного дизайну.',
        ru: 'Создавайте SVG-заполнители для веб-дизайна с настраиваемыми размерами, цветами и текстом с помощью нашего SVG Placeholder Generator. Подходит для быстрых макетов и адаптивной верстки.'
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.afterEach((to) => {
  const currentLocale = localStorage.getItem('language') || 'en'; // Определяем текущую локаль

  const title = to.meta?.title || 'Toolsy IT';
  const description =
    to.meta?.descriptions?.[currentLocale] || to.meta?.descriptions?.en || '';

  document.title = title;

  updateMetaTag('description', description);
  updateMetaTag('og:title', title, 'property');
  updateMetaTag('og:description', description, 'property');
  updateMetaTag('og:url', window.location.href, 'property');

  insertJsonLd(createJsonLd(title, description)); // Вставляем JSON-LD

  gtag('config', 'G-JZX9NBBLLG', { page_path: to.fullPath });

  function updateMetaTag(name, content, type = 'name') {
    let tag = document.querySelector(`meta[${type}="${name}"]`);
    if (!tag) {
      tag = document.createElement('meta');
      tag.setAttribute(type, name);
      document.head.appendChild(tag);
    }
    tag.setAttribute('content', content);
  }

  function createJsonLd(title, description) {
    return {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": title,
      "description": description,
      "url": window.location.href,
      "isPartOf": {
        "@type": "WebSite",
        "name": "Toolsy IT",
        "url": "https://toolsyit.com"
      }
    };
  }

  function insertJsonLd(jsonLd) {
    let scriptTag = document.querySelector('script[type="application/ld+json"]');
    if (!scriptTag) {
      scriptTag = document.createElement('script');
      scriptTag.setAttribute('type', 'application/ld+json');
      document.head.appendChild(scriptTag);
    }
    scriptTag.textContent = JSON.stringify(jsonLd);
  }
});

export default router;
