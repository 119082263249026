<template>
    <n-config-provider>
        <div class="tool-container">
            
            <ToolHeader :toolData="toolData" />

            <n-card size="small">
                <div class="key-display" @keydown="handleKeyPress" tabindex="0" ref="keyDisplay">
                    <p class="keycode" v-if="keyInfo.key">{{ keyInfo.key }}</p>
                    <p :class="['keycode-label', { 'without-keycode': !keyInfo.key }]">
                        {{
                    keyInfo.key
                        ? t('keycode_info_input_placeholder')
                        : t('keycode_info_input_placeholder2')
                }}
                    </p>
                </div>
            </n-card>
            <div v-if="keyInfo.key" class="info-table">
                <n-card>
                    <n-space vertical>
                        <div class="converted-row">
                            <label class="converted-label">{{ $t('keycode_info_key_label') }}</label>
                            <n-input :value="keyInfo.key" readonly>
                                <template #suffix>
                                    <n-button style="font-size: 16px" text @click="copyToClipboard('Key', keyInfo.key)">
                                        <n-icon>
                                            <Copy />
                                        </n-icon>
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="converted-row">
                            <label class="converted-label">{{ $t('keycode_info_keycode_label') }}</label>
                            <n-input :value="keyInfo.keyCode" readonly>
                                <template #suffix>
                                    <n-button style="font-size: 16px" text
                                        @click="copyToClipboard('Keycode', keyInfo.keyCode)">
                                        <n-icon>
                                            <Copy />
                                        </n-icon>
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="converted-row">
                            <label class="converted-label">{{ $t('keycode_info_code_label') }}</label>
                            <n-input :value="keyInfo.code" readonly>
                                <template #suffix>
                                    <n-button style="font-size: 16px" text
                                        @click="copyToClipboard('Code', keyInfo.code)">
                                        <n-icon>
                                            <Copy />
                                        </n-icon>
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="converted-row">
                            <label class="converted-label">{{ $t('keycode_info_location_label') }}</label>
                            <n-input :value="keyInfo.location" readonly>
                                <template #suffix>
                                    <n-button style="font-size: 16px" text
                                        @click="copyToClipboard('Location', keyInfo.code)">
                                        <n-icon>
                                            <Copy />
                                        </n-icon>
                                    </n-button>
                                </template>
                            </n-input>
                        </div>

                        <div class="converted-row">
                            <label class="converted-label">{{ $t('keycode_info_modifiers_label') }}</label>
                            <n-input :value="keyInfo.modifiers || t('keycode_info_no_modifiers')" readonly>
                                <template #suffix>
                                    <n-button style="font-size: 16px" text
                                        @click="copyToClipboard('Modifiers', keyInfo.code)">
                                        <n-icon>
                                            <Copy />
                                        </n-icon>
                                    </n-button>
                                </template>
                            </n-input>
                        </div>
                    </n-space>
                </n-card>
            </div>

        </div>
    </n-config-provider>
</template>

<script>
import { ref, onMounted, reactive } from 'vue';
import { useNotification } from 'naive-ui';
import { Copy } from '@vicons/tabler';
import { toolsData } from '@/toolsData';
import { useI18n } from 'vue-i18n';
import ToolHeader from '@/components/ToolHeader.vue';

export default {
    components: { Copy, ToolHeader },
    setup() {

        const { t } = useI18n(); // Получаем функцию t для локализации

        const toolData = toolsData.development.find(tool => tool.path === '/keycode-info');

        const keyInfo = reactive({
            key: '',
            keyCode: '',
            code: '',
            location: '',
            modifiers: '',
        });

        const notification = useNotification();
        const keyDisplay = ref(null);

        // Функция для отслеживания нажатия клавиш
        const handleKeyPress = (event) => {
            keyInfo.key = event.key;
            keyInfo.keyCode = event.keyCode;
            keyInfo.code = event.code;
            keyInfo.location = event.location;
            keyInfo.modifiers = getModifiers(event);
        };

        // Вспомогательная функция для модификаторов
        const getModifiers = (event) => {
            const modifiers = [];
            if (event.ctrlKey) modifiers.push('Control');
            if (event.shiftKey) modifiers.push('Shift');
            if (event.altKey) modifiers.push('Alt');
            if (event.metaKey) modifiers.push('Meta');
            return modifiers.length ? modifiers.join(' + ') : t('keycode_info_no_modifiers');
        };

        // Копирование в буфер обмена
        const copyToClipboard = (title, text) => {
            navigator.clipboard.writeText(text).then(() => {
                notification.success({
                    content: title + t('keycode_info_copied_to_clipboard'),
                    duration: 2000,
                });
            });
        };

        // Установка фокуса на элемент для отслеживания клавиш при монтировании
        onMounted(() => {
            keyDisplay.value.focus();
            document.addEventListener('keydown', handleKeyPress);
        });

        return {
            t,
            keyInfo,
            handleKeyPress,
            copyToClipboard,
            keyDisplay,
            toolData
        };
    },
};
</script>

<style scoped>
.converted-row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.converted-label {
    width: 95px;
    text-align: right;
}

.keycode {
    font-size: 28px;
    margin-bottom: -10px;
}

.keycode-label {
    opacity: 0.7;
}

.keycode-label.without-keycode {
    padding: 20px;
    opacity: 0.7;
}

.key-display {
    line-height: 2.25rem;
    cursor: pointer;
    outline: none;
    text-align: center;
}

.info-table {
    margin-top: 10px;
    text-align: left;
}
</style>