<template>
  <n-config-provider>
    <div class="tool-container">
      <ToolHeader :toolData="toolData" />

      <n-card>
        <n-space vertical size="large">
          <!-- Поле для ввода текста -->
          <n-flex gap="2" vertical>
            <span>Your text to hash:</span>
            <n-input v-model:value="inputText" type="textarea" placeholder="Your string to hash..." rows="3" />
          </n-flex>

          <!-- Выбор кодировки -->
          <n-flex gap="2" vertical>
            <span>Digest encoding</span>
            <n-select v-model:value="encoding" :options="encodingOptions" />
          </n-flex>

          <!-- Вывод результатов хэширования -->
          <n-space vertical>
            <div class="converted-row" v-for="(hash, algo) in hashes" :key="algo">
              <label class="converted-label">{{ algo }}</label>
              <n-input :value="hash" readonly>
                <template #suffix>
                  <n-button text style="font-size: 16px" @click="copyToClipboard(hash, algo)">
                    <n-icon :component="Copy" />
                  </n-button>
                </template>
              </n-input>
            </div>
          </n-space>
        </n-space>
      </n-card>
    </div>
  </n-config-provider>
</template>


<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Copy } from '@vicons/tabler';
import { toolsData } from '@/toolsData';
import { useNotification } from 'naive-ui';
import CryptoJS from 'crypto-js';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
  name: 'HashText',
  components: { ToolHeader },
  setup() {
    const toolData = toolsData.crypto.find(tool => tool.path === '/hash-text');

    const hexToBinary = (hex) => {
      return hex.split('').map(char => {
        return parseInt(char, 16).toString(2).padStart(4, '0'); // Converts each hex char to 4-bit binary
      }).join('');
    };

    const inputText = ref(''); // Текст для хеширования
    const encoding = ref('hex'); // По умолчанию установлено Hexadecimal (base 16)
    const encodingOptions = ref([
      { label: 'Binary (base 2)', value: 'binary' },
      { label: 'Hexadecimal (base 16)', value: 'hex' },
      { label: 'Base64 (base 64)', value: 'base64' },
      { label: 'Base64url (base 64 with URL safe chars)', value: 'base64url' }
    ]);

    const hashes = ref({
      MD5: '',
      SHA1: '',
      SHA256: '',
      SHA224: '',
      SHA512: '',
      SHA384: '',
      SHA3: '',
      RIPEMD160: ''
    });

    const notification = useNotification();

    // Функция для генерации хэшей
    const generateHashes = () => {
      hashes.value.MD5 = encode(CryptoJS.MD5(inputText.value));
      hashes.value.SHA1 = encode(CryptoJS.SHA1(inputText.value));
      hashes.value.SHA256 = encode(CryptoJS.SHA256(inputText.value));
      hashes.value.SHA224 = encode(CryptoJS.SHA224(inputText.value));
      hashes.value.SHA512 = encode(CryptoJS.SHA512(inputText.value));
      hashes.value.SHA384 = encode(CryptoJS.SHA384(inputText.value));
      hashes.value.SHA3 = encode(CryptoJS.SHA3(inputText.value));
      hashes.value.RIPEMD160 = encode(CryptoJS.RIPEMD160(inputText.value));
    };

    // Кодирование результата в зависимости от выбранной кодировки
    const encode = (hash) => {
      switch (encoding.value) {
        case 'binary':
          return hexToBinary(hash.toString(CryptoJS.enc.Hex)); // Binary encoding
        case 'hex':
          return hash.toString(CryptoJS.enc.Hex); // Hexadecimal encoding
        case 'base64':
          return hash.toString(CryptoJS.enc.Base64); // Base64 encoding
        case 'base64url':
          return hash.toString(CryptoJS.enc.Base64url); // Base64url encoding
        default:
          return hash.toString(CryptoJS.enc.Hex); // По умолчанию Hexadecimal
      }
    };

    // Автоматическое обновление хэшей при изменении текста или кодировки
    watch([inputText, encoding], () => {
      generateHashes();
    });

    // Копирование хэша в буфер обмена
    const copyToClipboard = (hash, algo) => {
      navigator.clipboard.writeText(hash).then(() => {
        notification.success({
          content: algo + ' copied successfully!',
          duration: 2000,
        });
      });
    };

    // Генерация хэшей при загрузке компонента
    onMounted(() => {
      generateHashes(); // Генерация хэшей сразу при загрузке компонента
    });

    return {
      toolData,
      inputText,
      encoding,
      encodingOptions,
      hashes,
      copyToClipboard,
      Copy
    };
  }
});
</script>

<style scoped>
.converted-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.converted-label {
  width: 80px;
  text-align: right;
}

.n-input {
  flex: 1;
}
</style>
