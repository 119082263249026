<template>
    <div class="tool-container">

        <ToolHeader :toolData="toolData" />

        <n-card>
            <n-space vertical size="large">

                <n-flex gap="2" vertical>
                    <!-- <label>Your string</label> -->
                    <n-input v-model:value="inputString" placeholder="Enter your string" type="textarea" rows="3"
                        :autosize="{ minRows: 3, maxRows: 10 }" />
                </n-flex>

                <n-divider style="margin-top: 10px;" />


                <n-space vertical>
                    <div v-for="(result, key) in caseResults" :key="key" class="converted-row">
                        <label class="converted-label">{{ key }}</label>
                        <n-input :value="result" readonly>
                            <template #suffix>
                                <n-button text style="font-size: 16px" @click="copyText(result)">
                                    <n-icon :component="Copy" />
                                </n-button>
                            </template>
                        </n-input>
                    </div>
                </n-space>

            </n-space>
        </n-card>
    </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { useNotification } from 'naive-ui';
import { toolsData } from '@/toolsData';
import { Copy } from '@vicons/tabler';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'CaseConverter',
    components: { ToolHeader },
    setup() {
        const toolData = toolsData.converter.find(tool => tool.path === '/case-converter');
        const inputString = ref('lorem ipsum dolor sit amet');
        const notification = useNotification();

        const caseResults = computed(() => ({
            Lowercase: inputString.value.toLowerCase(),
            Uppercase: inputString.value.toUpperCase(),
            Camelcase: inputString.value.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
                return index == 0 ? match.toLowerCase() : match.toUpperCase();
            }).replace(/\s+/g, ''),
            Capitalcase: inputString.value.replace(/\b\w/g, char => char.toUpperCase()),
            Constantcase: inputString.value.toUpperCase().replace(/\s+/g, '_'),
            Dotcase: inputString.value.toLowerCase().replace(/\s+/g, '.'),
            Headercase: inputString.value.replace(/\b\w/g, char => char.toUpperCase()).replace(/\s+/g, '-'),
            Nocase: inputString.value.toLowerCase(),
            Paramcase: inputString.value.toLowerCase().replace(/\s+/g, '-'),
            Pascalcase: inputString.value.replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()).replace(/\s+/g, ''),
            Pathcase: inputString.value.toLowerCase().replace(/\s+/g, '/'),
            Sentencecase: inputString.value.charAt(0).toUpperCase() + inputString.value.slice(1).toLowerCase(),
            Snakecase: inputString.value.toLowerCase().replace(/\s+/g, '_'),
            Mockingcase: inputString.value.split('').map((char, i) => i % 2 == 0 ? char.toLowerCase() : char.toUpperCase()).join('')
        }));


        const copyText = (text) => {
            navigator.clipboard.writeText(text);
            notification.success({
                content: 'Text copied successfully!',
                duration: 2000,
            });
        };

        return {
            inputString,
            caseResults,
            copyText,
            toolData,
            Copy
        };
    },
});
</script>
<style scoped>
.converted-row {
    display: flex;
    align-items: center;
    gap: 16px;
}

.converted-label {
    width: 100px;
    text-align: right;
}

.n-input {
    flex: 1;
}
</style>