<template>
    <n-config-provider>
        <div class="tool-container">

            <ToolHeader :toolData="tool" />

            <n-card>
                <n-space vertical size="large">
                    <!-- Input Text Area -->
                    <n-input type="textarea" v-model:value="inputText" rows="5" :autosize="{ minRows: 5, maxRows: 15 }"
                        :placeholder="$t('text_statistics_input_placeholder')" @input="updateStatistics" />

                    <!-- Statistics -->
                    <n-grid cols="4" x-gap="20">
                        <n-grid-item>
                            <div class="stat-container">
                                <span class="stat-label">{{ $t('text_statistics_character_count') }}</span>
                                <span class="stat-value">{{ statistics.characters }}</span>
                            </div>
                        </n-grid-item>

                        <n-grid-item>
                            <div class="stat-container">
                                <span class="stat-label">{{ $t('text_statistics_word_count') }}</span>
                                <span class="stat-value">{{ statistics.words }}</span>
                            </div>
                        </n-grid-item>

                        <n-grid-item>
                            <div class="stat-container">
                                <span class="stat-label">{{ $t('text_statistics_line_count') }}</span>
                                <span class="stat-value">{{ statistics.lines }}</span>
                            </div>
                        </n-grid-item>

                        <n-grid-item>
                            <div class="stat-container">
                                <span class="stat-label">{{ $t('text_statistics_byte_size') }}</span>
                                <span class="stat-value">{{ statistics.bytes }}</span>
                            </div>
                        </n-grid-item>
                    </n-grid>
                </n-space>
            </n-card>
        </div>
    </n-config-provider>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { toolsData } from '@/toolsData';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
    name: 'TextStatistics',
    components: { ToolHeader },
    setup() {
        const tool = toolsData.text.find(t => t.path === '/text-statistics');
        const inputText = ref('');

        const statistics = ref({
            characters: 0,
            words: 0,
            lines: 1,
            bytes: 0,
        });

        const updateStatistics = () => {
            const text = inputText.value;
            statistics.value.characters = text.length;
            statistics.value.words = text.trim().split(/\s+/).filter(Boolean).length;
            statistics.value.lines = text.split(/\r\n|\r|\n/).length;
            const bytes = new TextEncoder().encode(text).length;
            statistics.value.bytes = bytes >= 1000 ? (bytes / 1024).toFixed(2) + ' KB' : bytes + ' Bytes';
        };

        onMounted(() => {
            updateStatistics();
        });

        return {
            tool,
            inputText,
            statistics,
            updateStatistics,
        };
    },
});
</script>

<style>
.stat-container {
    text-align: start;
}

.stat-label {
    display: block;
    font-size: 14px;
    opacity: 0.6;
}

.stat-value {
    margin-top: 5px;
    display: block;
    font-size: 20px;
}
</style>