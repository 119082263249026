<template>
  <n-config-provider>
    <div class="tool-container">

      <ToolHeader :toolData="tool" />

      <n-card>
        <n-space vertical size="large">
          <n-space class="switches-container">
            <!-- Левая колонка -->
            <n-grid :cols="1" y-gap="14">
              <n-grid-item>
                <n-space justify="space-between">
                  <span>Uppercase (ABC)</span>
                  <n-switch v-model:value="useUppercase" size="medium" :round="false" @change="generateToken" />
                </n-space>
              </n-grid-item>
              <n-grid-item>
                <n-space justify="space-between">
                  <span>Lowercase (abc)</span>
                  <n-switch v-model:value="useLowercase" size="medium" :round="false" @change="generateToken" />
                </n-space>
              </n-grid-item>
            </n-grid>

            <!-- Правая колонка -->
            <n-grid :cols="1" y-gap="14">
              <n-grid-item style="margin-left:36px;">
                <n-space justify="space-between">
                  <span>Numbers (123)</span>
                  <n-switch v-model:value="useNumbers" size="medium" :round="false" @change="generateToken" />
                </n-space>
              </n-grid-item>

              <n-grid-item style="margin-left:36px;">
                <n-space justify="space-between">
                  <span>Symbols (!@#$)</span>
                  <n-switch v-model:value="useSymbols" size="medium" :round="false" @change="generateToken" />
                </n-space>
              </n-grid-item>
            </n-grid>
          </n-space>

          <n-space vertical>
            <div class="length-container">
              <label>Length</label>
              <n-input-number v-model:value="tokenLength" :min="1" :max="512" step="1" @update:value="generateToken" />
            </div>

            <div class="length-slider">
              <n-slider v-model:value="tokenLength" :min="1" :max="512" step="1" @update:value="generateToken" />
            </div>
          </n-space>

          <!-- Текстовое поле для токена -->
          <n-input type="textarea" :value="generatedToken" readonly rows="3" :autosize="{ minRows: 3 }" />

          <!-- Кнопки Copy и Refresh -->
          <n-space justify="center">
            <n-button @click="copyToken">Copy</n-button>
            <n-button @click="generateToken">Refresh</n-button>
          </n-space>
        </n-space>
      </n-card>
    </div>
  </n-config-provider>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { toolsData } from '@/toolsData';
import { useNotification } from 'naive-ui';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
  name: 'TokenGenerator',
  components: { ToolHeader },
  setup() {
    const tool = toolsData.crypto.find(t => t.path === '/token-generator'); // Используем данные для утилиты

    const useUppercase = ref(true);
    const useLowercase = ref(true);
    const useNumbers = ref(false);
    const useSymbols = ref(false);
    const tokenLength = ref(64);
    const generatedToken = ref('');

    const notification = useNotification();

    const characters = {
      uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      lowercase: 'abcdefghijklmnopqrstuvwxyz',
      numbers: '0123456789',
      symbols: '!@#$%^&*()_+{}[]<>?~',
    };

    const generateToken = () => {
      let charSet = '';
      if (useUppercase.value) charSet += characters.uppercase;
      if (useLowercase.value) charSet += characters.lowercase;
      if (useNumbers.value) charSet += characters.numbers;
      if (useSymbols.value) charSet += characters.symbols;

      if (charSet === '') {
        generatedToken.value = 'Please select at least one character set.';
        return;
      }

      let token = '';
      for (let i = 0; i < tokenLength.value; i++) {
        token += charSet.charAt(Math.floor(Math.random() * charSet.length));
      }
      generatedToken.value = token;
    };

    const copyToken = () => {
      navigator.clipboard.writeText(generatedToken.value);
      notification.success({
        content: 'Token copied successfully!',
        duration: 2000,
      });
    };

    onMounted(() => {
      generateToken();
    });

    return {
      tool, // Передаем данные в шаблон
      useUppercase,
      useLowercase,
      useNumbers,
      useSymbols,
      tokenLength,
      generatedToken,
      copyToken,
      generateToken
    };
  },
});
</script>

<style scoped>
.switches-container {
  margin-bottom: 20px;
}

.length-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.length-slider {
  margin-top: 8px;
}

.form-wrapper {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .switches-container {
    margin-bottom: 20px;
    flex-flow: unset !important;
  }
}
</style>
