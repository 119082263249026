<template>
  <n-config-provider>
    <div class="tool-container">

      <ToolHeader :toolData="toolData" />

      <n-space vertical size="large">
        <!-- Ввод пароля -->
        <n-input v-model:value="password" type="password" show-password-on="click" placeholder="Enter a password..."
          clearable>
          <template #password-visible-icon>
            <n-icon style="padding-left: 10px;" :size="16" :component="EyeOffSharp" />
          </template>
          <template #password-invisible-icon>
            <n-icon style="padding-left: 10px;" :size="16" :component="EyeSharp" />
          </template>
        </n-input>

        <!-- Карточка с временем взлома -->
        <n-card>
          <n-space vertical :size="1" align="center">
            <span style="color: #999;">Duration to crack this password with brute force</span>
            <span class="crack-duration">{{ crackInfo.crackDurationFormatted }}</span>
          </n-space>
        </n-card>

        <!-- Карточка с деталями -->
        <n-card>
          <div class="result-container">
            <!-- Строки с результатами через flex -->
            <div class="result-row">
              <div class="result-label">Password length:</div>
              <div class="result-value">{{ crackInfo.passwordLength }}</div>
            </div>
            <div class="result-row">
              <div class="result-label">Entropy:</div>
              <div class="result-value">{{ crackInfo.entropy.toFixed(2) }}</div>
            </div>
            <div class="result-row">
              <div class="result-label">Character set size:</div>
              <div class="result-value">{{ crackInfo.charsetLength }}</div>
            </div>
            <div class="result-row">
              <div class="result-label">Score:</div>
              <div class="result-value">{{ (crackInfo.score * 100).toFixed(0) }} / 100</div>
            </div>
          </div>
        </n-card>

        <!-- Примечание -->
        <n-space class="note" justify="center">
          <p>
            <span style="font-weight: bold;">Note: </span>
            The computed strength is based on the time it would take to crack the password using a brute
            force approach, it does not take into account the possibility of a dictionary attack.
          </p>
        </n-space>
      </n-space>
    </div>
  </n-config-provider>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { toolsData } from '@/toolsData';
import { EyeSharp, EyeOffSharp } from '@vicons/ionicons5';
import _ from 'lodash';
import ToolHeader from '@/components/ToolHeader.vue';

export default defineComponent({
  name: 'PasswordStrengthAnalyzer',
  components: { ToolHeader },
  setup() {
    const toolData = toolsData.crypto.find(tool => tool.path === '/password-strength-analyser');
    const password = ref('');

    const crackInfo = ref({
      entropy: 0,
      charsetLength: 0,
      passwordLength: 0,
      crackDurationFormatted: 'Instantly',
      score: 0
    });

    const prettifyExponentialNotation = (exponentialNotation) => {
      const [base, exponent] = exponentialNotation.toString().split('e');
      const baseAsNumber = parseFloat(base);
      const prettyBase = baseAsNumber % 1 === 0 ? baseAsNumber.toLocaleString() : baseAsNumber.toFixed(2);
      return exponent ? `${prettyBase}e${exponent}` : prettyBase;
    };

    const getHumanFriendlyDuration = ({ seconds }) => {
      if (seconds <= 0.001) return 'Instantly';
      if (seconds <= 1) return 'Less than a second';

      const timeUnits = [
        { unit: 'millennium', secondsInUnit: 31536000000, format: prettifyExponentialNotation, plural: 'millennia' },
        { unit: 'century', secondsInUnit: 3153600000, plural: 'centuries' },
        { unit: 'decade', secondsInUnit: 315360000, plural: 'decades' },
        { unit: 'year', secondsInUnit: 31536000, plural: 'years' },
        { unit: 'month', secondsInUnit: 2592000, plural: 'months' },
        { unit: 'week', secondsInUnit: 604800, plural: 'weeks' },
        { unit: 'day', secondsInUnit: 86400, plural: 'days' },
        { unit: 'hour', secondsInUnit: 3600, plural: 'hours' },
        { unit: 'minute', secondsInUnit: 60, plural: 'minutes' },
        { unit: 'second', secondsInUnit: 1, plural: 'seconds' },
      ];

      return _.chain(timeUnits)
        .map(({ unit, secondsInUnit, plural }) => {
          const quantity = Math.floor(seconds / secondsInUnit);
          seconds %= secondsInUnit;
          return quantity ? `${quantity} ${quantity > 1 ? plural : unit}` : undefined;
        })
        .compact()
        .take(2)
        .join(', ')
        .value();
    };

    const getPasswordCrackTimeEstimation = (password) => {
      const guessesPerSecond = 1e9;
      const charsetLength = getCharsetLength(password);
      const passwordLength = password.length;
      const entropy = password === '' ? 0 : Math.log2(charsetLength) * passwordLength;
      const secondsToCrack = 2 ** entropy / guessesPerSecond;

      return {
        entropy,
        charsetLength,
        passwordLength,
        crackDurationFormatted: getHumanFriendlyDuration({ seconds: secondsToCrack }),
        secondsToCrack: prettifyExponentialNotation(secondsToCrack),
        score: Math.min(entropy / 128, 1)
      };
    };

    const getCharsetLength = (password) => {
      const hasLowercase = /[a-z]/.test(password);
      const hasUppercase = /[A-Z]/.test(password);
      const hasDigits = /\d/.test(password);
      const hasSpecialChars = /\W|_/.test(password);

      let charsetLength = 0;
      if (hasLowercase) charsetLength += 26;
      if (hasUppercase) charsetLength += 26;
      if (hasDigits) charsetLength += 10;
      if (hasSpecialChars) charsetLength += 32;

      return charsetLength;
    };

    watch(password, () => {
      crackInfo.value = getPasswordCrackTimeEstimation(password.value);
    });

    return {
      toolData,
      password,
      crackInfo,
      EyeSharp,
      EyeOffSharp
    };
  }
});
</script>

<style scoped>
.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
}

.result-label {
  flex: 1;
  text-align: right;
  padding-right: 20px;
  color: #999;
}

.result-value {
  flex: 1;
  text-align: left;
  font-weight: bold;
}


.crack-duration {
  font-size: 24px;
}

.note {
  font-size: 14px;
  color: #999;
}
</style>